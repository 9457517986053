import { defineStore } from "pinia";
// import { GoogleMap } from "vue3-google-map";
// import { cuti } from "@/data/dummies.js";
import axiosClient from "../axios.js";
import axiosFormData from "../axios.js";
import { getCookie, showNotification, hideModals } from "@/functions/general.js";
import { calculateDistance } from "@/functions/location.js";
// import { router } from '@/router';

export const useAbsenStore = defineStore("absen", {
    state: () => ({
        // ==================== states used on absen page and its relation
        cameras: null,
        selfieResult: null,
        scanner: {status: false},
        position: null,
        absenBantuan: null,
        loader: false
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on absen page and its relation
        async setScanner(status){
            this.scanner.status = status;
        },
        async setAbsen(){
            if(navigator.geolocation){
                navigator.geolocation.watchPosition(this.setPosition);
            }
        },
        async absenQR(code, selfie){
            // console.log('QC : ' + code);
            const params = JSON.parse(code);
            params.device_id = getCookie('deviceid');
            params.attachment = selfie;
            // console.log(params);
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosFormData.post(`attendance/source`, params, {headers: {"Content-Type": "multipart/form-data"}});
                if(response.status == 200){
                    showNotification('', response.data.message, 5000, null);
                    // router.push('/presensi');
                    return 1;
                }
                // console.log(response);
            }catch(error){
                if(error.response.status == 422){
                    showNotification('', error.response.data.message, 5000, 'var(--red)');
                }
                // console.log(error);
            }
        },
        async setPosition(position){
            // var latitude = position.coords.latitude;
            // var longitude = position.coords.longitude;
            // // var accuracy = position.coords.accuracy;
            // var coordinate = GoogleMap.maps.LatLng(latitude, longitude);
            // var options = {
            //     zoom: 15,
            //     center: coordinate,
            //     mapTypeControl: true,
            //     navigatorControlOptions: {
            //         style: GoogleMap.maps.NavigatorControlStyle.SMALL
            //     },
            //     mapTypeId: GoogleMap.maps.MapTypeId.ROADMAP
            // };
            // GoogleMap.maps.Map(document.getElementById('maps-absen'), options);
            // const pole = {
            //     latitude: '-6.9062436',
            //     longitude: '107.6031977'
            // };
            const pole = {
                latitude: '-6.90515',
                longitude: '107.60342'
            };
            this.position = await position;
            this.position.detail = {
                pole: pole,
                distance: {detected: [], value: calculateDistance(pole.latitude, pole.longitude, this.position.coords.latitude, this.position.coords.longitude)}
            };
            // =====
            var distavg = 0;
            const sleep = ms => {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
            const setAverage = ms => {
                this.position.detail.distance.detected.push({value: parseFloat(calculateDistance(pole.latitude, pole.longitude, this.position.coords.latitude, this.position.coords.longitude))});
                distavg = distavg + parseFloat(calculateDistance(pole.latitude, pole.longitude, this.position.coords.latitude, this.position.coords.longitude));
                return sleep(ms);
            }
            // =====
            for(var i = 0; i < 10; i++){
                await setAverage(100);
            }
            this.position.detail.distance.average = distavg/10;
            // console.log(distavg/10);
            // console.log(this.position);
        },
        async getAbsenBantuan(){
            try{
                // await useAccountStore().getProfile();
                const response = await axiosClient.get(`attendance/help`);
                this.absenBantuan = response.data.data;
                console.log(this.absenBantuan);
            }catch(error){
                console.log(error);
            }
        },
        async newAbsenBantuan(data, type){
            this.loader = true;
            try{
                const response = await axiosFormData.post(`attendance/help`, data, {headers: {"Content-Type": "multipart/form-data"}});
                if(response.status == 200){
                    this.getAbsenBantuan();
                    hideModals();
                    if(type == 'new'){
                        showNotification('', 'Permintaan Bantuan dikirim', 5000, null);
                    }else{
                        showNotification('', 'Permintaan Bantuan diperbarui', 5000, null);
                    }
                }
                this.loader = false;
                console.log(response);
            }catch(error){
                this.loader = false;
                showNotification('', "code : " + error?.code + "\n\nresponse : " + JSON.stringify(error?.response), 5000, 'var(--red)');
                console.log(error);
            }
        }
    }
});
