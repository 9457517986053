<template>
    <section>
        <div class="section-inner" v-if="data">
            <div class="si-head">
                <div class="si-head-inner">
                    <div class="data-baru" v-if="jadwalNearest">
                        <div class="data-baru-button">
                            <button @click="showModal('data-baru-modal');">Minta Bantuan</button>
                        </div>
                        <div class="modal srp-modal" id="data-baru-modal">
                            <div class="srp-modal-head">
                                <div class="smh-close" @click="hideModal('data-baru-modal')">
                                    <span>x</span>
                                </div>
                            </div>
                            <div class="srp-modal-body">
                                <div class="smb-form">
                                    <div class="smb-form-input" id="sfi-schedule">
                                        <div class="sfi-label">
                                            <span>Jadwal</span>
                                        </div>
                                        <div class="sfi-input">
                                            <div class="sir-cov">
                                                <div class="sfi-input-radio" v-for="(sc, index) in jadwalNearest" :key="sc">
                                                    <input type="radio" name="input-data-jadwal" :id="'schedule-' + index" :value="sc.id"/>
                                                    <label :for="'schedule-' + index">{{ sc.day + ', ' + sc.date + ' (' + sc.shift?.name + ')' }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="smb-form-input" id="sfi-type">
                                        <div class="sfi-label">
                                            <span>Masuk/Keluar</span>
                                        </div>
                                        <div class="sfi-input">
                                            <div class="sir-cov">
                                                <div class="sfi-input-radio">
                                                    <input type="radio" name="input-data-tipe" id="idt-masuk" value="1"/>
                                                    <label for="idt-masuk">Masuk</label>
                                                </div>
                                                <div class="sfi-input-radio">
                                                    <input type="radio" name="input-data-tipe" id="idt-keluar" value="2"/>
                                                    <label for="idt-keluar">Keluar</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="smb-form-input" id="sfi-time">
                                        <div class="sfi-label">
                                            <span>Jam</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="time" id="input-data-time"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input" id="sfi-description">
                                        <div class="sfi-label">
                                            <span>Keterangan</span>
                                        </div>
                                        <div class="sfi-input">
                                            <textarea id="input-data-keterangan"></textarea>
                                        </div>
                                    </div>
                                    <div class="smb-form-input" id="sfi-attachment">
                                        <div class="sfi-label">
                                            <span>Lampiran</span>
                                        </div>
                                        <div class="sfi-input" :id="'flb-attachments'">
                                            <small><i>maksimal ukuran file adalah 10 MB</i></small>
                                            <div>
                                                <input type="file"/>
                                            </div>
                                        </div>
                                        <!-- <div class="sfi-input-more">
                                            <span @click="inputLampiran('flb-attachments')">+ tambah lampiran</span>
                                        </div> -->
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-button" v-if="!loader">
                                            <button @click="submitBantuan();">Submit</button>
                                        </div>
                                        <div class="sfi-button" v-else>
                                            <button>Loading.....</button>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="si-body">
                <div class="si-body-inner" v-if="data.length > 0">
                    <div class="sbi-table">
                        <div class="sbi-table-fixed">
                            <div class="sbi-table-head stf-head">
                                <div class="shp-num">
                                    <span>No</span>
                                </div>
                                <div class="shp-num">
                                    <span>Tanggal</span>
                                </div>
                            </div>
                            <div class="sbi-table-body">
                                <div :class="'stb-row stb-row-' + (index % 2)" v-for="(dt, index) in data" :key="dt">
                                    <div class="sbp-num">
                                        <span>{{ index + 1 }}</span>
                                    </div>
                                    <div class="sbp-num">
                                        <span>{{ dt.date }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sbi-table-scroll" @scroll="tableScroll">
                            <div class="sbi-table-head">
                                <div class="sts-head-part">
                                    <span>Shift</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Masuk/Keluar</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Waktu</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Status</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Keterangan</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Action</span>
                                </div>
                            </div>
                            <div class="sbi-table-body">
                                <div class="stb-row" v-for="(dt, index) in data" :key="dt">
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.schedule?.shift?.name }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.type.alias }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.time }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.status.string }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.description }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <div class="srp-accordion" v-if="dt.status?.code < 1">
                                            <div class="srp-accordion-button saotg" :id="'sab-data-action-' + index" @click="actionToggle('sab-data-action-' + index)">
                                                <div class="sab-icon saotg">
                                                    <img class="saotg" src="../../assets/images/icons/arrow-1.png" alt="arrow"/>
                                                </div>
                                            </div>
                                            <div class="srp-accordion-options">
                                                <div class="sao-list">
                                                    <div class="saotg">
                                                        <span class="saotg" @click="showModal('modal-sxlbr-' + index)">Update</span>
                                                    </div>
                                                    <div class="modal srp-modal" :id="'modal-sxlbr-' + index">
                                                        <div class="srp-modal-head">
                                                            <div class="smh-close" @click="hideModal('modal-sxlbr-' + index)">
                                                                <span>x</span>
                                                            </div>
                                                        </div>
                                                        <div class="srp-modal-body">
                                                            <div class="smb-form">
                                                                <div class="smb-form-input" :id="'sfi-schedule' + index">
                                                                    <div class="sfi-label">
                                                                        <span>Jadwal</span>
                                                                    </div>
                                                                    <div class="sfi-input">
                                                                        <div class="sir-cov">
                                                                            <!-- <p>{{ dt.date }}</p> -->
                                                                            <div class="sfi-input-radio" v-for="(n, index) in 1" :key="n">
                                                                                <input type="radio" name="input-data-jadwal" :id="'schedule-' + index" :value="dt.schedule_id" checked/>
                                                                                <label :for="'schedule-' + index">{{ dt.date }}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input" :id="'sfi-type' + index">
                                                                    <div class="sfi-label">
                                                                        <span>Masuk/Keluar</span>
                                                                    </div>
                                                                    <div class="sfi-input">
                                                                        <div class="sir-cov">
                                                                            <div class="sfi-input-radio">
                                                                                <input type="radio" name="input-data-tipe" id="idt-masuk" value="1" checked v-if="dt.type?.code == 1"/>
                                                                                <input type="radio" name="input-data-tipe" id="idt-masuk" value="1" v-else/>
                                                                                <label for="idt-masuk">Masuk</label>
                                                                            </div>
                                                                            <div class="sfi-input-radio">
                                                                                <input type="radio" name="input-data-tipe" id="idt-keluar" value="2" v-if="dt.type?.code == 2"/>
                                                                                <input type="radio" name="input-data-tipe" id="idt-keluar" value="2" v-else/>
                                                                                <label for="idt-keluar">Keluar</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input" :id="'sfi-time' + index">
                                                                    <div class="sfi-label">
                                                                        <span>Jam</span>
                                                                    </div>
                                                                    <div class="sfi-input">
                                                                        <input type="time" :id="'input-data-time' + index" :value="dt.time"/>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input" :id="'sfi-description' + index">
                                                                    <div class="sfi-label">
                                                                        <span>Keterangan</span>
                                                                    </div>
                                                                    <div class="sfi-input">
                                                                        <textarea id="input-data-keterangan" :value="dt.description"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input" :id="'sfi-attachment' + index">
                                                                    <div class="sfi-label">
                                                                        <span>Lampiran</span>
                                                                    </div>
                                                                    <div class="sfi-input" :id="'flb-attachments'">
                                                                        <small><i>maksimal ukuran file adalah 10 MB</i></small>
                                                                        <div>
                                                                            <input type="file"/>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="sfi-input-more">
                                                                        <span @click="inputLampiran('flb-attachments')">+ tambah lampiran</span>
                                                                    </div> -->
                                                                </div>
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-button" v-if="!loader">
                                                                        <button @click="updateBantuan(index, dt.id);">Update</button>
                                                                    </div>
                                                                    <div class="sfi-button" v-else>
                                                                        <button>Loading.....</button>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="sao-list">
                                                    <div class="">
                                                        <span @click="showModal('modal-dtdelelete-' + index)">Batalkan</span>
                                                    </div>
                                                    <div class="modal srp-modal" :id="'modal-dtdelelete-' + index">
                                                        <div class="srp-modal-head">
                                                            <div class="smh-close" @click="hideModal('modal-dtdelelete-' + index)">
                                                                <span>x</span>
                                                            </div>
                                                        </div>
                                                        <div class="srp-modal-body">
                                                            <div class="smb-form">
                                                                <p>Batalkan Permintaan ini ?</p>
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-button" v-if="!loader">
                                                                        <button @click="deleteData(dt.id);">Batalkan</button>
                                                                    </div>
                                                                    <div class="sfi-button" v-else>
                                                                        <button>Loading.....</button>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="si-body-inner sso-note" v-else>
                    <p>Tidak ada data</p>
                </div>
            </div>
        </div>
        <CutiSkeleton v-else/>
    </section>
</template>

<script setup>
    import CutiSkeleton from '@/components/skeletons/sections/CutiSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useAbsenStore } from '@/stores/absen';
    import { showModal, hideModal, actionToggle } from '@/functions/general';
    // import { newData, updateData, deleteData, inputLampiran } from '@/functions/dinas';
    import { useJadwalStore } from '@/stores/jadwal';
    import { submitBantuan, updateBantuan } from '@/functions/absen';
    const data = computed(() => useAbsenStore().absenBantuan);
    const jadwalNearest = computed(() => useJadwalStore().jadwalNearest);
    const loader = computed(() => useAbsenStore().loader);
    useJadwalStore().setJadwal();
    useAbsenStore().getAbsenBantuan();
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        transition: 0.2s;
        /* border: 1px solid green; */
    }
    .si-head{
        padding: 0 0 2rem 0;
    }
    .data-baru-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        user-select: none;
        cursor: pointer;
    }
    .si-body-inner{
        padding: 0 0 2.5rem 0;
        /* border: 1px solid gold; */
    }
    .sbi-table{
        width: 100%;
        display: flex;
        /* border: 1px solid green; */
        padding: 0 0 2.5rem 0;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
        padding: 0 0 5rem 0;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
        padding: 0 0 5rem 0;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 75px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-0{
        background: var(--smoke);
    }
    .sts-row-part{
        min-width: 75px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .srp-0{
        background: var(--smoke);
    }
    .srp-accordion{
        position: relative;
        width: 100%;
        height: 37px;
        user-select: none;
    }
    .srp-accordion-button{
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .sab-icon img{
        transition: 0.2s;
    }
    .arrow-active{
        transform: rotate(180deg);
    }
    .srp-accordion-options{
        position: absolute;
        top: 100%;
        left: 5%;
        width: 90%;
        background: var(--white);
        z-index: 1;
        display: none;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .sao-active{
        display: block;
    }
    .sao-list{
        padding: 0.5rem;
    }
    .srp-button{
        text-align: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .srp-modal{
        padding: 100px 25% 1rem 25%;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        max-height: 90%;
        overflow: auto;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    .sir-cov{
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        padding: 0.5rem;
    }
    .sfi-input-radio{
        width: 100%;
        max-width: 200px;
        display: flex;
        justify-content: left;
        align-items: center;
        /* border: 1px solid green; */
        /* text-align: left; */
        padding: 0.5rem 0 0.5rem 0;
        user-select: none;
        /* text-align: left; */
    }
    .sfi-input-radio input{
        max-width: 50px;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 0;
        }
        .srp-modal-body{
            height: 100%;
        }
    }
</style>