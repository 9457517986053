import { createRouter, createWebHistory } from 'vue-router';
import { getCookie, showNotification } from '@/functions/general';
// import HomeView from '../views/HomeView.vue';
import DocumentsView from '../views/DocumentsView.vue';
// import DocumentUploadView from '../views/DocumentUploadView.vue';
import DocumentDetailView from '../views/DocumentDetailView.vue';
import RequestListView from '../views/RequestListView.vue';
import JadwalView from '../views/JadwalView.vue';
import PresenceView from '../views/PresenceView.vue';
import LemburView from '../views/LemburView.vue';
import CutiView from '../views/CutiView.vue';
import DinasView from '../views/DinasView.vue';
import SakitView from '../views/SakitView.vue';
import TugasView from '../views/TugasView.vue';
import AbsenView from '../views/AbsenView.vue';
import AbsenQrView from '../views/AbsenQrView.vue';
import QrTestView from '../views/QrTestView.vue';
import AbsenFotoView from '../views/AbsenFotoView.vue';
import BantuanAbsenView from '../views/BantuanAbsenView.vue';
import LoginView from '../views/LoginView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';
import NewPasswordView from '../views/NewPasswordView.vue';
import ProfileView from '../views/ProfileView.vue';
import KpiView from '../views/KpiView.vue';
import PayrollView from '../views/PayrollView.vue';
import ApprovalView from '../views/ApprovalView.vue';
import ResignView from '../views/ResignView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/absen'
  },
  {
    path: '/dokumen',
    name: 'documents',
    component: DocumentsView,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/dokumen/unggah',
  //   name: 'documentUpload',
  //   component: DocumentUploadView,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/dokumen/detail/:slug',
    name: 'documentDetail',
    component: DocumentDetailView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/request',
    name: 'requestList',
    component: RequestListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/presensi',
    name: 'presensi',
    component: PresenceView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jadwal-kerja',
    name: 'jadwalKerja',
    component: JadwalView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lembur',
    name: 'lembur',
    component: LemburView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cuti',
    name: 'cuti',
    component: CutiView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dinas',
    name: 'dinas',
    component: DinasView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sakit',
    name: 'sakit',
    component: SakitView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tugas',
    name: 'tugas',
    component: TugasView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/absen',
    name: 'absen',
    component: AbsenView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/absen/scan-qr',
    name: 'absenQr',
    component: AbsenQrView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/absen/qr-test',
    name: 'qrTest',
    component: QrTestView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/absen-foto',
    name: 'absenFoto',
    component: AbsenFotoView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/absen/bantuan',
    name: 'bantuanAbsen',
    component: BantuanAbsenView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reset-password/:token',
    name: 'newPassword',
    component: NewPasswordView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/profil',
    name: 'profile',
    component: ProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kpi',
    name: 'kpi',
    component: KpiView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payroll',
    name: 'payroll',
    component: PayrollView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/approval',
    name: 'approval',
    component: ApprovalView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/resign',
    name: 'resign',
    component: ResignView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!getCookie('session')) {
        if(to.name == 'login'){
          next();
        }else{
          showNotification('', 'Silakan login dahulu !', 2000, null);
          next({ name: 'login' });
        }
      } else {
        if(to.name == 'login'){
          next({name: 'profile'})
        }
        next()
      }
  } else {
    next()
  }
})

export default router

