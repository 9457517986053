import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { useAccountStore } from "./account";
import { showNotification, hideModals } from "@/functions/general";
// import { resign } from "@/data/dummies.js";

export const useResignStore = defineStore("resign", {
    state: () => ({
        // ==================== states used on resign page and its relation
        resign: null,
        loader: false
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on resign page and its relation
        async setResign(){
            try{
                await useAccountStore().getProfile();
                const response = await axiosClient.get(`submission/resign`);
                this.resign = response.data.data;
                console.log(this.resign);
                // console.log(navigator.userAgent);
            }catch(error){
                console.log(error);
            }
        },
        async resignBaru(params){
            this.loader = true;
            try{
                const response = await axiosClient.post(`submission/resign`, params);
                if(response.status == 201){
                    this.setResign();
                    showNotification('', 'Permintaan Resign dikirim', 5000, null);
                    hideModals();
                }
                this.loader = false;
                // console.log(response);
            }catch(error){
                if(error.response){
                    showNotification('', 'Message : ' + error.response.data.message, 5000, 'var(--red)');
                }
                this.loader = false;
                console.log(error);
            }
        }
    }
});