export function dropdownToggle(id){
    const dropdowns = document.getElementsByClassName('dropdown');
    const dropdown = document.getElementById(id);
    const button = dropdown.children[0];
    const option = dropdown.children[1];
    var i;
    for(i = 0; i < dropdowns.length; i++){
        if(dropdowns[i].getAttribute('id') != id){
            dropdowns[i].children[0].children[1].classList.remove('dbi-active');
            dropdowns[i].children[1].classList.remove('dropdown-option-active');
        }
    }
    if(option.classList.contains('dropdown-option-active')){
        option.classList.remove('dropdown-option-active');
        button.children[1].classList.remove('dbi-active');
    }else{
        option.classList.add('dropdown-option-active');
        button.children[1].classList.add('dbi-active');
    }
}
// function hideDropdowns(){
//     const dropdowns = document.getElementsByClassName('dropdown');
//     var i;
//     for(i = 0; i < dropdowns.length; i++){
//         dropdowns[i].children[0].children[1].classList.remove('dbi-active');
//         dropdowns[i].children[1].classList.remove('dropdown-option-active');
//     }
// }
// window.onclick = function(e){
//     if(!e.target.classList.contains('trigger')){
//         hideDropdowns();
//     }
// }


