<template>
    <section>
        <div class="section-inner" v-if="cuti">
            <div class="si-head">
                <div class="si-head-inner">
                    <div class="cuti-baru">
                        <div class="cuti-baru-button">
                            <button @click="showModal('cuti-baru-modal');">Minta Cuti</button>
                        </div>
                        <div class="modal srp-modal" id="cuti-baru-modal">
                            <div class="srp-modal-head">
                                <div class="smh-close" @click="hideModal('cuti-baru-modal')">
                                    <span>x</span>
                                </div>
                            </div>
                            <div class="srp-modal-body">
                                <div class="smb-form">
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Tanggal Mulai</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="date" id="input-cuti-mulai"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Tanggal Selesai</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="date" id="input-cuti-selesai"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Keterangan</span>
                                        </div>
                                        <div class="sfi-input">
                                            <textarea id="input-cuti-keterangan"></textarea>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-button" v-if="!loader">
                                            <button @click="cutiBaru();">Submit</button>
                                        </div>
                                        <div class="sfi-button" v-else>
                                            <button>Loading.....</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="si-body">
                <div class="si-body-inner" v-if="cuti.length > 0">
                    <div class="sbi-table">
                        <div class="sbi-table-fixed">
                            <div class="sbi-table-head stf-head">
                                <div class="shp-num">
                                    <span>No</span>
                                </div>
                            </div>
                            <div class="sbi-table-body">
                                <div :class="'stb-row stb-row-' + (index % 2)" v-for="(ct, index) in cuti" :key="ct">
                                    <div class="sbp-num">
                                        <span>{{ index + 1 }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sbi-table-scroll" @scroll="tableScroll">
                            <div class="sbi-table-head">
                                <div class="sts-head-part">
                                    <span>Mulai</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Selesai</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Status</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Keterangan</span>
                                </div>
                            </div>
                            <div class="sbi-table-body">
                                <div class="stb-row" v-for="(ct, index) in cuti" :key="ct">
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ ct.start_date }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ ct.end_date }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ ct.status }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ ct.description }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="si-body-inner sso-note" v-else>
                    <p>Tidak ada data</p>
                </div>
            </div>
        </div>
        <CutiSkeleton v-else/>
    </section>
</template>

<script setup>
    import CutiSkeleton from '@/components/skeletons/sections/CutiSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useCutiStore } from '@/stores/cuti';
    import { showModal, hideModal } from '@/functions/general';
    import { cutiBaru } from '@/functions/cuti';
    const cuti = computed(() => useCutiStore().cuti);
    const loader = computed(() => useCutiStore().loader);
    useCutiStore().setCuti();
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        transition: 0.2s;
    }
    .si-head{
        padding: 0 0 2rem 0;
    }
    .cuti-baru-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        user-select: none;
        cursor: pointer;
    }
    .sbi-table{
        width: 100%;
        display: flex;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 75px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-0{
        background: var(--smoke);
    }
    .sts-row-part{
        min-width: 75px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .srp-0{
        background: var(--smoke);
    }
    .srp-modal{
        padding: 1rem 25% 1rem 25%;
        transition: 0.2s;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
        transition: 0.2s;
    }
    .smb-form{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 0;
        }
        .srp-modal-body{
            height: 100%;
        }
    }
    /*  */
</style>