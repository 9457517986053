import { useDocumentStore } from "@/stores/document";
export function actionToggle(btnid){
    if(document.getElementById(btnid)){
        const button = document.getElementById(btnid);
        const buttons = document.getElementsByClassName('srp-accordion-button');
        if(button.nextElementSibling.classList.contains('sao-active')){
            button.nextElementSibling.classList.remove('sao-active');
            button.children[0].children[0].classList.remove('arrow-active');
        }else{
            for(var i = 0; i < buttons.length; i++){
                buttons[i].children[0].children[0].classList.remove('arrow-active');
                buttons[i].nextElementSibling.classList.remove('sao-active');
            }
            button.nextElementSibling.classList.add('sao-active');
            button.children[0].children[0].classList.add('arrow-active');
        }
    }
}
export function inputSelectCategory(e){
    useDocumentStore().inputSelectCategory(e.srcElement.value);
    useDocumentStore().searchDocument();
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useDocumentStore().searchbarSelectCategory.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function inputSelectDivision(e){
    useDocumentStore().inputSelectDivision(e.srcElement.value);
    useDocumentStore().searchDocument(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useDocumentStore().searchbarSelectCategory.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function inputDocument(e){
    useDocumentStore().searchbarDocument.input.value = e.srcElement.value;
    useDocumentStore().inputDocument(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
    // var timer = setTimeout(sendData, 1000);
    // clearTimeout(timer);
    // timer = setTimeout(sendData, 1000);
    // e.srcElement.onkeypress = function(){
    //     timer = null;
    //     console.log(timer);
    // }
    // function sendData(){
    //     console.log(e.srcElement.value);
    //     // console.log(timer);
    //     // if(useDocumentStore().searchbarDocument.input.value = e.srcElement.value){
    //     // }
    // }
}
export function selectCategory(category, inputid){
    useDocumentStore().searchbarDocument.select.category = {name: category.name, value: category.slug};
    const input = document.getElementById(inputid);
    input.value = null;
    useDocumentStore().searchbarSelectCategory.input.value = '';
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
    useDocumentStore().selectCategory();
}
export function selectDivision(division, inputid){
    useDocumentStore().searchbarDocument.select.division = {name: division.name, value: division.slug};
    const input = document.getElementById(inputid);
    input.value = null;
    useDocumentStore().searchbarSelectDivision.input.value = '';
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
    useDocumentStore().selectDivision();
}
export function clearInputDocument(){
    const input = document.getElementById('sii-input-document');
    input.value = null;
    useDocumentStore().searchDocument.input = {value: null};
    useDocumentStore().inputDocument('');
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function clearInputSelectCategory(){
    const input = document.getElementById('input-select-docategory');
    input.value = '';
    useDocumentStore().searchbarSelectCategory.input.value = '';
    useDocumentStore().inputSelectCategory('');
    useDocumentStore().searchDocument();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function clearInputSelectDivision(){
    const input = document.getElementById('input-select-dodivision');
    input.value = '';
    useDocumentStore().searchbarSelectCategory.input.value = '';
    useDocumentStore().inputSelectDivision('');
    useDocumentStore().searchDocument();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function newRequestAccess(dc, note){
    const params = {
        document_id: dc.id,
        desc_req: document.getElementById(note).value
    };
    useDocumentStore().requestAccess(params, note);
    // console.log(dc);
    // console.log(params);
}