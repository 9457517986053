//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export function calculateDistance(lat1, lon1, lat2, lon2) 
{
    var R = 6371; // km
    var dLat = toRad(lat2-lat1);
    var dLon = toRad(lon2-lon1);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return parseFloat(d.toFixed(3));
}

// Converts numeric degrees to radians
export function toRad(Value) 
{
    return Value * Math.PI / 180;
}
// 