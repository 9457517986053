import { useResignStore } from "@/stores/resign";
import { showNotification } from "./general";
export function resignBaru(){
    const note = document.getElementById('input-resign-keterangan');
    if(note.value){
        useResignStore().resignBaru({description: note.value});
    }else{
        showNotification('', 'Keterangan diperlukan', 2000, null);
    }
}
export function showTab(cl, id){
    const button = document.getElementsByClassName(cl.button);
    const content = document.getElementsByClassName(cl.content);
    for(var i = 0; i < button.length; i++){
        button[i].classList.remove('shp-active');
    }
    for(i = 0; i < content.length; i++){
        content[i].classList.remove('sbp-active');
    }
    document.getElementById(id.button).classList.add('shp-active');
    document.getElementById(id.content).classList.add('sbp-active');
}

