<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <h1>Dokumen</h1>
            </div>
            <div class="si-body">
                <div class="si-body-tab">
                    <div class="sbt-head">
                        <!--  -->
                    </div>
                    <div class="sbt-body">
                        <div class="sbt-body-part sbp-active" id="sbp-all">
                            <div class="sbp-content">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Nama</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body" v-if="account">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(document, index) in account.attachment_lists" :key="document">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <span>{{ document.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Tipe</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>File Sebelumnya</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body" v-if="account">
                                            <div class="stb-row" v-for="(document, index) in account.attachment_lists" :key="document">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ document.type }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <template v-if="document.submitted_document">
                                                        <div class="submitted-document" v-if="document.type == 'image'">
                                                            <img :src="document.submitted_document.file_url" alt="submitted image" :id="'image-' + document.submitted_document.attachment_id" @click="transformImage('image-' + document.submitted_document.attachment_id)"/>
                                                        </div>
                                                        <div class="submitted-document" v-if="document.type == 'pdf'">
                                                            <img src="../../assets/images/logos/pdflogo.jpg" alt="submitted pdf" :id="'pdf-' + document.submitted_document.attachment_id" @click="openNewPage(document.submitted_document.file_url);"/>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span>Belum Ada</span>
                                                    </template>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-status">
                                                        <div class="srp-transfer">
                                                            <div class="srp-button">
                                                                <span @click="showModal('modal-sx-' + index)">Upload</span>
                                                            </div>
                                                            <div class="modal srp-modal" :id="'modal-sx-' + index">
                                                                <div class="srp-modal-head">
                                                                    <div class="smh-close" @click="hideModal('modal-sx-' + index)">
                                                                        <span>x</span>
                                                                    </div>
                                                                </div>
                                                                <div class="srp-modal-body">
                                                                    <div class="smb-form">
                                                                        <input type="hidden" :id="'input-docid-' + index" :value="document.id"/>
                                                                        <div class="smb-form-input">
                                                                            <div class="sfi-label">
                                                                                <span>Lampiran</span>
                                                                            </div>
                                                                            <div class="sfi-input" :id="'flb-attachments-' + index">
                                                                                <small><i>maksimal ukuran file adalah 1 MB</i></small>
                                                                                <div>
                                                                                    <input type="file" @change="validateFileType('flb-attachments-' + index, document.type);"/>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="sfi-input-more">
                                                                                <span @click="inputLampiran(index)">+ tambah lampiran</span>
                                                                            </div> -->
                                                                        </div>
                                                                        <div class="smb-form-input" v-if="document.has_expired">
                                                                            <div class="sfi-label">
                                                                                <span>Tanggal Mulai</span>
                                                                            </div>
                                                                            <div class="sfi-input">
                                                                                <input type="date" :id="'input-docstart-' + index"/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="smb-form-input" v-if="document.has_expired">
                                                                            <div class="sfi-label">
                                                                                <span>Tanggal Selesai</span>
                                                                            </div>
                                                                            <div class="sfi-input">
                                                                                <input type="date" :id="'input-docend-' + index"/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="smb-form-input">
                                                                            <div class="sfi-label">
                                                                                <span>Nomor</span>
                                                                            </div>
                                                                            <div class="sfi-input">
                                                                                <input type="text" :id="'input-docnum-' + index" autocomplete="off"/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="smb-form-input">
                                                                            <div class="sfi-button" v-if="!loader">
                                                                                <button @click="submitDocument(index, document.has_expired);">Submit</button>
                                                                            </div>
                                                                            <div class="sfi-button" v-else>
                                                                                <button>Loading.....</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useAccountStore } from '@/stores/account';
    import { showModal, hideModal, openNewPage } from '@/functions/general';
    import { submitDocument, validateFileType, transformImage } from '@/functions/account';
    const account = computed(() => useAccountStore().account);
    const loader = computed(() => useAccountStore().loader);
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    section{
        width: 100%;
        /* padding: 2.5rem 10% 2.5rem 10%; */
        transition: 0.2s;
    }
    .si-head h1{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        transition: 0.2s;
    }
    .si-body-tab{
        border: 1px solid var(--softgrey);
    }
    .sbt-head{
        width: 100%;
        display: flex;
        background: var(--softgrey);
        overflow: auto;
    }
    .sbt-head::-webkit-scrollbar{
        display: none;
    }
    .sbt-head-part{
        min-width: 100px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 1rem 0;
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
    .shp-active{
        color: var(--primary);
        background: var(--white);
    }
    .sbt-body-part{
        display: none;
    }
    .sbp-active{
        display: block;
    }
    .sbp-content{
        padding: 1rem 0 0 0;
    }
    .sbp-content-note{
        text-align: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .sbi-table{
        width: 100%;
        display: flex;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        /* display: none; */
        height: 10px;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 150px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-0{
        /* background: var(--smoke); */
    }
    .sts-row-part{
        min-width: 150px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .srp-0{
        /* background: var(--smoke); */
    }
    .submitted-document img{
        max-width: 100px;
        /* height: 100%; */
        max-height: 50px;
        transition: 0.2s;
    }
    .attachment-transformed{
        transform: scale(5, 10);
    }
    .srp-button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button{
        display: none;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 0 0;
    }
    .lembur-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--grey);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button-active{
        display: flex;
    }
    .srp-modal{
        padding: 1rem 25% 1rem 25%;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .sbp-filter-board{
            width: 100%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .si-head h1{
            font-size: 1.5rem;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 0;
        }
        .srp-modal-body{
            height: 100%;
        }
    }
</style>