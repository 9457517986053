import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import axiosFormData from "@/axiosFormData";
import { useAccountStore } from "./account";
import { showNotification, hideModals, getCookie } from "@/functions/general";

export const useDinasStore = defineStore("dinas", {
    state: () => ({
        // ==================== states used on dinas page and its relation
        data: null,
        loader: false
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on dinas page and its relation
        async setData(){
            try{
                await useAccountStore().getProfile();
                const response = await axiosClient.get(`shift/ofc-travel`);
                this.data = response.data.data;
                console.log(this.data);
            }catch(error){
                console.log(error);
            }
        },
        async newData(params){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosFormData.post(`shift/ofc-travel`, params);
                if(response.data.success){
                    showNotification('', 'Permintaan Dinas dikirim', 5000, null);
                    hideModals();
                    this.setData();
                }
                this.loader = false;
                console.log(response);
            }catch(error){
                if(error.response?.status == 422){
                    showNotification('', error.response.data.message, 2000, 'var(--red)');
                }
                console.log(error);
                this.loader = false;
            }
        },
        async updateData(params){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosFormData.post(`shift/ofc-travel`, params);
                if(response.data.success){
                    showNotification('', 'Permintaan Dinas diperbarui', 5000, null);
                    hideModals();
                    this.setData();
                }
                this.loader = false;
                // console.log(response);
            }catch(error){
                if(error.response?.status == 422){
                    showNotification('', error.response.data.message, 2000, 'var(--red)');
                }
                console.log(error);
                this.loader = false;
            }
        },
        async deleteData(id){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosFormData.delete(`shift/ofc-travel/` + id);
                if(response.data.success){
                    showNotification('', 'Permintaan Dinas dibatalkan', 5000, null);
                    hideModals();
                    this.setData();
                }
                this.loader = false;
                console.log(response);
            }catch(error){
                if(error.response?.status == 422){
                    showNotification('', error.response.data.message, 2000, 'var(--red)');
                }
                console.log(error);
                this.loader = false;
            }
        }
    }
});