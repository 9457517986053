<template>
    <section>
        <div class="section-inner">
            <div class="coordinate" v-if="position">
                <!-- <div class="latitude">
                    <p>Latitude : {{ position.coords.latitude }}</p>
                </div>
                <div class="longitude">
                    <p>Longitude : {{ position.coords.longitude }}</p>
                </div> -->
                <div class="distance">
                    <!-- <p>Distance : {{ position.detail?.distance?.value || null }} KM</p> -->
                    <div class="detected" v-if="position.detail?.distance?.detected">
                        <!-- <p>Detected Distance</p>
                        <div>
                            {{ position.detail.distance }}
                        </div> -->
                        <!-- <div v-for="dt in position.detail?.distance?.detected" :key="dt">
                            <p>{{ dt }}</p>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="scanner" v-if="selfieResult">
                <div class="scanner-inner" id="scanner-video">
                    <!-- <div class="scanner-button">
                        <button @click="scanQRCode()" v-if="!scanner.status">Scan QR Code</button>
                    </div> -->
                    <div class="scanner-instruction">
                        <p>Sekarang Scan QR Code pada monitor !</p>
                    </div>
                    <div class="scanner-video">
                        <div class="scanner-video-line" id="svl-absen"></div>
                        <video class="video" id="video-element"></video>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    // import { scanner } from '@/data/absen';
    import { scanQRCode } from '@/functions/realtimescanner';
    import { computed } from '@vue/reactivity';
    import { useAbsenStore } from '@/stores/absen';
    import { onMounted } from 'vue';
    // import { router } from '@/router';
    const selfieResult = computed(() => useAbsenStore().selfieResult);
    const position = computed(() => useAbsenStore().position);
    useAbsenStore().setAbsen();
    onMounted(() => {
        scanQRCode(selfieResult.value);
        // if(selfieResult.value){
        //     console.log(selfieResult.value);
        // }else{
        //     router.push('/absen');
        // }
    });
    // window.onload = function(){
    //     scanFace();
    // }
    // function scanQr(){
    //     // const video = document.getElementById('video-element');
    //     // console.log(video);
    //     scanQRCode();
    // }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
    }
    .scanner{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .scanner-inner{
        width: 100%;
        max-width: 370px;
    }
    .mirror-element{
        transform: rotateY(180deg);
        -webkit-transform:rotateY(180deg); /* Safari and Chrome */
        -moz-transform:rotateY(180deg); /* Firefox */
    }
    .scanner-inner p{
        margin: 0 0 0.5rem;
        text-align: center;
    }
    .scanner-video{
        position: relative;
        width: 100%;
        height: 370px;
        max-height: 370px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid green; */
    }
    .scanner-video-line{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: lime;
        box-shadow: 0 4px 8px 0 rgba(0, 255, 13, 0.733), 0 6px 20px 0 rgba(0, 255, 34, 0.692);
        display: none;
        /* transition: 0.2s; */
    }
    .svl-active{
        display: block;
    }
    .scanner-video video{
        width: 100%;
        /* max-height: 370px;
        overflow: hidden; */
    }
    .scanner-button{
        display: flex;
        justify-content: center;
        padding: 1rem 0 1rem 0;
        user-select: none;
    }
    .scanner-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
    }
    .scanner-button button:hover{
        background: var(--primary-hover);
    }
    .selfie-result-buttons{
        display: flex;
        justify-content: center;
    }
    .srb-part{
        width: 50%;
    }
    .scanner-instruction{
        text-align: center;
    }
    /* .scanner-instruction p{
        margin: 0;
    } */
    .scelm-hidden{
        display: none;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
    }
</style>
