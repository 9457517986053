import { useAbsenStore } from "@/stores/absen";
import { showNotification, getCookie } from "./general";
export function submitBantuan(){
    const schedule = validateSchedule('');
    const type = validateType('');
    const time = validateTime('');
    const description = validateDescription('');
    const attachment = validateAttachment('');
    // console.log(schedule);
    // console.log(type);
    // console.log(time);
    // console.log(description);
    // console.log(attachment);
    if(schedule && type && time && description && attachment){
        const ipattachment = document.getElementById('sfi-attachment');
        const input = ipattachment.children[1].children[1].children[0];
        const [file] = input.files;
        console.log(input, file);
        // const params = {schedule_id: schedule, type: type, time: time, description: description, attachment: attachment};
        const fd = new FormData();
        fd.append('schedule_id', schedule);
        fd.append('type', type);
        fd.append('time', time);
        fd.append('description', description);
        fd.append('attachment', file);
        fd.append('device_id', getCookie('deviceid'));
        useAbsenStore().newAbsenBantuan(fd, 'new');
    }else{
        showNotification('', 'Semua input diperlukan', 2000, 'var(--primary)');
    }
}
export function updateBantuan(index, id){
    const schedule = validateSchedule(index);
    const type = validateType(index);
    const time = validateTime(index);
    const description = validateDescription(index);
    const attachment = validateAttachment(index);
    console.log(schedule);
    console.log(type);
    console.log(time);
    console.log(description);
    console.log(attachment);
    if(schedule && type && time && description){
        const ipattachment = document.getElementById('sfi-attachment' + index);
        const input = ipattachment.children[1].children[1].children[0];
        const [file] = input.files;
        console.log(input, file);
        // const params = {schedule_id: schedule, type: type, time: time, description: description, attachment: attachment};
        const fd = new FormData();
        fd.append('id', id);
        fd.append('schedule_id', schedule);
        fd.append('type', type);
        fd.append('time', time);
        fd.append('description', description);
        if(file){
            fd.append('attachment', file);
        }
        fd.append('device_id', getCookie('deviceid'));
        useAbsenStore().newAbsenBantuan(fd, 'update');
    }else{
        showNotification('', 'Semua input diperlukan', 2000, 'var(--primary)');
    }
}
export function validateSchedule(index){
    const ipschedule = document.getElementById('sfi-schedule' + index);
    const input = ipschedule.children[1].children[0].children;
    for(var i = 0; i < input.length; i++){
        if(input[i].children[0].checked){
            return input[i].children[0].value;
        }
    }
    return false;
}
export function validateType(index){
    const iptype = document.getElementById('sfi-type' + index);
    const input = iptype.children[1].children[0].children;
    for(var i = 0; i < input.length; i++){
        if(input[i].children[0].checked){
            return input[i].children[0].value;
        }
    }
    return false;
}
export function validateTime(index){
    const iptime = document.getElementById('sfi-time' + index);
    const input = iptime.children[1].children[0];
    if(input.value){
        return input.value.substr(0, 5);
    }
    return false;
}
export function validateDescription(index){
    const ipdesc = document.getElementById('sfi-description' + index);
    const input = ipdesc.children[1].children[0];
    if(input.value){
        return input.value;
    }
    return false;
}
export function validateAttachment(index){
    const ipattachment = document.getElementById('sfi-attachment' + index);
    const input = ipattachment.children[1].children[1].children[0];
    if(input.value){
        const [file] = input.files;
        return file;
    }
    return false;
}
// export function actionToggle(btnid){
//     if(document.getElementById(btnid)){
//         const button = document.getElementById(btnid);
//         const buttons = document.getElementsByClassName('srp-accordion-button');
//         if(button.nextElementSibling.classList.contains('sao-active')){
//             button.nextElementSibling.classList.remove('sao-active');
//             button.children[0].children[0].classList.remove('arrow-active');
//         }else{
//             for(var i = 0; i < buttons.length; i++){
//                 buttons[i].children[0].children[0].classList.remove('arrow-active');
//                 buttons[i].nextElementSibling.classList.remove('sao-active');
//             }
//             button.nextElementSibling.classList.add('sao-active');
//             button.children[0].children[0].classList.add('arrow-active');
//         }
//     }
// }

