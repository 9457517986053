import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { getCookie, showNotification, hideModals } from "@/functions/general.js";

export const useRequestStore = defineStore("request", {
    state: () => ({
        // ==================== states used on request page and its relation
        documents: null,
        searchbarSelectCategory: {input: {value: ''}},
        searchbarSelectDivision: {input: {value: ''}},
        searchbarDocument: {select: {category: {name: 'Semua', value: 'all'}, division: {name: 'Semua', value: 'all'}}, input: {value: null}},
        categories: null,
        filteredCategories: null,
        divisions: null,
        filteredDivisions: null,
        documentDetail: null,
        loader: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on request page and its relation
        async setDocuments(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`/document/request`);
                const ctres = await axiosClient.get(`/document/category`);
                const dvres = await axiosClient.get(`/org/list`);
                this.documents = response.data.data;
                this.categories = ctres.data.data;
                this.filteredCategories = ctres.data.data;
                this.divisions = dvres.data.data;
                this.filteredDivisions = dvres.data.data;
                console.log(this.documents);
            }catch(error){
                console.log(error);
            }
        },
        inputSelectCategory(value){
            this.searchbarSelectCategory.input.value = value;
            var result = [];
            var inval = new RegExp(value, 'gi');
            for(var i = 0; i < this.categories.length; i++){
                if(this.categories[i].name.match(inval)){
                    result.push(this.categories[i]);
                }
            }
            this.filteredCategories = result;
        },
        async selectCategory(){
            this.searchDocument();
        },
        inputSelectDivision(value){
            this.searchbarSelectDivision.input.value = value;
            var result = [];
            var inval = new RegExp(value, 'gi');
            for(var i = 0; i < this.divisions.length; i++){
                if(this.divisions[i].name.match(inval)){
                    result.push(this.divisions[i]);
                }
            }
            this.filteredDivisions = result;
            // this.searchDocument();
            // console.log(this.filteredDivisions);
        },
        async selectDivision(){
            this.searchDocument();
        },
        async inputDocument(value){
            this.searchbarDocument.input.value = value;
            // setTimeout(function(){
            //     // console.log(this);
            //     if(document.getElementById('sii-input-document').value == value){
            //     }
            // }, 1000);
            this.searchDocument();
            console.log(value);
        },
        async searchDocument(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                this.documents = null;
                var ct = '';
                var dv = '';
                var ctinput = this.searchbarSelectCategory.input.value;
                var dvinput = this.searchbarSelectDivision.input.value;
                var sdinput = '';
                if(this.searchbarDocument.select.category.value != 'all'){
                    ct = this.searchbarDocument.select.category.value;
                }
                if(this.searchbarDocument.select.division.value != 'all'){
                    dv = this.searchbarDocument.select.division.value;
                }
                if(this.searchbarDocument.input.value != null){
                    sdinput = this.searchbarDocument.input.value;
                }
                const response = await axiosClient.get(`/document/content?org=` + dv + `&sOrg=` + dvinput + `&category=` + ct + `&sCategory=` + ctinput + `&search=` + sdinput);
                this.documents = response.data.data;
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async setDocumentDetail(slug){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`/document/content/` + slug);
                this.documentDetail = response.data.data;
                console.log(this.documentDetail);
            }catch(error){
                console.log(error);
            }
        },
        async updateRequestAccess(params){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.loader = true;
            try{
                const response = await axiosClient.post(`/document/request/`, params);
                if(response.data.success){
                    // document.getElementById(note).value = '';
                    showNotification('', 'Update Request berhasil', 2000, 'var(--primary)');
                    hideModals();
                    this.setDocuments();
                }
                this.loader = null;
                console.log(response);
            }catch(error){
                this.loader = null;
                console.log(error);
            }
        }
    }
});
