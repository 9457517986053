<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <h2>Chart Pendapatan</h2>
                <h3>Total Pendapatan Rp.17.750.000</h3>
            </div>
            <div class="si-body">
                <Doughnut :data="data" :options="options" />
            </div>
        </div>
    </section>
</template>

<script setup>
    import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
    import { Doughnut } from 'vue-chartjs';
    ChartJS.register(ArcElement, Tooltip, Legend);
    const data = {
        labels: ['Lembur Rp.1.500.000', 'Pengurangan Rp.250.000', 'Gaji Rp.11.000.000', 'Pinjaman Rp.5.000.000'],
        datasets: [
            {
                backgroundColor: ['rgb(24, 130, 223)', 'rgb(54, 218, 177)', 'rgb(50, 194, 69)', 'orange'],
                data: [1500000, 250000, 11000000, 5000000]
            }
        ]
    }
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                text: 'Total Rp.12.750.000',
                display: 'true',
                position: 'right',
                color: 'black'
            },
            legend: {
                position: 'right'
            },
            tooltip: {
                enabled: false
            }
        }
    }
</script>

<style scoped>
    .si-body{
        width: 100%;
    }
    .si-head h3{
        text-align: right;
    }
    .x{
        color: rgb(54, 218, 177);
    }
</style>




