import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { useAccountStore } from "./account";
import { showNotification, hideModals } from "@/functions/general";
// import { cuti } from "@/data/dummies.js";

export const useCutiStore = defineStore("cuti", {
    state: () => ({
        // ==================== states used on cuti page and its relation
        cuti: null,
        loader: false
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on cuti page and its relation
        async setCuti(){
            try{
                await useAccountStore().getProfile();
                const response = await axiosClient.get(`leave/submission`);
                this.cuti = response.data.data;
                // console.log(this.cuti);
                // console.log(navigator.userAgent);
            }catch(error){
                console.log(error);
            }
        },
        async cutiBaru(params){
            this.loader = true;
            try{
                const response = await axiosClient.post(`leave/apply`, params);
                if(response.status == 201){
                    this.setCuti();
                    showNotification('', 'Permintaan Cuti dikirim', 5000, null);
                    hideModals();
                }
                this.loader = false;
                // console.log(response);
            }catch(error){
                if(error.response){
                    showNotification('', 'Message : ' + error.response.data.message, 5000, 'var(--red)');
                }
                this.loader = false;
                console.log(error);
            }
        }
    }
});