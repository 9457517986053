import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import axiosFormData from "@/axiosFormData";
import { showNotification, hideModals, getCookie } from "@/functions/general";
// import { timeFormat } from "@/functions/lembur.js";
import { usePresensiStore } from "./presensi.js";
import { time24start, time24 } from "@/data/time.js";
import { clearForm } from "@/functions/lembur.js";

export const useLemburStore = defineStore("lembur", {
    state: () => ({
        // ==================== states used on lembur page and its relation
        lemburForm: {
            type: 0,
            schedule: {
                id: ''
            },
            date: {
                starts: null,
                ends: null
            },
            time: {
                starts: null,
                ends: null
            },
            notes: null
        },
        lembur: null,
        jadwal: null,
        loader: false,
        timeStart: null,
        time: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on lembur page and its relation
        async setLembur(){
            try{
                const lembur = await axiosClient.get(`overtime/submission`);
                const jadwal = await axiosClient.get(`shift/options`);
                this.lembur = lembur.data.data;
                this.jadwal = jadwal.data;
                this.timeStart = time24start;
                this.time = time24;
                // timeFormat('flb-endtime');
                console.log(this.lembur);
                // console.log(this.jadwal);
            }catch(error){
                console.log(error);
            }
        },
        setStartTime(time){
            console.log(time24);
            if(time.hour){
                this.timeStart.button.hour = time.hour;
                this.timeStart.input.time.hour = time.hour;
            }
            if(time.minute){
                this.timeStart.button.minute = time.minute;
                this.timeStart.input.time.minute = time.minute;
            }
            if(this.timeStart.input.time.hour && this.timeStart.input.time.minute){
                this.timeStart.input.value = this.timeStart.input.time.hour + ':' + this.timeStart.input.time.minute;
                this.lemburForm.time.starts = this.timeStart.input.time.hour + ':' + this.timeStart.input.time.minute;
            }
        },
        setEndTime(time){
            if(time.hour){
                this.time.button.hour = time.hour;
                this.time.input.time.hour = time.hour;
            }
            if(time.minute){
                this.time.button.minute = time.minute;
                this.time.input.time.minute = time.minute;
            }
            if(this.time.input.time.hour && this.time.input.time.minute){
                this.time.input.value = this.time.input.time.hour + ':' + this.time.input.time.minute;
                this.lemburForm.time.ends = this.time.input.time.hour + ':' + this.time.input.time.minute;
            }
        },
        async submitLembur(params){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosFormData.post(`overtime/submission`, params);
                if(response.data.success){
                    clearForm();
                    showNotification('', 'Permintaan Lembur dikirim', 5000, null);
                    hideModals();
                    this.setLembur();
                    usePresensiStore().setRawPresence();
                }
                this.loader = false;
            }catch(error){
                if(error.response?.status == 422){
                    showNotification('', error.response.data.message, 2000, 'var(--red)');
                }
                console.log(error);
                this.loader = false;
            }
        }
    }
});


