import router from "@/router";
export function pageScrollTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
export function showNotification(title, message, milisecond, bg){
    const date = new Date();
    const time = date.getTime();
    const nt = document.getElementById('notification');
    const tt = document.getElementById('notification-title');
    const mg = document.getElementById('notification-message');
    var background;
    nt.style.display = 'none';
    if(bg){
        background = bg;
    }else{
        background = 'rgb(76, 155, 230)';
    }
    nt.style.background = background;
    nt.setAttribute('time', time);
    setTimeout(function(){
        tt.innerHTML = title;
        mg.innerHTML = message;
        nt.style.display = 'block';
        setTimeout(function(){
            if(nt.getAttribute('time') == time){
                nt.style.display = 'none';
            }
        }, milisecond);
    }, 0);
}
export function setCookie(cname, cvalue, exdays){
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}
export function showModal(id){
    const navbar = document.getElementById('navbar');
    navbar.classList.add('navbar-down');
    const modal = document.getElementById(id);
    modal.classList.add('modal-active');
}
export function hideModals(){
    var modal = document.getElementsByClassName('modal');
    for(var i = 0; i < modal.length; i++){
        modal[i].classList.remove('modal-active');
    }
    const navbar = document.getElementById('navbar');
    navbar.classList.remove('navbar-down');
}
export function hideModal(id){
    const modal = document.getElementById(id);
    modal.classList.remove('modal-active');
    const navbar = document.getElementById('navbar');
    navbar.classList.remove('navbar-down');
}
export function showLogin(){
    const lf = document.getElementById('navbar-login-form');
    if(lf.style.display == 'block'){
        if(lf.children[0].children[1].scrollLeft > 0){
            lf.children[0].children[1].scrollLeft = 0;
        }else{
            lf.style.display = 'none';
        }
    }else{
        lf.style.display = 'block';
    }
    lf.children[0].children[1].scrollLeft = 0;
}
export function hideLogin(){
    const lf = document.getElementById('navbar-login-form');
    lf.style.display = 'none';
}
export function goTo(route){
    router.push(route);
}
export function showTab(button, btnid, hide, show, btactivename, ctactivename){
    if(document.getElementById(btnid) && document.getElementById(show)){
        var i;
        const bt = document.getElementsByClassName(button);
        const cl = document.getElementsByClassName(hide);
        for(i = 0; i < bt.length; i++){
            bt[i].classList.remove(btactivename);
        }
        for(i = 0; i < cl.length; i++){
            cl[i].classList.remove(ctactivename);
        }
        document.getElementById(btnid).classList.add(btactivename);
        document.getElementById(show).classList.add(ctactivename);
    }
}
export function wordsLimit(words, max){
    if(words.length > max) words = words.substring(0, max) + '...';
    return words;
}
export function removeHTML(html){
    var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        return text;
}
export function number_format(number, decimals, dec_point, thousands_sep){
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
export function navbarMenuSelect(id, event){
    const button = document.getElementById(id);
    const arrow = button.children[1].children[0];
    if(hasTouch()){
        if(event == 'click'){
            if(button.nextElementSibling.classList.contains('npa-option-active')){
                button.nextElementSibling.classList.remove('npa-option-active');
                button.nextElementSibling.style.height = 0;
                arrow.style.transform = 'rotate(0)';
            }else{
                button.nextElementSibling.classList.add('npa-option-active');
                button.nextElementSibling.style.height = button.nextElementSibling.scrollHeight + 'px';
                arrow.style.transform = 'rotate(180deg)';
            }
        }
    }
    if(!hasTouch()){
        if(event == 'enter'){
            button.nextElementSibling.classList.add('npa-option-active');
            button.nextElementSibling.style.height = button.nextElementSibling.scrollHeight + 'px';
            arrow.style.transform = 'rotate(180deg)';
        }
        if(event == 'leave'){
            button.nextElementSibling.classList.remove('npa-option-active');
            button.nextElementSibling.style.height = 0;
            arrow.style.transform = 'rotate(0)';
        }
    }
}
export function topSearchSelect(id){
    const button = document.getElementById(id);
    const arrow = button.children[2].children[0].children[0];
    if(button.nextElementSibling.classList.contains('float-active')){
        button.nextElementSibling.classList.remove('float-active');
        arrow.style.transform = 'rotate(0)';
    }else{
        button.nextElementSibling.classList.add('float-active');
        arrow.style.transform = 'rotate(180deg)';
    }
}
export function hasTouch() {
    return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
}
export function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}
export const createSlug = str => str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
export function scheduleHour(time){
    if(time){
        return time.slice(0, 5);
    }
}
export function accordionToggle(id){
    const bt = document.getElementById(id);
    const pn = bt.nextElementSibling;
    if(pn.style.maxHeight){
        bt.children[1].children[0].style.transform = 'rotate(0deg)';
        pn.style.maxHeight = null;
    }else{
        bt.children[1].children[0].style.transform = 'rotate(180deg)';
        pn.style.maxHeight = pn.scrollHeight + 'px';
    }
}
export function optionToggle(button){
    const float = document.getElementsByClassName('float');
    for(var i = 0; i < float.length; i++){
        const thisbt = float[i].previousElementSibling;
        if(thisbt.getAttribute('id') != button){
            float[i].classList.remove('float-active');
            thisbt.children[2].classList.remove('float-icon-active');
        }
    }
    const bt = document.getElementById(button);
    if(bt.nextElementSibling.classList.contains('float-active')){
        bt.nextElementSibling.classList.remove('float-active');
        bt.children[2].classList.remove('float-icon-active');
    }else{
        bt.nextElementSibling.classList.add('float-active');
        bt.children[2].classList.add('float-icon-active');
    }
}
export function selectOption(sp, option){
    document.getElementById(option.input).value = sp.value;
    document.getElementById(option.title).innerHTML = sp.title;
}
export function clearInput(ic){
    const input = document.getElementsByClassName(ic);
    for(var i = 0; i < input.length; i++){
        input[i].value = '';
    }
}
export function clearCheckBox(ic){
    const checkbox = document.getElementsByClassName(ic);
    for(var i = 0; i < checkbox.length; i++){
        checkbox[i].checked = false;
    }
}
export function clickElement(id){
    document.getElementById(id).click();
}
export function firstCapwords(str){
    const words = str.toLowerCase().split(' ');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
}
export function timeToYmdhms(milliseconds){ // returns how many years, months, days, hours, minutes and seconds
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    const secondsRemainder = seconds % 60;
    const minutesRemainder = minutes % 60;
    const hoursRemainder = hours % 24;
    const daysRemainder = days % 30;
    const monthsRemainder = months % 12;

    return {
        years,
        months: monthsRemainder,
        days: daysRemainder,
        hours: hoursRemainder,
        minutes: minutesRemainder,
        seconds: secondsRemainder,
    };
}
export function leftPadNum(number, targetLength, paddingChar) {
    let str = number.toString();
    let paddingLength = targetLength - str.length;
    if (paddingLength > 0) {
        let padding = '';
        for (let i = 0; i < paddingLength; i++) {
        padding += paddingChar;
        }
        str = padding + str;
    }
    return str;
}
export function inputSearchOptionXXXXX(e){
    const search = e.srcElement.parentElement;
    if(e.srcElement.value){
        var clear = false;
        for(var i = 0; i < search.children.length; i++){
            if(search.children[i].classList.contains('clear-button')){
                clear = true;
            }
        }
        if(!clear){
            var el = document.createElement('div');
            var text = document.createTextNode('x');
            el.appendChild(text);
            el.classList.add('float-button');
            el.classList.add('clear-button');
            el.style.cssText = 'position: absolute; top: 0; right: 0; height: 100%; width: 35px; display: flex; justify-content: center; align-items: center; cursor: pointer;';
            search.appendChild(el);
        }
    }else{
        for(i = 0; i < search.children.length; i++){
            if(search.children[i].classList.contains('clear-button')){
                search.children[i].remove();
            }
        }
    }
}
export function selectToggle(id){
    const select = document.getElementById(id);
    const option = document.getElementsByClassName('option');
    const icon = document.getElementsByClassName('option-icon');
    if(select.children[1]){
        if(select.children[1].classList.contains('option-active')){
            select.children[0].children[1].classList.remove('option-icon-active');
            select.children[1].classList.remove('option-active');
        }else{
            for(var i = 0; i < option.length; i++){
                option[i].classList.remove('option-active');
            }
            for(i = 0; i < icon.length; i++){
                icon[i].classList.remove('option-icon-active');
            }
            select.children[0].children[1].classList.add('option-icon-active');
            select.children[1].classList.add('option-active');
        }
    }
}
export function inputFocus(id){
    if(document.getElementById(id)){
        document.getElementById(id).focus();
    }
}
export function elementScrollTop(id){
    if(document.getElementById(id)){
        document.getElementById(id).scrollTop = 0;
    }
}
export function dropdownToggle(id){
    const select = document.getElementById(id);
    const option = document.getElementsByClassName('option');
    const icon = document.getElementsByClassName('option-icon');
    if(select.children[1]){
        if(select.children[1].classList.contains('option-active')){
            select.children[0].children[1].classList.remove('option-icon-active');
            select.children[1].classList.remove('option-active');
        }else{
            for(var i = 0; i < option.length; i++){
                option[i].classList.remove('option-active');
            }
            for(i = 0; i < icon.length; i++){
                icon[i].classList.remove('option-icon-active');
            }
            select.children[0].children[1].classList.add('option-icon-active');
            select.children[1].classList.add('option-active');
        }
    }
}
export function getDay(daynum){
    const daylist = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    if(daynum >= 0 && daynum < daylist.length){
        return daylist[daynum];
    }else{
        return "-";
    }
}
export function actionToggle(btnid){
    if(document.getElementById(btnid)){
        const button = document.getElementById(btnid);
        const buttons = document.getElementsByClassName('srp-accordion-button');
        if(button.nextElementSibling.classList.contains('sao-active')){
            button.nextElementSibling.classList.remove('sao-active');
            button.children[0].children[0].classList.remove('arrow-active');
        }else{
            for(var i = 0; i < buttons.length; i++){
                buttons[i].children[0].children[0].classList.remove('arrow-active');
                buttons[i].nextElementSibling.classList.remove('sao-active');
            }
            button.nextElementSibling.classList.add('sao-active');
            button.children[0].children[0].classList.add('arrow-active');
        }
    }
}
export function hideDropdowns(){
    const dropdowns = document.getElementsByClassName('dropdown');
    var i;
    for(i = 0; i < dropdowns.length; i++){
        dropdowns[i].children[0].children[1].classList.remove('dbi-active');
        dropdowns[i].children[1].classList.remove('dropdown-option-active');
    }
}
export function generateRandomString(length) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // You can include more characters if needed
    let randomString = '';
    
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomString += charset[randomIndex];
    }
  
    return randomString;
}
export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}
export function get12MonthsData() {
    const months = [
      { index: 0, name: 'Januari' },
      { index: 1, name: 'Pebruari' },
      { index: 2, name: 'Maret' },
      { index: 3, name: 'April' },
      { index: 4, name: 'Mei' },
      { index: 5, name: 'Juni' },
      { index: 6, name: 'Juli' },
      { index: 7, name: 'Agustus' },
      { index: 8, name: 'September' },
      { index: 9, name: 'Oktober' },
      { index: 10, name: 'November' },
      { index: 11, name: 'Desember' }
    ];
  
    return months;
}
export function openNewPage(page){
    window.open(page);
}
// export function validateFileType(id){
//     var selectedFile = document.getElementById(id).files[0];
//     var allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

//     if (!allowedTypes.includes(selectedFile.type)) {
//        alert('Invalid file type. Please upload a JPEG, PNG, or PDF file.');
//        document.getElementById(id).value = '';
//     }
// }





