import { useCutiStore } from "@/stores/cuti";
export function cutiBaru(){
    const start = document.getElementById('input-cuti-mulai');
    const end = document.getElementById('input-cuti-selesai');
    const note = document.getElementById('input-cuti-keterangan');
    useCutiStore().cutiBaru({start_date: start.value, end_date: end.value, description: note.value});
}
export function showTab(cl, id){
    const button = document.getElementsByClassName(cl.button);
    const content = document.getElementsByClassName(cl.content);
    for(var i = 0; i < button.length; i++){
        button[i].classList.remove('shp-active');
    }
    for(i = 0; i < content.length; i++){
        content[i].classList.remove('sbp-active');
    }
    document.getElementById(id.button).classList.add('shp-active');
    document.getElementById(id.content).classList.add('sbp-active');
}
