<template>
    <section>
        <div class="section-inner">
            <div class="coordinate" v-if="position">
                <!-- <div class="latitude">
                    <p>Latitude : {{ position.coords.latitude }}</p>
                </div>
                <div class="longitude">
                    <p>Longitude : {{ position.coords.longitude }}</p>
                </div> -->
                <div class="distance">
                    <!-- <p>Distance : {{ position.detail?.distance?.value || null }} KM</p> -->
                    <div class="detected" v-if="position.detail?.distance?.detected">
                        <!-- <p>Detected Distance</p>
                        <div>
                            {{ position.detail.distance }}
                        </div> -->
                        <!-- <div v-for="dt in position.detail?.distance?.detected" :key="dt">
                            <p>{{ dt }}</p>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="scanner" v-if="!scanner.status">
                <div class="scanner-inner">
                    <div class="scanner-button">
                        <button id="start-camera">Buka Kamera</button>
                    </div>
                    <div class="selfie-video scelm-hidden" id="selfie-video">
                        <p>Selfie heula atuh !</p>
                        <video class="mirror-element" id="video-selfie" width="350" height="500"></video>
                        <div class="camera-select" v-if="cameras">
                            <!-- <div class="camera-option" v-for="camera in cameras" :key="camera">
                                <span>{{ camera.label }}</span>
                            </div> -->
                        </div>
                        <div class="scanner-button">
                            <button id="capture-photo">Cekrek</button>
                        </div>
                    </div>
                    <div class="selfie-result scelm-hidden">
                        <p>Sudah kece belum ?</p>
                        <canvas class="mirror-element" id="canvas-photo" width="350" height="475"></canvas>
                        <div class="selfie-result-buttons">
                            <div class="srb-part">
                                <div class="scanner-button">
                                    <button id="repeat-capture">Ulang</button>
                                </div>
                            </div>
                            <div class="srb-part">
                                <div class="scanner-button">
                                    <button id="okay-capture">Oke !</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="scanner-button">
                        <button id="start-camera" v-if="!scanner.status">Buka Kamera</button>
                    </div>
                    <div class="scanner-button">
                        <button id="capture-photo" v-if="!scanner.status">Ambil Foto</button>
                    </div>
                    <div class="scanner-video">
                        <video class="video" id="video-selfie" width="320" height="240" autoplay></video>
                        <div class="scanner-canvas">
                            <canvas id="canvas-photo" width="320" height="240"></canvas>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="scanner" v-if="!scanner.status">
                <div class="scanner-inner scelm-hidden" id="scanner-video">
                    <div class="scanner-instruction">
                        <p>Sekarang Scan QR Code pada monitor !</p>
                    </div>
                    <div class="scanner-video">
                        <div class="scanner-video-line" id="svl-absen"></div>
                        <video class="video" id="video-element"></video>
                    </div>
                </div>
            </div> -->
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    // import { scanner } from '@/data/absen';
    import { scanFace } from '@/functions/realtimescanner';
    import { useAbsenStore } from '@/stores/absen';
    import { onMounted, onUnmounted } from 'vue';
    const cameras = computed(() => useAbsenStore().cameras);
    const scanner = computed(() => useAbsenStore().scanner);
    const position = computed(() => useAbsenStore().position);
    useAbsenStore().setAbsen();
    onMounted(() => {
        scanFace();
    });
    onUnmounted(() => {
        // 
    });
    // window.onload = function(){
    //     scanFace();
    // }
    // function scanQr(){
    //     // const video = document.getElementById('video-element');
    //     // console.log(video);
    //     scanQRCode();
    // }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
    }
    .scanner{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .scanner-inner{
        width: 100%;
        max-width: 370px;
    }
    .mirror-element{
        transform: rotateY(180deg);
        -webkit-transform:rotateY(180deg); /* Safari and Chrome */
        -moz-transform:rotateY(180deg); /* Firefox */
    }
    .scanner-inner p{
        margin: 0 0 0.5rem;
        text-align: center;
    }
    .scanner-video{
        position: relative;
        width: 100%;
        height: 370px;
        max-height: 370px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid green; */
    }
    .scanner-video-line{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: lime;
        box-shadow: 0 4px 8px 0 rgba(0, 255, 13, 0.733), 0 6px 20px 0 rgba(0, 255, 34, 0.692);
        display: none;
        /* transition: 0.2s; */
    }
    .svl-active{
        display: block;
    }
    .scanner-video video{
        width: 100%;
        /* max-height: 370px;
        overflow: hidden; */
    }
    .scanner-button{
        display: flex;
        justify-content: center;
        padding: 1rem 0 1rem 0;
        user-select: none;
    }
    .scanner-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
    }
    .scanner-button button:hover{
        background: var(--primary-hover);
    }
    .camera-select{
        width: 100%;
        display: flex;
        overflow: auto;
    }
    .camera-option{
        min-width: 100px;
        background: var(--primary);
        color: var(--white);
        margin: 0 0.5rem 0 0;
        cursor: pointer;
        user-select: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .selfie-result-buttons{
        display: flex;
        justify-content: center;
    }
    .srb-part{
        width: 50%;
    }
    .scanner-instruction{
        text-align: center;
    }
    /* .scanner-instruction p{
        margin: 0;
    } */
    .scelm-hidden{
        display: none;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
    }
</style>
