<template>
    <section>
        <div class="section-inner">
            <div class="scanner">
                <div class="scanner-inner">
                    <button id="start-camera">Start Camera</button>
                    <video class="video-selfie" id="video-selfie" width="320" height="240" autoplay></video>
                    <button id="capture-photo">Click Photo</button>
                    <canvas class="capture-photo" id="canvas-photo" width="320" height="240"></canvas>
                    <div class="scanner-button">
                        <button @click="scanFace()" v-if="!scanner.status">Ambil Foto</button>
                    </div>
                    <div class="scanner-video">
                        <div class="scanner-video-line" id="svl-absen"></div>
                        <video class="video" id="video-element"></video>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { scanFace } from '@/functions/realtimescanner';
    import { useAbsenStore } from '@/stores/absen';
    import { onMounted } from 'vue';
    const scanner = computed(() => useAbsenStore().scanner);
    useAbsenStore().setAbsen();
    onMounted(() => {
        scanFace();
    });
    // window.onload = function(){
    //     scanFace();
    // }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
    }
    .scanner{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .scanner-inner{
        width: 100%;
        max-width: 370px;
    }
    .video-selfie{
        transform: rotateY(180deg);
        -webkit-transform:rotateY(180deg); /* Safari and Chrome */
        -moz-transform:rotateY(180deg); /* Firefox */
    }
    .scanner-video{
        position: relative;
        width: 100%;
        height: 370px;
        max-height: 370px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid green; */
    }
    .scanner-video-line{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: lime;
        box-shadow: 0 4px 8px 0 rgba(0, 255, 13, 0.733), 0 6px 20px 0 rgba(0, 255, 34, 0.692);
        display: none;
        /* transition: 0.2s; */
    }
    .svl-active{
        display: block;
    }
    .scanner-video video{
        width: 100%;
        /* max-height: 370px;
        overflow: hidden; */
    }
    .scanner-button{
        display: flex;
        justify-content: center;
        padding: 1rem 0 1rem 0;
    }
    .scanner-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
    }
    .scanner-button button:hover{
        background: var(--primary-hover);
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
    }
</style>
