import { useLemburStore } from "@/stores/lembur";
import { showNotification } from "./general";
import { time24, time24start } from "@/data/time";
export function selectType(type){
    useLemburStore().lemburForm.type = type;
    // console.log(useLemburStore().lemburForm);
}export function selectSchedule(schedule){
    useLemburStore().lemburForm.schedule.id = schedule.id;
    // console.log(schedule);
}
export function selectStartDate(id){
    useLemburStore().lemburForm.date.starts = document.getElementById(id).value;
}
export function selectEndDate(id){
    useLemburStore().lemburForm.date.ends = document.getElementById(id).value;
}
export function setStartTime(time){
    let hour = null;
    let minute = null;
    if(time.hour){
        hour = time.hour;
    }
    if(time.minute){
        minute = time.minute;
    }
    useLemburStore().setStartTime({hour: hour, minute: minute});
    console.log(time);
}
export function setEndTime(time){
    let hour = null;
    let minute = null;
    if(time.hour){
        hour = time.hour;
    }
    if(time.minute){
        minute = time.minute;
    }
    useLemburStore().setEndTime({hour: hour, minute: minute});
    console.log(time);
}
export function inputLampiran(){
    const inform = document.getElementById('flb-attachments');
    const el = document.createElement('div');
    el.classList.add('element-relative');
    el.style.cssText = 'width: 100%;';
    el.innerHTML = '<input type="file"/><div class="sfi-input-remove" style="position: absolute; top: 0; right: 0; width: 50px; height: 100%; cursor: pointer; display: flex; justify-content: right; align-items: center; padding: 0.5rem; transition: 0.2s;">x</div>';
    el.children[1].addEventListener('click', function(e){
        e.target.parentElement.remove();
    });
    inform.appendChild(el);
}
export function setNotes(id){
    useLemburStore().lemburForm.notes = document.getElementById(id).value;
}
export async function submitLembur(){
    // const fd = new FormData();
    // const id = document.getElementById('input-schedule-option');
    // const endtime = document.getElementById('flb-endtime');
    // const description = document.getElementById('flb-description');
    // const attachments = document.getElementById('flb-attachments');
    // for(var i = 0; i < attachments.children.length; i++){
    //     if(attachments.children[i].children[0].value){
    //         const [files] = await attachments.children[i].children[0].files;
    //         fd.append(`attachments[${i}][file]`, files);
    //     }
    // }
    // fd.append('schedule_id', id.value);
    // fd.append('time_end', endtime.value);
    // fd.append('description', description.value);
    // useLemburStore().submitLembur(fd);
    console.log(useLemburStore().lemburForm);
    const lform = useLemburStore().lemburForm;
    const fd = new FormData();
    // const id = document.getElementById('input-schedule-option');
    // const endtime = document.getElementById('flb-endtime');
    // const description = document.getElementById('flb-description');
    
    if(lform.type == 1){
        if(!lform.schedule.id){
            showNotification('', 'Pilih jadwal dahulu!', 2000, 'var(--red)');
            return;
        }
        if(!lform.time.ends){
            showNotification('', 'Masukkan waktu lembur selesai dahulu!', 2000, 'var(--red)');
            return;
        }
        fd.append('type', lform.type);
        fd.append('schedule_id', lform.schedule.id);
        fd.append('time_end', lform.time.ends);
    }else if(lform.type == 2){
        if(!lform.date.starts){
            showNotification('', 'Masukkan tanggal lembur dimulai dahulu!', 2000, 'var(--red)');
            return;
        }
        if(!lform.date.ends){
            showNotification('', 'Masukkan tanggal lembur selesai dahulu!', 2000, 'var(--red)');
            return;
        }
        if(!lform.time.starts){
            showNotification('', 'Masukkan waktu lembur mulai dahulu!', 2000, 'var(--red)');
            return;
        }
        if(!lform.time.ends){
            showNotification('', 'Masukkan waktu lembur selesai dahulu!', 2000, 'var(--red)');
            return;
        }
        fd.append('type', lform.type);
        fd.append('date_start', lform.date.starts);
        fd.append('date_end', lform.date.ends);
        fd.append('time_start', lform.time.starts);
        fd.append('time_end', lform.time.ends);
    }else{
        showNotification('', 'Pilih Tipe Lembur dahulu!', 2000, 'var(--red)');
        return;
    }
    fd.append('description', lform.notes);
    const attachments = document.getElementById('flb-attachments');
    var atcount = 0;
    for(var i = 0; i < attachments.children.length; i++){
        if(attachments.children[i].children[0].value){
            atcount = atcount + 1;
            const [files] = await attachments.children[i].children[0].files;
            fd.append(`attachments[${i}]`, files);
        }
    }
    if(atcount == 0){
        showNotification('', 'Diperlukan setidaknya 1 lampiran.', 2000, 'var(--red)');
        return;
    }
    useLemburStore().submitLembur(fd);
}
export function clearForm(){
    useLemburStore().lemburForm = {
        type: 0,
        schedule: {
            id: ''
        },
        date: {
            starts: null,
            ends: null
        },
        time: {
            starts: null,
            ends: null
        },
        notes: null
    };
    document.getElementById('ssb-text-tpoption').innerHTML = '<span>Pilih Tipe Lembur</span>';
    time24start.button = {
        hour: '- -',
        minute: '- -'
    };
    time24.button = {
        hour: '- -',
        minute: '- -'
    };
    const attachments = document.getElementById('flb-attachments');
    for(var i = 1; i < attachments.children.length; i++){
        if(attachments.children[i].children[0].value){
            attachments.children[i].children[0].value = null;
        }
    }
    for(i = 2; i < attachments.children.length; i++){
        attachments.children[i].remove();
    }
}







