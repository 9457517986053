<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <h1>KPI</h1>
                <div class="si-head-inner">
                    <div class="cuti-baru">
                        <div class="cuti-baru-button">
                            <button @click="showModal('cuti-baru-modal');">Buat KPI Baru</button>
                        </div>
                        <div class="modal srp-modal" id="cuti-baru-modal">
                            <div class="srp-modal-head">
                                <div class="smh-close" @click="hideModal('cuti-baru-modal')">
                                    <span>x</span>
                                </div>
                            </div>
                            <div class="srp-modal-body">
                                <div class="smb-form" id="form-new-kpi">
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Tanggal</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="date" :id="'input-date-new'"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Pekerjaan</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="text" :id="'input-title-new'"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <input type="hidden" name="friend" label="Poli" class="poli-input" :id="'input-kpitype-new'"/>
                                        <div class="sfi-label">Pilih Jenis Pekerjaan</div>
                                        <div class="sfi-select" v-if="kpiOptions">
                                            <div class="sfi-select-button" :id="'ssb-signup-specialistnew'" @click="optionToggle('ssb-signup-specialistnew');">
                                                <div class="btn-cover float-button"></div>
                                                <div class="ssb-text" :id="'ssb-text-temannew'">
                                                    <span>Pilih Jenis Pekerjaan</span>
                                                </div>
                                                <div class="float-icon">
                                                    <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                </div>
                                            </div>
                                            <div class="sfi-select-option float" id="sso-friend">
                                                <div class="sso-notif">
                                                    <span v-if="kpiOptions.length > 1">ada {{ (kpiOptions.length) }} Jenis Pekerjaan</span>
                                                    <span v-else></span>
                                                </div>
                                                <div class="sso-search float-button">
                                                    <div class="sso-search-inner float-button">
                                                        <div class="sso-search-input float-button">
                                                            <input type="text" class="input-select float-button" id="input-searchjob-new" @input="inputSf('input-searchjob-new');" placeholder="masukkan judul pekerjaan" autocomplete="off"/>
                                                        </div>
                                                        <div class="sso-search-close float-button">
                                                            <div class="ssc-button float-button" @click="clearInputSf('input-searchjob-new');">
                                                                <span class="float-button">x</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sso-button-list">
                                                    <template v-for="friend in kpiOptions" :key="friend.id">
                                                        <div class="sso-button" @click="selectOption({title: friend.name, value: friend?.id}, {button: 'ssb-signup-specialist', input: 'input-kpitype-new', title: 'ssb-text-temannew'}); selectFriend();">
                                                            <span>{{ friend.name }}</span>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>Loading.....</div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Deskripsi</span>
                                        </div>
                                        <div class="sfi-input">
                                            <textarea :id="'input-description-new'"></textarea>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-button" v-if="!loader">
                                            <button @click="newKpi();">Buat KPI</button>
                                        </div>
                                        <div class="sfi-button" v-else>
                                            <button>Loading.....</button>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="si-body">
                <div class="si-body-tab">
                    <div class="sbt-head">
                        <div class="sbt-head-part shp-active" id="shp-all" @click="showTab({button: 'sbt-head-part', content: 'sbt-body-part'}, {button: 'shp-all', content: 'sbp-all'});">
                            <span>KPI Saya</span>
                        </div>
                        <div class="sbt-head-part" id="shp-transfer" @click="showTab({button: 'sbt-head-part', content: 'sbt-body-part'}, {button: 'shp-transfer', content: 'sbp-transfer'});">
                            <span>Laporan</span>
                        </div>
                    </div>
                    <div class="sbt-body">
                        <div class="sbt-body-part sbp-active" id="sbp-all">
                            <div class="sbp-filter">
                                <h2>Filter</h2>
                                <div class="sbp-filter-board">
                                    <div class="sfb-part sfb-part-from">
                                        <h3>Dari Tanggal</h3>
                                        <input type="date" id="filter-from-kpi" filter="from" @change="kpiFilterSelectDateFrom('filter-from-kpi');"/>
                                        <!-- <div class="ss-select" id="reports-select-year">
                                            <div class="ss-select-button trigger" @click="selectToggle('reports-select-year');">
                                                <div class="ssb-text trigger">
                                                    <span class="trigger" v-if="kpiReports?.filter?.selected?.year">Tahun {{ kpiReports?.filter?.selected?.year }}</span>
                                                    <span class="trigger" v-else>Pilih Tahun</span>
                                                </div>
                                                <div class="ssb-icon trigger option-icon">
                                                    <img class="trigger" src="../../assets/images/icons/arrow-1.png" alt="melinda care"/>
                                                </div>
                                            </div>
                                            <div class="ss-select-option option trigger">
                                                <div class="sso-list trigger" id="sso-list-specialist">
                                                    <div class="sso-list-part" v-for="year in kpiReports?.filter?.years" :key="year" @click="useKpiStore().selectYear(year); selectToggle('reports-select-year');">
                                                        <span>{{ year }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="sfb-part sfb-part-to">
                                        <!-- <div class="ss-select" id="reports-select-month">
                                            <div class="ss-select-button trigger" @click="selectToggle('reports-select-month');">
                                                <div class="ssb-text trigger">
                                                    <span class="trigger" v-if="kpiReports?.filter?.selected?.month?.name">Bulan {{ kpiReports?.filter?.selected?.month?.name }}</span>
                                                    <span class="trigger" v-else>Pilih Bulan</span>
                                                </div>
                                                <div class="ssb-icon trigger option-icon">
                                                    <img class="trigger" src="../../assets/images/icons/arrow-1.png" alt="melinda care"/>
                                                </div>
                                            </div>
                                            <div class="ss-select-option option trigger">
                                                <div class="sso-list trigger" id="sso-list-specialist">
                                                    <div class="sso-list-part" v-for="month in kpiReports?.filter?.months" :key="month" @click="useKpiStore().selectMonth(month); selectToggle('reports-select-month');">
                                                        <span>{{ month.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <h3>Hingga Tanggal</h3>
                                        <input type="date" id="filter-to-kpi" filter="to" @change="kpiFilterSelectDateTo('filter-to-kpi');"/>
                                    </div>
                                    <div class="sfb-part sbf-part-status">
                                        <h3>Status</h3>
                                        <div class="ss-select" id="kpi-select-status">
                                            <div class="ss-select-button trigger" @click="selectToggle('kpi-select-status');">
                                                <div class="ssb-text trigger">
                                                    <span class="trigger" v-if="dataFilter?.selected?.status?.name">{{ dataFilter?.selected?.status?.name }}</span>
                                                    <span class="trigger" v-else>Pilih Status</span>
                                                </div>
                                                <div class="ssb-icon trigger option-icon">
                                                    <img class="trigger" src="../../assets/images/icons/arrow-1.png" alt="melinda care"/>
                                                </div>
                                            </div>
                                            <div class="ss-select-option option trigger">
                                                <div class="sso-list trigger" id="sso-list-specialist">
                                                    <div class="sso-list-part" v-for="status in dataFilter?.status" :key="status" @click="useKpiStore().kpiFilterSelectStatus(status); selectToggle('kpi-select-status');">
                                                        <span>{{ status.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <input type="date" id="filter-to-jadwal" filter="to" @change="inputJadwalFilter"/> -->
                                    </div>
                                    <div class="sfb-part sfb-part-button">
                                        <div class="spb-inner">
                                            <div class="spb-reset">
                                                <button @click="useKpiStore().resetData();">Reset</button>
                                            </div>
                                            <div class="spb-filter">
                                                <button v-if="!filterLoader" @click="useKpiStore().filterData();">Filter</button>
                                                <button v-else>Memfilter...</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="allData?.data">
                                <div class="sbp-content" v-if="allData?.data?.length > 0">
                                    <div class="sbi-table">
                                        <div class="sbi-table-fixed">
                                            <div class="sbi-table-head stf-head">
                                                <div class="shp-num">
                                                    <span>No</span>
                                                </div>
                                                <div class="stf-head-part">
                                                    <span>Tanggal</span>
                                                </div>
                                            </div>
                                            <div class="sbi-table-body">
                                                <div :class="'stb-row stb-row-' + (index % 2)" v-for="(data, index) in allData.data" :key="data">
                                                    <div class="sbp-num">
                                                        <span>{{ index + allData?.meta?.from }}</span>
                                                    </div>
                                                    <div class="stf-body-part">
                                                        <div class="day-name">{{ data.day }}</div>
                                                        <span>{{ data.date }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sbi-table-scroll" @scroll="tableScroll">
                                            <div class="sbi-table-head">
                                                <div class="sts-head-part">
                                                    <span>Judul</span>
                                                </div>
                                                <div class="sts-head-part">
                                                    <span>Deskripsi</span>
                                                </div>
                                                <div class="sts-head-part">
                                                    <span>Status</span>
                                                </div>
                                                <div class="sts-head-part">
                                                    <span>Action</span>
                                                </div>
                                            </div>
                                            <div class="sbi-table-body">
                                                <div class="stb-row" v-for="(data, index) in allData.data" :key="data">
                                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                                        <span>{{ wordsLimit(data.name, 25) }}</span>
                                                    </div>
                                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                                        <span>{{ wordsLimit(removeHTML(data.description), 20) }}</span>
                                                    </div>
                                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                                        <span>{{ data.s_status.string }}</span>
                                                    </div>
                                                    <div :class="'sts-row-part srp-option srp-' + (index % 2)">
                                                        <div class="srp-accordion">
                                                            <div class="srp-accordion-button saotg" :id="'sab-documents-action-' + index" @click="actionToggle('sab-documents-action-' + index)">
                                                                <div class="sab-icon saotg">
                                                                    <img class="saotg" src="../../assets/images/icons/arrow-1.png" alt="arrow"/>
                                                                </div>
                                                            </div>
                                                            <div class="srp-accordion-options">
                                                                <div class="sao-list">
                                                                    <div class="saotg">
                                                                        <span class="saotg" @click="showModal('modal-detail-' + index)">Detail KPI</span>
                                                                    </div>
                                                                    <div class="modal srp-modal" :id="'modal-detail-' + index">
                                                                        <div class="srp-modal-head">
                                                                            <div class="smh-close" @click="hideModal('modal-detail-' + index)">
                                                                                <span>x</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="srp-modal-body">
                                                                            <div class="smb-form" id="form-detail-kpi">
                                                                                <h2>Detail KPI</h2>
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-label">
                                                                                        <span>Tanggal</span>
                                                                                    </div>
                                                                                    <div class="sfi-input">
                                                                                        <p>{{ data.date }}</p>
                                                                                        <!-- <input type="date" :id="'input-date-' + index" :value="data.date"/> -->
                                                                                    </div>
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-label">
                                                                                        <span>Pekerjaan</span>
                                                                                    </div>
                                                                                    <div class="sfi-input">
                                                                                        <p>{{ data.name }}</p>
                                                                                        <!-- <input type="text" :id="'input-title-' + index" :value="data.name"/> -->
                                                                                    </div>
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <p>{{ data.kpi_name }}</p>
                                                                                    <!-- <input type="hidden" name="friend" label="Poli" class="poli-input" :id="'input-kpitype-' + index" :value="data.kpi_id"/>
                                                                                    <div class="sfi-label">Pilih Jenis Pekerjaan</div>
                                                                                    <div class="sfi-select" v-if="kpiOptions">
                                                                                        <div class="sfi-select-button" :id="'ssb-signup-specialist' + index" @click="optionToggle('ssb-signup-specialist' + index);">
                                                                                            <div class="btn-cover float-button"></div>
                                                                                            <div class="ssb-text" :id="'ssb-text-teman' + index">
                                                                                                <span v-if="data.kpi?.name">{{ data.kpi?.name }}</span>
                                                                                                <span v-else>Pilih Jenis Pekerjaan</span>
                                                                                            </div>
                                                                                            <div class="float-icon">
                                                                                                <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="sfi-select-option float" id="sso-friend">
                                                                                            <div class="sso-notif">
                                                                                                <span v-if="kpiOptions.length > 1">ada {{ (kpiOptions.length) }} Jenis Pekerjaan</span>
                                                                                                <span v-else></span>
                                                                                            </div>
                                                                                            <div class="sso-search float-button">
                                                                                                <div class="sso-search-inner float-button">
                                                                                                    <div class="sso-search-input float-button">
                                                                                                        <input type="text" class="input-select float-button" :id="'input-sjobupdate-' + index" @input="inputSf('input-sjobupdate-' + index);" placeholder="masukkan judul pekerjaan" autocomplete="off"/>
                                                                                                    </div>
                                                                                                    <div class="sso-search-close float-button">
                                                                                                        <div class="ssc-button float-button" @click="clearInputSf('input-sjobupdate-' + index);">
                                                                                                            <span class="float-button">x</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="sso-button-list">
                                                                                                <template v-for="friend in kpiOptions" :key="friend.id">
                                                                                                    <div class="sso-button" @click="selectOption({title: friend.name, value: friend?.id}, {button: 'ssb-signup-specialist', input: 'input-kpitype-' + index, title: 'ssb-text-teman' + index}); selectFriend();">
                                                                                                        <span>{{ friend.name }}</span>
                                                                                                    </div>
                                                                                                </template>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-else>Loading.....</div> -->
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-label">
                                                                                        <span>Deskripsi</span>
                                                                                    </div>
                                                                                    <div class="sfi-input">
                                                                                        <div v-html="data.description"></div>
                                                                                        <!-- <textarea :id="'input-description-' + index" :value="!loader ? removeHTML(data.description) : 'Loading'"></textarea> -->
                                                                                    </div>
                                                                                </div>
                                                                                <!-- <div class="smb-form-input">
                                                                                    <div class="sfi-button" v-if="!loader">
                                                                                        <button @click="updateKpi(data, index)">Sunting</button>
                                                                                    </div>
                                                                                    <div class="sfi-button" v-else>
                                                                                        <button>Loading.....</button>
                                                                                    </div>
                                                                                </div> -->
                                                                                <br/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="sao-list" v-if="data.status == 0 || data.status == 1">
                                                                    <div class="saotg">
                                                                        <span class="saotg" @click="showModal('modal-update-' + index)">Sunting</span>
                                                                    </div>
                                                                    <div class="modal srp-modal" :id="'modal-update-' + index">
                                                                        <div class="srp-modal-head">
                                                                            <div class="smh-close" @click="hideModal('modal-update-' + index)">
                                                                                <span>x</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="srp-modal-body">
                                                                            <div class="smb-form" id="form-update-kpi">
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-label">
                                                                                        <span>Tanggal</span>
                                                                                    </div>
                                                                                    <div class="sfi-input">
                                                                                        <input type="date" :id="'input-date-' + index" :value="data.date"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-label">
                                                                                        <span>Pekerjaan</span>
                                                                                    </div>
                                                                                    <div class="sfi-input">
                                                                                        <input type="text" :id="'input-title-' + index" :value="data.name"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <input type="hidden" name="friend" label="Poli" class="poli-input" :id="'input-kpitype-' + index" :value="data.kpi_id"/>
                                                                                    <div class="sfi-label">Pilih Jenis Pekerjaan</div>
                                                                                    <div class="sfi-select" v-if="kpiOptions">
                                                                                        <div class="sfi-select-button" :id="'ssb-signup-specialist' + index" @click="optionToggle('ssb-signup-specialist' + index);">
                                                                                            <div class="btn-cover float-button"></div>
                                                                                            <div class="ssb-text" :id="'ssb-text-teman' + index">
                                                                                                <span v-if="data.kpi?.name">{{ data.kpi?.name }}</span>
                                                                                                <span v-else>Pilih Jenis Pekerjaan</span>
                                                                                            </div>
                                                                                            <div class="float-icon">
                                                                                                <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="sfi-select-option float" id="sso-friend">
                                                                                            <div class="sso-notif">
                                                                                                <span v-if="kpiOptions.length > 1">ada {{ (kpiOptions.length) }} Jenis Pekerjaan</span>
                                                                                                <span v-else></span>
                                                                                            </div>
                                                                                            <div class="sso-search float-button">
                                                                                                <div class="sso-search-inner float-button">
                                                                                                    <div class="sso-search-input float-button">
                                                                                                        <input type="text" class="input-select float-button" :id="'input-sjobupdate-' + index" @input="inputSf('input-sjobupdate-' + index);" placeholder="masukkan judul pekerjaan" autocomplete="off"/>
                                                                                                    </div>
                                                                                                    <div class="sso-search-close float-button">
                                                                                                        <div class="ssc-button float-button" @click="clearInputSf('input-sjobupdate-' + index);">
                                                                                                            <span class="float-button">x</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="sso-button-list">
                                                                                                <template v-for="friend in kpiOptions" :key="friend.id">
                                                                                                    <div class="sso-button" @click="selectOption({title: friend.name, value: friend?.id}, {button: 'ssb-signup-specialist', input: 'input-kpitype-' + index, title: 'ssb-text-teman' + index}); selectFriend();">
                                                                                                        <span>{{ friend.name }}</span>
                                                                                                    </div>
                                                                                                </template>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-else>Loading.....</div>
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-label">
                                                                                        <span>Deskripsi</span>
                                                                                    </div>
                                                                                    <div class="sfi-input">
                                                                                        <textarea :id="'input-description-' + index" :value="removeHTML(data.description)"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-button" v-if="!loader">
                                                                                        <button @click="updateKpi(data, index)">Sunting</button>
                                                                                    </div>
                                                                                    <div class="sfi-button" v-else>
                                                                                        <button>Loading.....</button>
                                                                                    </div>
                                                                                </div>
                                                                                <br/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="sao-list" v-if="data.status == 0 || data.status == 1">
                                                                    <div class="saotg">
                                                                        <span class="saotg" @click="showModal('modal-uplamp-' + index)">Unggah Lampiran</span>
                                                                    </div>
                                                                    <div class="modal srp-modal" :id="'modal-uplamp-' + index">
                                                                        <div class="srp-modal-head">
                                                                            <div class="smh-close" @click="hideModal('modal-uplamp-' + index)">
                                                                                <span>x</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="srp-modal-body">
                                                                            <div class="smb-form" id="form-uplamp-kpi">
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-label">
                                                                                        <span>Lampiran</span>
                                                                                    </div>
                                                                                    <div class="sfi-input" :id="'flp-uplamp-' + index">
                                                                                        <div>
                                                                                            <input type="file" accept=".png, .jpg, .jpeg, .webp"/>
                                                                                            <small><i>maksimal ukuran file adalah 5 Mb</i></small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="sfi-input-more">
                                                                                        <span @click="inputLampiran(index)">+ tambah lampiran</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-button" v-if="!loader">
                                                                                        <button @click="uplampKpi(data, index)">Upload</button>
                                                                                    </div>
                                                                                    <div class="sfi-button" v-else>
                                                                                        <button>Loading.....</button>
                                                                                    </div>
                                                                                </div>
                                                                                <br/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="sao-list" v-if="data.status == 0 || data.status == 1">
                                                                    <template v-if="data.status == 0">
                                                                        <div class="saotg">
                                                                            <span class="saotg" @click="showModal('modal-startjob-' + index)">Mulai Kerjakan</span>
                                                                        </div>
                                                                        <div class="modal srp-modal" :id="'modal-startjob-' + index">
                                                                            <div class="srp-modal-head">
                                                                                <div class="smh-close" @click="hideModal('modal-startjob-' + index)">
                                                                                    <span>x</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="srp-modal-body">
                                                                                <div class="smb-form" id="form-startjob-kpi">
                                                                                    <div class="srp-modal-question">
                                                                                        <div class="smq-inner">
                                                                                            <div class="smq-text">
                                                                                                <p>Mulai kerjakan?</p>
                                                                                            </div>
                                                                                            <div class="smq-button">
                                                                                                <div class="smq-button-part sbp-yes">
                                                                                                    <button @click="startJob(data);" v-if="!loader">Ya</button>
                                                                                                    <button v-else>Loading.....</button>
                                                                                                </div>
                                                                                                <div class="smq-button-part sbp-no">
                                                                                                    <button @click="hideModal('modal-startjob-' + index);">Batal</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                    <template v-if="data.status == 1">
                                                                        <div class="saotg">
                                                                            <span class="saotg" @click="showModal('modal-endjob-' + index)">Selesaikan Tugas</span>
                                                                        </div>
                                                                        <div class="modal srp-modal" :id="'modal-endjob-' + index">
                                                                            <div class="srp-modal-head">
                                                                                <div class="smh-close" @click="hideModal('modal-endjob-' + index)">
                                                                                    <span>x</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="srp-modal-body">
                                                                                <div class="smb-form" id="form-endjob-kpi">
                                                                                    <div class="srp-modal-question">
                                                                                        <div class="smq-inner">
                                                                                            <div class="smq-text">
                                                                                                <p>Sudah selesai?</p>
                                                                                            </div>
                                                                                            <div class="smq-button">
                                                                                                <div class="smq-button-part sbp-yes">
                                                                                                    <button @click="endJob(data);" v-if="!loader">Ya</button>
                                                                                                    <button v-else>Loading.....</button>
                                                                                                </div>
                                                                                                <div class="smq-button-part sbp-no">
                                                                                                    <button @click="hideModal('modal-endjob-' + index);">Batal</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                                <!-- <div class="sao-list" v-if="data.status == 0 || data.status == 1">
                                                                    <div class="saotg">
                                                                        <span class="saotg" @click="showModal('modal-delete-' + index)">Hapus</span>
                                                                    </div>
                                                                    <div class="modal srp-modal" :id="'modal-delete-' + index">
                                                                        <div class="srp-modal-head">
                                                                            <div class="smh-close" @click="hideModal('modal-delete-' + index)">
                                                                                <span>x</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="srp-modal-body">
                                                                            <div class="smb-form" id="form-startjob-kpi">
                                                                                <div class="srp-modal-question">
                                                                                    <div class="smq-inner">
                                                                                        <div class="smq-text">
                                                                                            <p>Hapus KPI?</p>
                                                                                        </div>
                                                                                        <div class="smq-button">
                                                                                            <div class="smq-button-part sbp-yes">
                                                                                                <button @click="deleteJob(data);" v-if="!loader">Ya</button>
                                                                                                <button v-else>Loading.....</button>
                                                                                            </div>
                                                                                            <div class="smq-button-part sbp-no">
                                                                                                <button @click="hideModal('modal-delete-' + index);">Batal</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sc-pagination">
                                        <div class="sc-pagination-inner">
                                            <template v-for="link in allData?.meta?.links" :key="link">
                                                <div class="spi-part" @click="useKpiStore().dataByPage(link.label);" v-if="link.label != allData?.meta?.current_page">
                                                    <span v-if="link.url">{{ filterLinkLabel(link.label) }}</span>
                                                </div>
                                                <div class="spi-part-current" v-else>
                                                    <span v-if="link.url">{{ filterLinkLabel(link.label) }}</span>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="sbp-content" v-else>
                                    <div class="sbp-content-note">
                                        <p>Tidak ada KPI</p>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="sbp-content-note">
                                    <p>Loading</p>
                                </div>
                            </template>
                        </div>
                        <div class="sbt-body-part" id="sbp-transfer">
                            <div class="sbp-filter">
                                <h2>Filter</h2>
                                <div class="sbp-filter-board">
                                    <div class="sfb-part sfb-part-from">
                                        <div class="ss-select" id="reports-select-year">
                                            <div class="ss-select-button trigger" @click="selectToggle('reports-select-year');">
                                                <div class="ssb-text trigger">
                                                    <span class="trigger" v-if="kpiReports?.filter?.selected?.year">Tahun {{ kpiReports?.filter?.selected?.year }}</span>
                                                    <span class="trigger" v-else>Pilih Tahun</span>
                                                </div>
                                                <div class="ssb-icon trigger option-icon">
                                                    <img class="trigger" src="../../assets/images/icons/arrow-1.png" alt="melinda care"/>
                                                </div>
                                            </div>
                                            <div class="ss-select-option option trigger">
                                                <div class="sso-list trigger" id="sso-list-specialist">
                                                    <div class="sso-list-part" v-for="year in kpiReports?.filter?.years" :key="year" @click="useKpiStore().selectYear(year); selectToggle('reports-select-year');">
                                                        <span>{{ year }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sfb-part sfb-part-to">
                                        <div class="ss-select" id="reports-select-month">
                                            <div class="ss-select-button trigger" @click="selectToggle('reports-select-month');">
                                                <div class="ssb-text trigger">
                                                    <span class="trigger" v-if="kpiReports?.filter?.selected?.month?.name">Bulan {{ kpiReports?.filter?.selected?.month?.name }}</span>
                                                    <span class="trigger" v-else>Pilih Bulan</span>
                                                </div>
                                                <div class="ssb-icon trigger option-icon">
                                                    <img class="trigger" src="../../assets/images/icons/arrow-1.png" alt="melinda care"/>
                                                </div>
                                            </div>
                                            <div class="ss-select-option option trigger">
                                                <div class="sso-list trigger" id="sso-list-specialist">
                                                    <div class="sso-list-part" v-for="month in kpiReports?.filter?.months" :key="month" @click="useKpiStore().selectMonth(month); selectToggle('reports-select-month');">
                                                        <span>{{ month.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <input type="date" id="filter-to-jadwal" filter="to" @change="inputJadwalFilter"/> -->
                                    </div>
                                    <div class="sfb-part sfb-part-button">
                                        <div class="spb-inner">
                                            <div class="spb-reset">
                                                <button @click="useKpiStore().resetFilter();">Reset</button>
                                            </div>
                                            <div class="spb-filter">
                                                <button @click="useKpiStore().filterKpiReport()" v-if="!loader">Filter</button>
                                                <button v-else>Memfilter...</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbp-content" v-if="kpiReports?.data">
                                <div class="report-table">
                                    <div class="report-table-head">
                                        <div class="rt-leader">
                                            <div class="rt-leader-part">
                                                <span>Jenis Pekerjaan</span>
                                            </div>
                                        </div>
                                        <div class="rt-content">
                                            <div class="rt-content-row">
                                                <div class="rt-content-part">
                                                    <span>KPI</span>
                                                </div>
                                                <div class="rt-content-part rcp-small">
                                                    <span>Bobot</span>
                                                </div>
                                                <div class="rt-content-part rcp-small">
                                                    <span>Target</span>
                                                </div>
                                                <div class="rt-content-part rcp-small">
                                                    <span>Capaian</span>
                                                </div>
                                                <div class="rt-content-part rcp-small">
                                                    <span>Skor</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="report-table-body">
                                        <div class="rtb-row" v-for="(value, key) in kpiReports.data" :key="key">
                                            <div class="rt-leader">
                                                <div class="rt-leader-part">
                                                    <span>{{ key }}</span>
                                                </div>
                                            </div>
                                            <div class="rt-content">
                                                <div class="rt-content-row" v-for="vl in value" :key="vl">
                                                    <div class="rt-content-part">
                                                        <span>{{ vl.kpi_name }}</span>
                                                    </div>
                                                    <div class="rt-content-part rcp-small">
                                                        <span>{{ vl.quality }}</span>
                                                    </div>
                                                    <div class="rt-content-part rcp-small">
                                                        <span>{{ vl.target }}</span>
                                                    </div>
                                                    <div class="rt-content-part rcp-small">
                                                        <span>{{ vl.accomplish }}</span>
                                                    </div>
                                                    <div class="rt-content-part rcp-small">
                                                        <span>{{ vl.score }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbp-content" v-else>
                                <div class="sbp-content-note">
                                    <p v-if="!loader">Belum ada Laporan</p>
                                    <p v-else>Loading...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useKpiStore } from '@/stores/kpi';
    import { showModal, hideModal, removeHTML, wordsLimit, 
        optionToggle, 
        selectOption,
        selectToggle
     } from '@/functions/general';
    import { showTab, actionToggle, selectFriend, newKpi, updateKpi, inputLampiran, uplampKpi, startJob, endJob, clearInputSf, inputSf, kpiFilterSelectDateFrom, kpiFilterSelectDateTo, filterLinkLabel } from '@/functions/kpi';
    const allData = computed(() => useKpiStore().allData);
    const dataFilter = computed(() => useKpiStore().dataFilter);
    const kpiOptions = computed(() => useKpiStore().kpiOptionsFiltered);
    // const transfer = computed(() => useKpiStore().transferList);
    // const request = computed(() => useKpiStore().transferRequests);
    const loader = computed(() => useKpiStore().loader);
    const filterLoader = computed(() => useKpiStore().filterLoader);
    const kpiReports = computed(() => useKpiStore().kpiReports);
    useKpiStore().setData();
    const date = new Date();
    useKpiStore().getKpiReport(date.getFullYear(), date.getMonth() + 1);
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    @import url('../../assets/css/selectoption.css');
    @import url('../../assets/css/report_table.css');
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        transition: 0.2s;
    }
    .si-head{
        padding: 0 0 2rem 0;
    }
    .si-head h1{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        transition: 0.2s;
    }
    .cuti-baru-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        user-select: none;
        cursor: pointer;
    }
    .si-body{
        /* overflow: auto; */
    }
    .si-body-tab{
        min-height: 500px;
        border: 1px solid var(--softgrey);
    }
    .sbt-head{
        width: 100%;
        display: flex;
        background: var(--softgrey);
        overflow: auto;
    }
    .sbt-head::-webkit-scrollbar{
        display: none;
    }
    .sbt-head-part{
        min-width: 100px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 1rem 0;
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
    .shp-active{
        color: var(--primary);
        background: var(--white);
    }
    .sbt-body-part{
        display: none;
    }
    .sbp-active{
        display: block;
    }
    /* filter starts */
    .sbp-filter{
        padding: 1rem;
        transition: 0.2s;
    }
    .sbp-filter h2{
        margin: 1rem 0 1rem 0;
        padding: 0 0.5rem 0 0.5rem;
        font-size: 1.2rem;
        transition: 0.2s;
    }
    .sbp-filter-board{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        user-select: none;
        transition: 0.2s;
    }
    .sfb-part{
        width: 25%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sfb-part h3{
        margin: 0 0 1rem 0;
        font-size: 1.1rem;
        transition: 0.2s;
    }
    .sfb-part input{
        width: 100%;
        height: 35px;
        border: 1px solid var(--softgrey);
        outline: none;
        padding: 0.5rem;
    }
    .sfb-part-button{
        padding: 0 0 0.5rem 0.5rem;
        transition: 0.2s;
    }
    .spb-inner{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: end;
    }
    .spb-inner button{
        border: none;
        color: var(--white);
        width: 100%;
        height: 35px;
        transition: 0.2s;
    }
    .spb-reset{
        width: 50%;
        padding: 0 0.25rem 0 0;
    }
    .spb-reset button{
        background: var(--grey);
    }
    .spb-reset button:hover{
        background: var(--grey-hover);
    }
    .spb-filter{
        width: 50%;
        padding: 0 0 0 0.25rem;
    }
    .spb-filter button{
        background: var(--primary);
    }
    .spb-filter button:hover{
        background: var(--primary-hover);
    }
    /* filter ends */
    .sbp-content{
        position: relative;
        min-height: 500px;
        padding: 1rem 0 0 0;
        /* overflow: auto; */
    }
    .sbp-content-note{
        text-align: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .sbi-table{
        width: 100%;
        display: flex;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
        padding: 0 0 10rem 0;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 75px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-report{
        height: 200px;
    }
    .stb-row-0{
        background: var(--smoke);
    }
    .sts-row-part{
        min-width: 75px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        overflow: auto;
        padding: 0.5rem;
        /* border: 1px solid green; */
    }
    .srp-option{
        overflow: unset;
    }
    .srp-0{
        background: var(--smoke);
    }
    .srp-accordion{
        position: relative;
        width: 100%;
        height: 37px;
        user-select: none;
    }
    .srp-accordion-button{
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .sab-icon img{
        transition: 0.2s;
    }
    .arrow-active{
        transform: rotate(180deg);
    }
    .srp-accordion-options{
        position: absolute;
        top: 100%;
        left: 5%;
        width: 90%;
        background: var(--white);
        z-index: 5;
        display: none;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .sao-active{
        display: block;
    }
    .sao-list{
        padding: 0.5rem;
    }
    .srp-button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button{
        display: none;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 0 0;
    }
    .lembur-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--grey);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button-active{
        display: flex;
    }
    .srp-modal{
        padding: 5rem 25% 1rem 25%;
        overflow: auto;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    .srp-modal-question{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
	.smq-text{
		text-align: center;
	}
	.smq-button{
		display: flex;
		justify-content: center;
	}
	.smq-button-part{
		width: 50%;
		padding: 1rem;
	}
	.smq-button button{
		border: none;
		min-width: 100px;
		padding: 0.5rem 1rem 0.5rem 1rem;
		transition: 0.2s;
		color: var(--white);
	}
	.sbp-yes button{
		background: var(--primary);
	}
	.sbp-yes button:hover{
		background: var(--primary-hover);
	}
	.sbp-no button{
		background: var(--red);
	}
	.sbp-no button:hover{
		background: var(--red-hover);
	}
    .sc-pagination{
        position: absolute;
        bottom: 100px;
        left: 1rem;
        right: 1rem;
        width: 100%;
        display: flex;
        /* justify-content: right; */
        font-weight: 700;
        /* border: 1px solid green; */
        padding: 1rem 0 1rem 0;
        z-index: 1;
        user-select: none;
    }
    .sc-pagination-inner{
        width: 100%;
        display: flex;
        justify-content: left;
    }
    .spi-part{
        /* cursor: pointer; */
        /* border: 1px solid green; */
    }
    .spi-part span{
        padding: 0.5rem;
        color: var(--primary);
        cursor: pointer;
    }
    .spi-part span:hover{
        color: var(--primary-hover);
    }
    .spi-part-current span{
        padding: 0.5rem;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .sbp-filter-board{
            width: 100%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        /* filter starts */
        .sbp-filter{
            padding: 1rem 2.5% 1rem 2.5%;
        }
        .sbp-filter h2{
            padding: 0;
            font-size: 1.1rem;
        }
        .sfb-part{
            width: 50%;
        }
        .sfb-part h3{
            font-size: 0.9rem;
        }
        .sfb-part-from{
            padding: 0 0.25rem 0.5rem 0;
        }
        .sfb-part-to{
            padding: 0 0 0.5rem 0.25rem;
        }
        .sfb-part-button{
            width: 100%;
            padding: 0 0 0.5rem 0;
        }
        /* filter ends */
        .si-head h1{
            font-size: 1.5rem;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 0 0 0 0;
        }
        .srp-modal-body{
            height: 100%;
        }
    }
</style>