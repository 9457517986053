import { usePresensiStore } from "@/stores/presensi";
export function inputLampiran(index){
    const inform = document.getElementById('flp-attachments-' + index);
    const el = document.createElement('div');
    el.classList.add('element-relative');
    el.style.cssText = 'width: 100%;';
    el.innerHTML = '<input type="file"/><div class="sfi-input-remove" style="position: absolute; top: 0; right: 0; width: 50px; height: 100%; cursor: pointer; display: flex; justify-content: right; align-items: center; padding: 0.5rem; transition: 0.2s;">x</div>';
    el.children[1].addEventListener('click', function(e){
        e.target.parentElement.remove();
    });
    inform.appendChild(el);
}
export async function submitLembur(index){
    const fd = new FormData();
    const id = document.getElementById('flp-id-' + index);
    const endtime = document.getElementById('flp-endtime-' + index);
    const description = document.getElementById('flp-description-' + index);
    const attachments = document.getElementById('flp-attachments-' + index);
    for(var i = 0; i < attachments.children.length; i++){
        if(attachments.children[i].children[0].value){
            const [files] = await attachments.children[i].children[0].files;
            fd.append(`attachments[${i}][file]`, files);
        }
    }
    fd.append('schedule_id', id.value);
    fd.append('time_end', endtime.value);
    fd.append('description', description.value);
    usePresensiStore().submitLembur(fd);
}














