<template>
    <div class="section-inner">
        <div class="si-body" v-if="account">
            <div class="sb-row">
                <div class="si-part">
                    <div class="si-part-inner">
                        <div class="spi-count">
                            <span>5/23</span>
                        </div>
                        <div class="spi-text">
                            <span>Telat Masuk</span>
                        </div>
                    </div>
                </div>
                <div class="si-part">
                    <div class="si-part-inner">
                        <div class="spi-count">
                            <span>-9:23</span>
                        </div>
                        <div class="spi-text">
                            <span>Lebih/Kurang</span>
                        </div>
                    </div>
                </div>
                <div class="si-part">
                    <div class="si-part-inner">
                        <div class="spi-count">
                            <span>1</span>
                        </div>
                        <div class="spi-text">
                            <span>Total Cuti</span>
                        </div>
                    </div>
                </div>
                <div class="si-part">
                    <div class="si-part-inner">
                        <div class="spi-count">
                            <span>21/23</span>
                        </div>
                        <div class="spi-text">
                            <span>Hari Kerja</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sb-row sb-row-wide">
                <div class="si-part si-part-wide">
                    <div class="si-part-inner">
                        <div class="spi-count">
                            <span>Rp.5.000.000</span>
                        </div>
                        <div class="spi-text">
                            <span>Pinjaman Pegawai</span>
                        </div>
                    </div>
                </div>
                <div class="si-part si-part-wide">
                    <div class="si-part-inner">
                        <div class="spi-count">
                            <span>Rp.1.500.000</span>
                        </div>
                        <div class="spi-text">
                            <span>Lemburan</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CutiStatsSkeleton v-else/>
    </div>
</template>

<script setup>
    import CutiStatsSkeleton from '@/components/skeletons/sections/CutiStatsSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useAccountStore } from '@/stores/account';
    const account = computed(() => useAccountStore().account);
    useAccountStore().getProfile();
</script>

<style scoped>
    .si-body{
        width: 100%;
        /* display: flex;
        flex-wrap: wrap;
        justify-content: center; */
    }
    .sb-row{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .sb-row-wide{
        padding: 1rem 0 0 0;
    }
    .si-part{
        width: 25%;
        height: 150px;
        text-align: center;
        padding: 0 0.5rem 0 0.5rem;
        transition: 0.2s;
    }
    .si-part-wide{
        width: 50%;
    }
    .si-part-inner{
        background: var(--primary);
        color: var(--white);
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
    .spi-count{
        height: 50%;
        display: flex;
        align-items: end;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .spi-text{
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media only screen and (max-width: 750px){
        .si-part{
            width: 50%;
            padding: 0.5rem;
        }
    }
    @media only screen and (max-width: 370px){
        .si-part{
            width: 100%;
        }
    }
</style>