import axios from "axios";
const axiosFormData = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API + 'api/',
  headers: { "Content-Type": "multipart/form-data", Accept: "application/json" }
});

axiosFormData.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem("authToken")}`;
  return config;
});

export default axiosFormData;