<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <h1>Jadwal Kerja</h1>
            </div>
            <div class="si-body" v-if="jadwal">
                <div class="si-body-tab">
                    <div class="sbt-head">
                        <div class="sbt-head-part shp-active" id="shp-all" @click="showTab({button: 'sbt-head-part', content: 'sbt-body-part'}, {button: 'shp-all', content: 'sbp-all'});">
                            <span>Jadwal Saya</span>
                        </div>
                        <div class="sbt-head-part" id="shp-transfer" @click="showTab({button: 'sbt-head-part', content: 'sbt-body-part'}, {button: 'shp-transfer', content: 'sbp-transfer'});">
                            <span>Transfer</span>
                        </div>
                        <div class="sbt-head-part" id="shp-request" @click="showTab({button: 'sbt-head-part', content: 'sbt-body-part'}, {button: 'shp-request', content: 'sbp-request'});">
                            <span>Konfirmasi</span>
                        </div>
                    </div>
                    <div class="sbt-body">
                        <div class="sbt-body-part sbp-active" id="sbp-all">
                            <div class="sbp-filter">
                                <h2>Filter</h2>
                                <div class="sbp-filter-board">
                                    <div class="sfb-part sfb-part-from">
                                        <h3>Dari Tanggal</h3>
                                        <input type="date" id="filter-from-jadwal" filter="from" @change="inputJadwalFilter"/>
                                    </div>
                                    <div class="sfb-part sfb-part-to">
                                        <h3>Hingga Tanggal</h3>
                                        <input type="date" id="filter-to-jadwal" filter="to" @change="inputJadwalFilter"/>
                                    </div>
                                    <div class="sfb-part sfb-part-button">
                                        <div class="spb-inner">
                                            <div class="spb-reset" v-if="inputFilter.from || inputFilter.to">
                                                <button @click="resetJadwal">Reset</button>
                                            </div>
                                            <div class="spb-filter" v-if="inputFilter.from && inputFilter.to">
                                                <button @click="useJadwalStore().filterJadwal()">Terapkan</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbp-content" v-if="jadwal?.length > 0">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Tanggal</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(day, index) in jadwal" :key="day">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <div class="day-name">{{ day.day }}</div>
                                                    <span>{{ day.date }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Shift</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Jam Mulai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Jam Selesai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(day, index) in jadwal" :key="day">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ day.shift.name }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span v-if="day.shift?.time_start">{{ day.shift.time_start }}</span>
                                                    <span v-else>-</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span v-if="day.shift?.time_end">{{ day.shift.time_end }}</span>
                                                    <span v-else>-</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-status" v-if="day.transfers?.length < 1">
                                                        <div class="srp-transfer">
                                                            <div class="srp-button">
                                                                <span @click="showModal('modal-sx-' + index)">Transfer</span>
                                                            </div>
                                                            <div class="modal srp-modal" :id="'modal-sx-' + index">
                                                                <div class="srp-modal-head">
                                                                    <div class="smh-close" @click="hideModal('modal-sx-' + index)">
                                                                        <span>x</span>
                                                                    </div>
                                                                </div>
                                                                <div class="srp-modal-body">
                                                                    <div class="smb-form">
                                                                        <div class="smb-form-input">
                                                                            <input type="hidden" name="friend" label="Poli" class="poli-input" :id="'input-teman-' + index"/>
                                                                            <div class="sfi-label">Pilih Teman</div>
                                                                            <div class="sfi-select">
                                                                                <div class="sfi-select-button" :id="'ssb-signup-specialist' + index" @click="optionToggle('ssb-signup-specialist' + index);">
                                                                                    <div class="btn-cover float-button"></div>
                                                                                    <div class="ssb-text" :id="'ssb-text-teman' + index">
                                                                                        <span>Pilih Teman</span>
                                                                                    </div>
                                                                                    <div class="float-icon">
                                                                                        <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="sfi-select-option float" v-if="employees && account" id="sso-friend">
                                                                                    <div class="sso-notif">
                                                                                        <span v-if="employees.length > 1">ada {{ (employees.length - 1) }} teman</span>
                                                                                        <span v-else>Tidak ada teman</span>
                                                                                    </div>
                                                                                    <div class="sso-search float-button">
                                                                                        <div class="sso-search-inner float-button">
                                                                                            <div class="sso-search-input float-button">
                                                                                                <input type="text" class="input-select float-button" id="input-search-friend" @input="inputSf" placeholder="masukkan nama teman" autocomplete="off"/>
                                                                                            </div>
                                                                                            <div class="sso-search-close float-button">
                                                                                                <div class="ssc-button float-button" @click="clearInputSf">
                                                                                                    <span class="float-button">x</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="sso-button-list">
                                                                                        <template v-for="friend in friends" :key="friend.id">
                                                                                            <div class="sso-button" @click="selectOption({title: friend.name, value: friend?.id}, {button: 'ssb-signup-specialist', input: 'input-teman-' + index, title: 'ssb-text-teman' + index}); selectFriend();" v-if="friend?.id != account.id">
                                                                                                <span>{{ friend.name }}</span>
                                                                                            </div>
                                                                                        </template>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="smb-form-input">
                                                                            <div class="sfi-label">
                                                                                <span>Keterangan</span>
                                                                            </div>
                                                                            <div class="sfi-input">
                                                                                <textarea :id="'jadwal-input-notes' + index"></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div class="smb-form-input">
                                                                            <div class="sfi-button" v-if="!loader">
                                                                                <button @click="transferJadwal(day, index);">Submit</button>
                                                                            </div>
                                                                            <div class="sfi-button" v-else>
                                                                                <button>Loading.....</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="srp-lembur" v-if="day.today">
                                                            <div class="lembur-button" id="lembur-button-jadwal">
                                                                <span @click="showModal('modal-lembur-form')">Lembur</span>
                                                            </div>
                                                            <div class="modal srp-modal" id="modal-lembur-form">
                                                                <div class="srp-modal-head">
                                                                    <div class="smh-close" @click="hideModal('modal-lembur-form')">
                                                                        <span>x</span>
                                                                    </div>
                                                                </div>
                                                                <div class="srp-modal-body">
                                                                    <div class="smb-form">
                                                                        <div class="smb-form-input">
                                                                            <div class="sfi-label">
                                                                                <span>Keterangan</span>
                                                                            </div>
                                                                            <div class="sfi-input">
                                                                                <textarea :id="'jadwal-input-notes' + index"></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div class="smb-form-input">
                                                                            <div class="sfi-button" v-if="!loader">
                                                                                <button>Ajukan Lembur</button>
                                                                            </div>
                                                                            <div class="sfi-button" v-else>
                                                                                <button>Loading.....</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <span>Proses Transfer</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbp-content" v-else>
                                <div class="sbp-content-note">
                                    <p>Jadwal tidak ditemukan</p>
                                </div>
                            </div>
                        </div>
                        <div class="sbt-body-part" id="sbp-transfer">
                            <div class="sbp-content" v-if="transfer?.length > 0">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Tanggal</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(day, index) in transfer" :key="day">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <span>{{ day.schedule.workday.date }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Tujuan</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Shift</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Jam Mulai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Jam Selesai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(day, index) in transfer" :key="day">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ day.employe_target.name }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ day.shift.name }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span v-if="day.shift?.time_start">{{ day.shift.time_start }}</span>
                                                    <span v-else>-</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span v-if="day.shift?.time_end">{{ day.shift.time_end }}</span>
                                                    <span v-else>-</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-status" v-if="day.status == 0">
                                                        <div class="srp-button">
                                                            <span @click="showModal('modal-sxl-' + index)">Batalkan</span>
                                                        </div>
                                                        <div class="modal srp-modal" :id="'modal-sxl-' + index">
                                                            <div class="srp-modal-head">
                                                                <div class="smh-close" @click="hideModal('modal-sxl-' + index)">
                                                                    <span>x</span>
                                                                </div>
                                                            </div>
                                                            <div class="srp-modal-body">
                                                                <div class="smb-form">
                                                                    <p>Batalkan ?</p>
                                                                    <div class="smb-form-input">
                                                                        <div class="sfi-button" v-if="!loader">
                                                                            <button @click="useJadwalStore().cancelTransferSchedule(day.id);">Ya</button>
                                                                        </div>
                                                                        <div class="sfi-button" v-else>
                                                                            <button>Loading.....</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <span v-if="day.status == 1">Diterima</span>
                                                        <span v-else>Ditolak</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbp-content" v-else>
                                <div class="sbp-content-note">
                                    <p>Tidak ada transfer jadwal</p>
                                </div>
                            </div>
                        </div>
                        <div class="sbt-body-part" id="sbp-request">
                            <div class="sbp-content" v-if="request?.length > 0">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Tanggal</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(day, index) in request" :key="day">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <span>{{ day.schedule.workday.date }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Dari</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Shift</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Jam Mulai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Jam Selesai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(day, index) in request" :key="day">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ day.employe_request.name }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ day.shift.name }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span v-if="day.shift?.time_start">{{ day.shift.time_start }}</span>
                                                    <span v-else>-</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span v-if="day.shift?.time_end">{{ day.shift.time_end }}</span>
                                                    <span v-else>-</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-status" v-if="day.status == 0">
                                                        <div class="srp-button">
                                                            <span @click="showModal('modal-sxr-' + index)">Lihat Detail</span>
                                                        </div>
                                                        <div class="modal srp-modal" :id="'modal-sxr-' + index">
                                                            <div class="srp-modal-head">
                                                                <div class="smh-close" @click="hideModal('modal-sxr-' + index)">
                                                                    <span>x</span>
                                                                </div>
                                                            </div>
                                                            <div class="srp-modal-body">
                                                                <div class="smb-form">
                                                                    <div class="smb-confirmation">
                                                                        <p>{{ day.description }}</p>
                                                                    </div>
                                                                    <div class="smb-form-input">
                                                                        <div class="sfi-button">
                                                                            <div class="sfi-button-multi" v-if="!loader">
                                                                                <div class="sbm-part sbm-part-yes">
                                                                                    <button @click="useJadwalStore().transferResponse({id: day.id, status: 1});">Terima</button>
                                                                                </div>
                                                                                <div class="sbm-part sbm-part-no">
                                                                                    <button @click="useJadwalStore().transferResponse({id: day.id, status: 2});">Tolak</button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="sfi-button-multi" v-else>
                                                                                <div class="sbm-part">
                                                                                    <p>Loading.....</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <span v-if="day.status == 1">Diterima</span>
                                                        <span v-else>Ditolak</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbp-content" v-else>
                                <div class="sbp-content-note">
                                    <p>Tidak ada konfirmasi</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <JadwalKerjaSkeleton v-else/>
        </div>
    </section>
</template>

<script setup>
    import JadwalKerjaSkeleton from '@/components/skeletons/sections/JadwalKerjaSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useJadwalStore } from '@/stores/jadwal';
    import { useAccountStore } from '@/stores/account';
    import { showModal, hideModal, optionToggle, selectOption } from '@/functions/general';
    import { transferJadwal, showTab, clearInputSf, inputSf, selectFriend } from '@/functions/jadwal';
    const jadwal = computed(() => useJadwalStore().jadwalShow);
    const inputFilter = computed(() => useJadwalStore().inputFilter);
    const employees = computed(() => useJadwalStore().employees);
    const friends = computed(() => useJadwalStore().friends);
    const transfer = computed(() => useJadwalStore().transferList);
    const request = computed(() => useJadwalStore().transferRequests);
    const loader = computed(() => useJadwalStore().loader);
    const account = computed(() => useAccountStore().account);
    useJadwalStore().setJadwal();
    function inputJadwalFilter(e){
        useJadwalStore().inputJadwalFilter(e);
    }
    function resetJadwal(){
        document.getElementById('filter-from-jadwal').value = null;
        document.getElementById('filter-to-jadwal').value = null;
        useJadwalStore().resetJadwal();
    }
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        transition: 0.2s;
    }
    .si-head h1{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        transition: 0.2s;
    }
    .si-body-tab{
        border: 1px solid var(--softgrey);
    }
    .sbt-head{
        width: 100%;
        display: flex;
        background: var(--softgrey);
        overflow: auto;
    }
    .sbt-head::-webkit-scrollbar{
        display: none;
    }
    .sbt-head-part{
        min-width: 100px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 1rem 0;
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
    .shp-active{
        color: var(--primary);
        background: var(--white);
    }
    .sbt-body-part{
        display: none;
    }
    .sbp-active{
        display: block;
    }
    /* filter starts */
    .sbp-filter{
        padding: 1rem;
        transition: 0.2s;
    }
    .sbp-filter h2{
        margin: 1rem 0 1rem 0;
        padding: 0 0.5rem 0 0.5rem;
        font-size: 1.2rem;
        transition: 0.2s;
    }
    .sbp-filter-board{
        width: 75%;
        display: flex;
        flex-wrap: wrap;
        user-select: none;
        transition: 0.2s;
    }
    .sfb-part{
        width: 33.33%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sfb-part h3{
        margin: 0 0 1rem 0;
        font-size: 1.1rem;
        transition: 0.2s;
    }
    .sfb-part input{
        width: 100%;
        height: 35px;
        border: 1px solid var(--softgrey);
        outline: none;
        padding: 0.5rem;
    }
    .sfb-part-button{
        padding: 0 0 0.5rem 0.5rem;
        transition: 0.2s;
    }
    .spb-inner{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: end;
    }
    .spb-inner button{
        border: none;
        color: var(--white);
        width: 100%;
        height: 35px;
        transition: 0.2s;
    }
    .spb-reset{
        width: 50%;
        padding: 0 0.25rem 0 0;
    }
    .spb-reset button{
        background: var(--grey);
    }
    .spb-reset button:hover{
        background: var(--grey-hover);
    }
    .spb-filter{
        width: 50%;
        padding: 0 0 0 0.25rem;
    }
    .spb-filter button{
        background: var(--primary);
    }
    .spb-filter button:hover{
        background: var(--primary-hover);
    }
    /* filter ends */
    .sbp-content{
        padding: 1rem 0 0 0;
    }
    .sbp-content-note{
        text-align: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .sbi-table{
        width: 100%;
        display: flex;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 75px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-0{
        background: var(--smoke);
    }
    .sts-row-part{
        min-width: 75px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .srp-0{
        background: var(--smoke);
    }
    .srp-button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button{
        display: none;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 0 0;
    }
    .lembur-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--grey);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button-active{
        display: flex;
    }
    .srp-modal{
        padding: 1rem 25% 1rem 25%;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .sbp-filter-board{
            width: 100%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        /* filter starts */
        .sbp-filter{
            padding: 1rem 2.5% 1rem 2.5%;
        }
        .sbp-filter h2{
            padding: 0;
            font-size: 1.1rem;
        }
        .sfb-part{
            width: 50%;
        }
        .sfb-part h3{
            font-size: 0.9rem;
        }
        .sfb-part-from{
            padding: 0 0.25rem 0.5rem 0;
        }
        .sfb-part-to{
            padding: 0 0 0.5rem 0.25rem;
        }
        .sfb-part-button{
            width: 100%;
            padding: 0 0 0.5rem 0;
        }
        /* filter ends */
        .si-head h1{
            font-size: 1.5rem;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 0;
        }
        .srp-modal-body{
            height: 100%;
        }
    }
    /*  */
</style>