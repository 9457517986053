<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <!-- <h1>Dokumen</h1> -->
            </div>
            <div class="si-body" v-if="documents">
                <div class="si-body-tab">
                    <div class="sbt-body">
                        <div class="sbt-body-part sbp-active" id="sbp-all">
                            <div class="sbp-content" v-if="documents?.length > 0">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Judul</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(document, index) in documents" :key="document">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <span>{{ document.document.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Divisi</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Kategori</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Tanggal</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Status</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(document, index) in documents" :key="document">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ document.org_doc.name }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ document.document.category.name }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ document.time_created_id }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ document.status_alias }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-accordion">
                                                        <div class="srp-accordion-button" :id="'sab-documents-action-' + index" @click="actionToggle('sab-documents-action-' + index)">
                                                            <div class="sab-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="arrow"/>
                                                            </div>
                                                        </div>
                                                        <div class="srp-accordion-options">
                                                            <template v-if="document.status == 2">
                                                                <div class="sao-list">
                                                                    <router-link :to="'/dokumen/detail/' + document.document.slug">Lihat Detail</router-link>
                                                                </div>
                                                                <div class="sao-list">
                                                                    <a :href="dlsrc + document.document.slug + '?_token=' + getCookie('session')" download="file.pdf">Download</a>
                                                                </div>
                                                                <!-- <div class="sao-list">
                                                                    <span @click="useRequestStore().downloadFile(document.slug)">Download</span>
                                                                </div> -->
                                                                <!-- <div class="sao-list">
                                                                    <a href="http://localhost:8080/goto_prospektus.pdf" download>Download</a>
                                                                </div> -->
                                                            </template>
                                                            <template v-else>
                                                                <div class="sao-list">
                                                                    <div class="">
                                                                        <span @click="showModal('modal-sxlbr-' + index)">Edit Request</span>
                                                                    </div>
                                                                    <div class="modal srp-modal" :id="'modal-sxlbr-' + index">
                                                                        <div class="srp-modal-head">
                                                                            <div class="smh-close" @click="hideModal('modal-sxlbr-' + index)">
                                                                                <span>x</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="srp-modal-body">
                                                                            <div class="smb-form" id="form-lembur-presensi">
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-label">
                                                                                        <span>Keterangan</span>
                                                                                    </div>
                                                                                    <div class="sfi-input">
                                                                                        <textarea :id="'flp-description-' + index" :value="document.desc_req"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="smb-form-input">
                                                                                    <div class="sfi-button" v-if="!loader">
                                                                                        <button @click="updateRequestAccess(document, 'flp-description-' + index)">Update Request</button>
                                                                                    </div>
                                                                                    <div class="sfi-button" v-else>
                                                                                        <button>Loading.....</button>
                                                                                    </div>
                                                                                </div>
                                                                                <br/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbp-content" v-else>
                                <div class="sbp-content-note">
                                    <p>Belum ada Permintaan Akses</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DocumentsSkeleton v-else/>
        </div>
    </section>
</template>

<script setup>
    import DocumentsSkeleton from '@/components/skeletons/sections/DocumentsSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useRequestStore } from '@/stores/request';
    import { actionToggle, updateRequestAccess } from '@/functions/request';
    import { showModal, hideModal, getCookie } from '@/functions/general';
    const documents = computed(() => useRequestStore().documents);
    const loader = computed(() => useRequestStore().loader);
    const dlsrc = process.env.VUE_APP_ROOT_API + 'dms/doc/download/';
    useRequestStore().setDocuments();
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    section{
        padding: 0 10% 2.5rem 10%;
        transition: 0.2s;
        word-break: break-all;
    }
    .si-head h1{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        transition: 0.2s;
    }
    .si-body-tab{
        border: 1px solid var(--softgrey);
    }
    .sbt-head{
        width: 100%;
        display: flex;
        background: var(--softgrey);
        overflow: auto;
    }
    .sbt-head::-webkit-scrollbar{
        display: none;
    }
    .sbt-head-part{
        min-width: 100px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 1rem 0;
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
    .shp-active{
        color: var(--primary);
        background: var(--white);
    }
    .sbt-body-part{
        display: none;
    }
    .sbp-active{
        display: block;
    }
    .sbp-content{
        padding: 1rem 0 0 0;
    }
    .sbp-content-note{
        text-align: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .sbi-table{
        width: 100%;
        display: flex;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
        padding: 0 0 10rem 0;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 100px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-0{
        background: var(--smoke);
    }
    .sts-row-part{
        min-width: 100px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .srp-0{
        background: var(--smoke);
    }
    .srp-accordion{
        position: relative;
        width: 100%;
        height: 37px;
        user-select: none;
    }
    .srp-accordion-button{
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .sab-icon img{
        transition: 0.2s;
    }
    .srp-accordion-options{
        position: absolute;
        top: 100%;
        left: 5%;
        width: 90%;
        background: var(--white);
        z-index: 1;
        display: none;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .sao-active{
        display: block;
    }
    .sao-list{
        padding: 0.5rem;
    }
    .srp-button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 0 0;
    }
    .lembur-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--grey);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .srp-modal{
        padding: 100px 25% 1rem 25%;
    }
    .srp-modal-head{
        position: absolute;
        top: 75px;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        max-height: 90%;
        overflow: auto;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 0 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 0 2.5% 2.5rem 2.5%;
        }
        .si-head h1{
            font-size: 1.5rem;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 75px 0 0 0;
        }
        .srp-modal-body{
            height: 100%;
            max-height: unset;
        }
    }
</style>