import { defineStore } from "pinia";
// import { jadwal } from "@/data/dummies.js";
import { useAccountStore } from "./account";
import { getCookie, showNotification, hideModals, leftPadNum, getDay } from "@/functions/general";
import axiosClient from "../axios.js";
import { showLemburButton } from "@/functions/jadwal";

export const useJadwalStore = defineStore("jadwal", {
    state: () => ({
        // ==================== states used on jadwal kerja page and its relation
        rawSchedule: null,
        jadwal: null,
        jadwalShow: null,
        jadwalYesterday: null,
        jadwalNearest: null,
        inputFilter: {from: null, to: null},
        employees: null,
        allFriends: null,
        friends: null,
        transferList: null,
        transferRequests: null,
        loader: false
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on jadwal kerja page and its relation
        async setJadwal(){
            await useAccountStore().getProfile();
            this.getThisMonthSchedule();
            await this.getActiveEmployees();
            await this.getFriends();
            this.getTransferList();
            this.getTransferRequest();
        },
        async getThisMonthSchedule(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const date = new Date();
                const month = (date.getMonth() + 1);
                const year = date.getFullYear();
                const response = await axiosClient.get(`shift/schedule/` + month + '/' + year);
                this.rawSchedule = await response.data.data;
                this.setSchedule();
                this.initLembur();
                // this.jadwal = await response.data.data;
                // this.jadwalShow = await response.data.data;
                console.log(this.rawSchedule);
            }catch(error){
                console.log(error);
            }
        },
        setSchedule(){
            const date = new Date();
            const yttime = new Date(date.getTime() - 86400000);
            const yesterday = new Date(yttime).toISOString().split('T')[0];
            // console.log(yesterday);
            var scdata = [];
            var nearestsc = [];
            for(var i = 0; i < this.rawSchedule.length; i++){
                this.rawSchedule[i].day = getDay(new Date(this.rawSchedule[i].date).getDay());
                if(!this.rawSchedule[i].is_holiday && !this.rawSchedule[i].is_leave){
                    scdata.push(this.rawSchedule[i]);
                    if(this.rawSchedule[i].date == date.toISOString().split('T')[0] || this.rawSchedule[i].date == yesterday){
                        nearestsc.push(this.rawSchedule[i]);
                    }
                    console.log(this.rawSchedule[i]);
                }
            }
            this.jadwal = scdata;
            this.jadwalShow = scdata;
            this.jadwalNearest = nearestsc;
            // console.log(this.jadwalShow);
        },
        async getActiveEmployees(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`employe/active`);
                this.employees = response.data.data;
            }catch(error){
                console.log(error);
            }
        },
        async getFriends(){
            try{
                var frdata = [];
                for(var i = 0; i < this.employees.length; i++){
                    if(this.employees[i].id != useAccountStore().account?.id){
                        frdata.push(this.employees[i]);
                    }
                    // console.log(this.employees[i]);
                }
                this.allFriends = frdata;
                this.friends = frdata;
                // console.log(this.friends);
            }catch(error){
                console.log(error);
            }
        },
        async transferSchedule(params){
            this.loader = true;
            try{
                const response = await axiosClient.post(`shift/transfer`, params);
                if(response.status == 201){
                    this.getThisMonthSchedule();
                    this.getTransferList();
                    showNotification('', 'Permintaan transfer jadwal dikirim', 2000, null);
                    hideModals();
                }
                this.loader = false;
            }catch(error){
                if(error.response){
                    showNotification('', 'Error : ' + error.response.status + ', Message : ' + error.response.data.message, 2000, 'var(--red)');
                }
                this.loader = false;
                console.log(error);
            }
        },
        async cancelTransferSchedule(id){
            this.loader = true;
            try{
                const response = await axiosClient.delete(`shift/transfer/pull/` + id);
                if(response.status == 200){
                    this.getThisMonthSchedule();
                    this.getTransferList();
                    showNotification('', response.data.message, 2000, null);
                    hideModals();
                }
                this.loader = false;
            }catch(error){
                if(error.response){
                    showNotification('', 'Error : ' + error.response.status + ', Message : ' + error.response.data.message, 2000, 'var(--red)');
                }
                this.loader = false;
                console.log(error);
            }
        },
        async getTransferList(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`shift/transfer`);
                this.transferList = response.data.data;
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async getTransferRequest(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`shift/transfer/request`);
                this.transferRequests = response.data.data;
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async transferResponse(params){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.post(`shift/transfer/response`, params);
                if(response.data.success){
                    await this.getTransferRequest();
                    showNotification('', 'Konfirmasi berhasil', 2000, null);
                }
                this.loader = false;
            }catch(error){
                this.loader = false;
                console.log(error);
            }
        },
        inputJadwalFilter(e){
            if(e.srcElement.getAttribute('filter') == 'from'){
                this.inputFilter.from = e.srcElement.value;
            }else{
                this.inputFilter.to = e.srcElement.value;
            }
        },
        filterJadwal(){
            if(this.inputFilter.from && this.inputFilter.to){
                const fdate = new Date(this.inputFilter.from);
                const tdate = new Date(this.inputFilter.to);
                if(tdate.getTime() >= fdate.getTime()){
                    var scfiltered = [];
                    for(var i = 0; i < this.jadwal.length; i++){
                        const date = new Date(this.jadwal[i].date);
                        if(date.getTime() >= fdate.getTime() && date.getTime() <= tdate.getTime()){
                            scfiltered.push(this.jadwal[i]);
                        }
                    }
                    this.jadwalShow = scfiltered;
                }else{
                    showNotification('', 'Tanggal Hingga harus setelah atau sama dengan Tanggal Dari', 5000, 'var(--red)');
                }
            }else{
                showNotification('', 'Tanggal Dari dan Hingga harus diisi', 5000, 'var(--red)');
            }
        },
        resetJadwal(){
            this.jadwalShow = this.jadwal;
            this.inputFilter = {from: null, to: null};
        },
        async initLembur(){
            var lbshow = 3600000; // 1 hour
            var lbdelay = null;
            const date = new Date();
            const nextdate = new Date(date.getTime() + 86400000);
            const today = date.getFullYear() + '-' + await leftPadNum(date.getMonth() + 1, 2, '0') + '-' + await leftPadNum(date.getDate(), 2, '0');
            const nextday = nextdate.getFullYear() + '-' + await leftPadNum(nextdate.getMonth() + 1, 2, '0') + '-' + await leftPadNum(nextdate.getDate(), 2, '0');
            for(var i = 0; i < this.jadwal.length; i++){
                if(this.jadwal[i].date == today){
                    this.jadwal[i].today = true;
                    const ldstart = new Date(today + ' ' + this.jadwal[i].time_start);
                    const ldend = new Date(today + ' ' + this.jadwal[i].time_end);
                    if((ldend.getTime() - ldstart.getTime()) >= 0){
                        lbdelay = (ldend.getTime() + lbshow) - date.getTime();
                        // console.log(ldend.getTime() - ldstart.getTime());
                    }else{
                        lbdelay = (nextday.getTime() + lbshow) - date.getTime();
                    }
                }else{
                    this.jadwal[i].today = false;
                }
            }
            for(i = 0; i < this.jadwalShow.length; i++){
                if(this.jadwalShow[i].date == today){
                    this.jadwalShow[i].today = true;
                }else{
                    this.jadwalShow[i].today = false;
                }
            }
            if(lbdelay > 1){
                setTimeout(function(){
                    showLemburButton();
                }, lbdelay);
            }else{
                showLemburButton();
            }
            // console.log(lbdelay);
            // console.log(this.jadwal);
            // console.log(today);
            // console.log(nextday);
        },
        searchFriend(value){
            if(value != ''){
                var result = [];
                var inval = new RegExp(value, 'gi');
                for(var i = 0; i < this.allFriends.length; i++){
                    if(this.allFriends[i].name.match(inval)){
                        result.push(this.allFriends[i]);
                    }
                }
                this.friends = result;
            }else{
                this.friends = this.allFriends;
            }
        }
    }
});