<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <h1>New Password</h1>
            </div>
            <div class="si-body">
                <div class="login-form">
                    <!-- <div class="login-form-part">
                        <div class="lfp-label">
                            <span>Email</span>
                        </div>
                        <div class="lfp-input">
                            <input type="email" name="username" id="input-login-email" autocomplete="off" placeholder="masukkan email"/>
                        </div>
                    </div> -->
                    <div class="login-form-part">
                        <div class="lfp-label">
                            <span>New Password</span>
                        </div>
                        <div class="lfp-input">
                            <input :type="resetPassword.newPassword.visibility ? 'text' : 'password'" name="password" id="input-password-baru" @input="newPasswordInput('input-password-baru');" autocomplete="off" placeholder="masukkan password baru"/>
                            <div class="lfp-input-shpass">
                                <template v-if="resetPassword.newPassword.contains">
                                    <span v-if="!resetPassword.newPassword.visibility" @click="shNewPassword">Show</span>
                                    <span v-else @click="shNewPassword">Hide</span>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="login-form-part">
                        <div class="lfp-label">
                            <span>New Password Confirmation</span>
                        </div>
                        <div class="lfp-input">
                            <input :type="resetPassword.newConfirmPassword.visibility ? 'text' : 'password'" name="password" id="input-konfirmasi-pbaru" @input="newConfirmPasswordInput('input-konfirmasi-pbaru');" autocomplete="off" placeholder="masukkan lagi password baru"/>
                            <div class="lfp-input-shpass">
                                <template v-if="resetPassword.newConfirmPassword.contains">
                                    <span v-if="!resetPassword.newConfirmPassword.visibility" @click="shNewConfirmPassword">Show</span>
                                    <span v-else @click="shNewConfirmPassword">Hide</span>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="login-form-part" v-if="!loader">
                        <button @click="login">Submit</button>
                    </div>
                    <div class="login-form-part" v-else>
                        <button>Loading.....</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useAccountStore } from '@/stores/account';
    const resetPassword = computed(() => useAccountStore().resetPassword);
    const loader = computed(() => useAccountStore().loader);
    function newPasswordInput(id){
        if(document.getElementById(id)){
            const input = document.getElementById(id);
            if(input.value){
                useAccountStore().resetPassword.newPassword.contains = true;
            }else{
                useAccountStore().resetPassword.newPassword.contains = false;
            }
        }
    }
    function newConfirmPasswordInput(id){
        if(document.getElementById(id)){
            const input = document.getElementById(id);
            if(input.value){
                useAccountStore().resetPassword.newConfirmPassword.contains = true;
            }else{
                useAccountStore().resetPassword.newConfirmPassword.contains = false;
            }
        }
    }
    function shNewPassword(){
        if(useAccountStore().resetPassword.newPassword.visibility){
            useAccountStore().resetPassword.newPassword.visibility = false;
        }else{
            useAccountStore().resetPassword.newPassword.visibility = true;
        }
    }
    function shNewConfirmPassword(){
        if(useAccountStore().resetPassword.newConfirmPassword.visibility){
            useAccountStore().resetPassword.newConfirmPassword.visibility = false;
        }else{
            useAccountStore().resetPassword.newConfirmPassword.visibility = true;
        }
    }
    function login(){
        const params = {
            username: document.getElementById('input-login-email').value, 
            password: document.getElementById('input-login-password').value
        };
        useAccountStore().login(params);
    }
</script>

<style scoped>
    section{
        padding: 2.5rem 25% 2.5rem 25%;
    }
    .section-inner{
        width: 100%;
    }
    .si-head h1{
        font-size: 2rem;
        margin: 0 0 1rem 0;
    }
    .si-body{
        display: flex;
        justify-content: center;
    }
    .login-form{
        width: 100%;
        min-height: 370px;
        border: 1px solid var(--softgrey);
        border-radius: 10px;
        padding: 1rem;
    }
    .login-form-part{
        width: 100%;
        padding: 0 0 1rem 0;
    }
    .lfp-label{
        padding: 1rem 0 1rem 0;
    }
    .lfp-input{
        width: 100%;
        height: 50px;
        position: relative;
    }
    .lfp-input input{
        width: 100%;
        height: 100%;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        outline: none;
        padding: 1% 50px 1% 1rem;
        font-size: 1.1rem;
        color: var(--grey);
    }
    .lfp-input-shpass{
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        user-select: none;
    }
    .login-form-part button{
        border: none;
        border-radius: 5px;
        background: var(--primary);
        color: var(--white);
        font-size: 1rem;
        padding: 1rem 2rem 1rem 2rem;
        min-width: 200px;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
    }
    .login-form-part button:hover{
        background: var(--primary-hover);
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
    }
    /*  */
</style>

