<template>
    <section>
        <div class="section-inner" v-if="account">
            <div class="si-head">
                <h1>Payroll</h1>
            </div>
            <div class="si-body">
                <div class="pcb-detail">
                    <div class="profile-card">
                        <div class="profile-card-head">
                            <div class="pch-image">
                                <img :src="account.avatar" alt="melinda hcs" v-if="account.avatar"/>
                                <img src="../../assets/images/pictures/emptyprofile.webp" alt="melinda hcs" v-else/>
                            </div>
                            <div class="pch-text">
                                <div class="pch-name">
                                    <h2>{{ account.name }}</h2>
                                </div>
                                <div class="pch-update">
                                    <!-- <div class="pch-update-button">
                                        <button @click="showModal('modal-profile-update');">Edit</button>
                                    </div> -->
                                    <!-- <div class="modal pch-update-modal" id="modal-profile-update">
                                        <div class="pum-head">
                                            <div class="smh-close" @click="hideModal('modal-profile-update');">
                                                <span>x</span>
                                            </div>
                                        </div>
                                        <div class="pum-body">
                                            <div class="profile-update-form">
                                                <div class="smb-form-input">
                                                    <div class="sfi-label">Foto Profil</div>
                                                    <input type="file" name="image" class="profile-update" id="input-pum-image" label="Nama Pasien" placeholder="masukkan email" autocomplete="off"/>
                                                    <div class="sfi-alert"></div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <input type="hidden" name="gender" label="Jenis Kelamin" class="profile-update" id="input-pum-gender"/>
                                                    <div class="sfi-label">Jenis Kelamin</div>
                                                    <div class="sfi-select">
                                                        <div class="sfi-select-button" id="pum-button-gender" @click="optionToggle('pum-button-gender');">
                                                            <div class="btn-cover float-button"></div>
                                                            <div class="ssb-text" id="stp-gender">
                                                                <span v-if="account.gender == 1">Laki-laki</span>
                                                                <span v-else>Perempuan</span>
                                                            </div>
                                                            <div class="float-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                            </div>
                                                        </div>
                                                        <div class="sfi-select-option float">
                                                            <div class="sso-button-list">
                                                                <div class="sso-button" @click="selectOption({value: 1, title: 'Laki-laki'}, {button: 'pum-button-gender', input: 'input-pum-gender', title: 'stp-gender'});">
                                                                    <span>Laki-laki</span>
                                                                </div>
                                                                <div class="sso-button" @click="selectOption({value: 2, title: 'Perempuan'}, {button: 'pum-button-gender', input: 'input-pum-gender', title: 'stp-gender'});">
                                                                    <span>Perempuan</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <div class="sfi-label">Email</div>
                                                    <input type="text" name="email" class="profile-update" id="input-pum-email" label="Nama Pasien" :value="account.user.email" placeholder="masukkan email" autocomplete="off"/>
                                                    <div class="sfi-alert"></div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <div class="sfi-label">Tempat Lahir</div>
                                                    <input type="text" name="birth_place" class="profile-update" id="input-pum-birthplace" label="Nama Pasien" :value="account.birthplace" placeholder="kota kelahiran" autocomplete="off"/>
                                                    <div class="sfi-alert"></div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <div class="sfi-label">Tanggal Lahir</div>
                                                    <input type="date" name="birth_date" class="profile-update" id="input-pum-birthdate" label="Nama Pasien" :value="account.birthday" placeholder="masukkan email" autocomplete="off"/>
                                                    <div class="sfi-alert"></div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <input type="hidden" name="religion" label="Poli" class="profile-update" id="input-pum-religion"/>
                                                    <div class="sfi-label">Agama</div>
                                                    <div class="sfi-select">
                                                        <div class="sfi-select-button" id="pum-button-religion" @click="optionToggle('pum-button-religion');">
                                                            <div class="btn-cover float-button"></div>
                                                            <div class="ssb-text" id="stp-religion">
                                                                <span v-if="account.religion">{{ account.religion }}</span>
                                                                <span v-else>Pilih Agama</span>
                                                            </div>
                                                            <div class="float-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                            </div>
                                                        </div>
                                                        <div class="sfi-select-option float">
                                                            <div class="sso-search float-button">
                                                                <input type="text" class="float-button" :value="searchReligion.input.value" @input="useAccountStore().inputReligionSearch"/>
                                                            </div>
                                                            <div class="sso-button-list" v-if="religions?.length > 0">
                                                                <div class="sso-button" @click="selectOption({value: religion.value, title: religion.title}, {button: 'pum-button-religion', input: 'input-pum-religion', title: 'stp-religion'});" v-for="religion in religions" :key="religion">
                                                                    <span>{{ religion.name }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="sso-button-list" v-else>
                                                                <p class="sbl-note">Tidak ditemukan agama</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <div class="sfi-label">Alamat</div>
                                                    <textarea type="text" name="address" class="profile-update" id="input-pum-address" label="Alamat" :value="account.address" placeholder="masukkan alamat" autocomplete="off"></textarea>
                                                    <div class="sfi-alert"></div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <input type="hidden" name="province" label="Poli" class="profile-update" id="input-pum-province"/>
                                                    <div class="sfi-label">Provinsi</div>
                                                    <div class="sfi-select" v-if="provinces">
                                                        <div class="sfi-select-button" id="pum-button-province" @click="optionToggle('pum-button-province');">
                                                            <div class="btn-cover float-button"></div>
                                                            <div class="ssb-text" id="stp-province">
                                                                <span v-if="account.province">{{ account.province }}</span>
                                                                <span v-else>Pilih Provinsi</span>
                                                            </div>
                                                            <div class="float-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                            </div>
                                                        </div>
                                                        <div class="sfi-select-option float">
                                                            <div class="sso-search float-button">
                                                                <input type="text" class="float-button" :value="searchProvince.input.value" @input="useAccountStore().inputProvinceSearch"/>
                                                            </div>
                                                            <div class="sso-button-list" v-if="provinces.length > 0">
                                                                <div class="sso-button" @click="selectOption({value: firstCapwords(province.name), title: firstCapwords(province.name)}, {button: 'pum-button-province', input: 'input-pum-province', title: 'stp-province'}); selectProvince(province)" v-for="province in provinces" :key="province">
                                                                    <span>{{ firstCapwords(province.name) }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="sso-button-list" v-else>
                                                                <p class="sbl-note">Tidak ditemukan provinsi</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <input type="hidden" name="city" label="Poli" class="profile-update" id="input-pum-city"/>
                                                    <div class="sfi-label">Kota</div>
                                                    <div class="sfi-select">
                                                        <div class="sfi-select-button" id="pum-button-city" @click="optionToggle('pum-button-city');">
                                                            <div class="btn-cover float-button"></div>
                                                            <div class="ssb-text" id="stp-city">
                                                                <span v-if="selectedCity">{{ firstCapwords(selectedCity) }}</span>
                                                                <span v-else>Pilih Kota</span>
                                                            </div>
                                                            <div class="float-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                            </div>
                                                        </div>
                                                        <div class="sfi-select-option float">
                                                            <div class="sso-search float-button" v-if="citiesByProvince">
                                                                <input type="text" class="float-button" :value="searchCity.input.value" @input="useAccountStore().inputCitySearch"/>
                                                            </div>
                                                            <template v-if="citiesByProvince">
                                                                <div class="sso-button-list" v-if="citiesByProvince.length > 0">
                                                                    <div class="sso-button" @click="selectOption({value: firstCapwords(city.name), title: firstCapwords(city.name)}, {button: 'pum-button-city', input: 'input-pum-city', title: 'stp-city'});" v-for="city in citiesByProvince" :key="city">
                                                                        <span>{{ firstCapwords(city.name) }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="sso-button-list" v-else>
                                                                    <p class="sbl-note">Tidak ditemukan kota/kabupaten</p>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <div class="sso-button-list">
                                                                    <div class="sso-button">
                                                                        <span>Pilih Provinsi dahulu</span>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <div class="sfi-label">Nomor Ponsel</div>
                                                    <input type="text" name="phone" class="profile-update" id="input-pum-phone" label="Nomor Ponsel" :value="account.phone" placeholder="masukkan nomor ponsel" autocomplete="off"/>
                                                    <div class="sfi-alert"></div>
                                                </div>
                                                <div class="smb-form-input">
                                                    <div class="sfi-button" v-if="!loader">
                                                        <button @click="updateProfile">Update</button>
                                                    </div>
                                                    <div class="sfi-button" v-else>
                                                        <button>Loading.....</button>
                                                    </div>
                                                </div>
                                                <br/>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="profile-card-body">
                            <div class="pcb-update-password">
                                <!-- <div class="pup-button">
                                    <button @click="showModal('modal-password-update');">Update Password</button>
                                </div> -->
                                <!-- <div class="modal pch-update-modal" id="modal-password-update">
                                    <div class="pum-head">
                                        <div class="smh-close" @click="hideModal('modal-password-update');">
                                            <span>x</span>
                                        </div>
                                    </div>
                                    <div class="pum-body">
                                        <div class="profile-update-form">
                                            <div class="smb-form-input">
                                                <div class="sfi-label">Password Lama</div>
                                                <input type="text" name="old_password" class="profile-update" id="input-oldpass-updatepass" label="Password Lama" placeholder="password lama" autocomplete="off"/>
                                                <div class="sfi-alert"></div>
                                            </div>
                                            <div class="smb-form-input">
                                                <div class="sfi-label">Password Baru</div>
                                                <input type="text" name="new_password" class="profile-update" id="input-newpass-updatepass" label="Password Baru" placeholder="password baru" autocomplete="off"/>
                                                <div class="sfi-alert"></div>
                                            </div>
                                            <div class="smb-form-input">
                                                <div class="sfi-label">Konfirmasi Password Baru</div>
                                                <input type="text" name="new_password_confirmation" class="profile-update" id="input-newpassconf-updatepass" label="Konfirmasi Password Baru" placeholder="konfirmasi password baru" autocomplete="off"/>
                                                <div class="sfi-alert"></div>
                                            </div>
                                            <div class="smb-form-input">
                                                <div class="sfi-button" v-if="!loader">
                                                    <button @click="updatePassword">Update</button>
                                                </div>
                                                <div class="sfi-button" v-else>
                                                    <button>Loading.....</button>
                                                </div>
                                            </div>
                                            <br/>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>Username</span>
                                </div>
                                <div class="pcr-value">
                                    <span>{{ account.user.username }}</span>
                                </div>
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>NIP</span>
                                </div>
                                <div class="pcr-value">
                                    <span>1234567890</span>
                                </div>
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>Jenis Kelamin</span>
                                </div>
                                <div class="pcr-value">
                                    <span v-if="account.gender == 1">Laki-laki</span>
                                    <span v-if="account.gender == 2">Perempuan</span>
                                </div>
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>Email</span>
                                </div>
                                <div class="pcr-value">
                                    <span>{{ account.user.email }}</span>
                                </div>
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>Tempat Lahir</span>
                                </div>
                                <div class="pcr-value">
                                    <span>{{ account.birthplace }}</span>
                                </div>
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>Tanggal Lahir</span>
                                </div>
                                <div class="pcr-value">
                                    <span>{{ account.birthday }}</span>
                                </div>
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>Agama</span>
                                </div>
                                <div class="pcr-value">
                                    <span>{{ account.religion }}</span>
                                </div>
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>Nomor Ponsel</span>
                                </div>
                                <div class="pcr-value">
                                    <span>{{ account.phone }}</span>
                                </div>
                            </div>
                            <div class="profile-card-row">
                                <div class="pcr-param">
                                    <span>Alamat</span>
                                </div>
                                <div class="pcr-value">
                                    <span>{{ account.address }}</span>
                                    <div>
                                        <p>{{ account.city }}</p>
                                    </div>
                                    <div>
                                        <p>{{ account.province }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-data">
                    <PayrollStatsComponent/>
                    <PayrollWorkDaysComponent/>
                    <PayrollDoughnutComponent/>
                </div>
            </div>
        </div>
        <ProfileSkeleton v-else/>
    </section>
</template>

<script setup>
    import ProfileSkeleton from '@/components/skeletons/sections/ProfileSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useAccountStore } from '@/stores/account';
    // import { showModal, hideModal, optionToggle, selectOption, firstCapwords } from '@/functions/general';
    // import { updateProfile, updatePassword } from '@/functions/account';
    import PayrollStatsComponent from '@/components/payroll/PayrollStatsComponent.vue';
    import PayrollWorkDaysComponent from '@/components/payroll/PayrollWorkDaysComponent.vue';
    import PayrollDoughnutComponent from '@/components/payroll/PayrollDoughnutComponent.vue';
    const account = computed(() => useAccountStore().account);
    // const religions = computed(() => useAccountStore().religionsShow);
    // const searchReligion = computed(() => useAccountStore().searchReligion);
    // const provinces = computed(() => useAccountStore().provincesShow);
    // const searchProvince = computed(() => useAccountStore().searchProvince);
    // const citiesByProvince = computed(() => useAccountStore().cbpShow);
    // const searchCity = computed(() => useAccountStore().searchCity);
    // const selectedCity = computed(() => useAccountStore().selectedCity);
    // const loader = computed(() => useAccountStore().loader);
    // function selectProvince(province){
    //     useAccountStore().setCitiesByProvince(province);
    // }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
    }
    .section-inner{
        width: 100%;
    }
    .si-head h1{
        margin: 0 0 1rem 0;
    }
    .si-body{
        display: flex;
        flex-wrap: wrap;
    }
    .pcb-detail{
        width: 30%;
        transition: 0.2s;
    }
    .profile-card{
        width: 100%;
        min-height: 370px;
        overflow: hidden;
        border: 1px solid var(--softgrey);
        border-radius: 10px;
    }
    .profile-card-head{
        width: 100%;
        border-bottom: 1px solid var(--softgrey);
    }
    .pch-image{
        width: 100%;
    }
    .pch-image img{
        width: 100%;
    }
    .pch-text{
        width: 100%;
        display: flex;
        padding: 1rem;
    }
    .pch-name{
        width: 75%;
    }
    .pch-name h2{
        margin: 0;
    }
    .pch-update{
        width: 25%;
    }
    .pch-update-button{
        text-align: right;
    }
    .pch-update-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .profile-card-body{
        position: relative;
        width: 100%;
        padding: 3.7rem 1rem 1rem 1rem;
    }
    .pcb-update-password{
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
    }
    .pup-button button{
        background: var(--grey);
        color: var(--white);
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .profile-card-row{
        width: 100%;
        display: flex;
        padding: 0 0 1rem 0;
    }
    .pcr-param{
        width: 30%;
    }
    .pcr-value{
        width: 70%;
    }
    
    .pch-update-modal{
        padding: 1rem 25% 1rem 25%;
    }
    .pum-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pum-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 90%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .profile-update-form{
        width: 100%;
        height: 100%;
        padding: 0 0 1rem 0;
        overflow: auto;
    }
    .pcb-data{
        width: 70%;
        padding: 0 0 0 1rem;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .pcb-detail{
            width: 100%;
        }
        .pcb-data{
            width: 100%;
            padding: 2.5rem 0 0 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .pch-update-modal{
            padding: 0;
        }
        .pum-body{
            max-height: 100%;
            height: 100%;
        }
    }
</style>