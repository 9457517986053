import { useJadwalStore } from "@/stores/jadwal";
export function transferJadwal(day, index){
    var params = {
        emp_target: document.getElementById('input-teman-' + index).value,
        shift_id: day.shift.id,
        schedule_id: day.id,
        description: document.getElementById('jadwal-input-notes' + index).value
    };
    useJadwalStore().transferSchedule(params);
}
export function showTab(cl, id){
    const button = document.getElementsByClassName(cl.button);
    const content = document.getElementsByClassName(cl.content);
    for(var i = 0; i < button.length; i++){
        button[i].classList.remove('shp-active');
    }
    for(i = 0; i < content.length; i++){
        content[i].classList.remove('sbp-active');
    }
    document.getElementById(id.button).classList.add('shp-active');
    document.getElementById(id.content).classList.add('sbp-active');
}
export function showLemburButton(){
    if(document.getElementById('lembur-button-jadwal')){
        document.getElementById('lembur-button-jadwal').classList.add('lembur-button-active');
    }
}
export function clearInputSf(){
    const input = document.getElementById('input-search-friend');
    input.value = '';
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
    useJadwalStore().searchFriend(input.value);
}
export function inputSf(){
    const input = document.getElementById('input-search-friend');
    useJadwalStore().searchFriend(input.value);
    if(input.value){
        input.parentElement.nextElementSibling.children[0].style.display = 'block';
    }else{
        input.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function selectFriend(){
    const sf = document.getElementById('sso-friend');
    sf.classList.remove('float-active');
}


