import { defineStore } from "pinia";
import axiosClient from "../axios.js";
// import axiosFormData from "@/axiosFormData";
// import { useAccountStore } from "./account";
import { showNotification, hideModals } from "@/functions/general";

export const useApprovalStore = defineStore("approval", {
    state: () => ({
        // ==================== states used on approval page and its relation
        dinas: null,
        sakit: null,
        tugas: null,
        btAbsen: null,
        data: null,
        loader: false
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on approval page and its relation
        async setDinas(){
            try{
                const response = await axiosClient.get(`shift/ofc-travel/submissions`);
                this.dinas = response.data.data;
                // console.log(this.dinas);
            }catch(error){
                console.log(error);
            }
        },
        async setSakit(){
            try{
                const response = await axiosClient.get(`shift/sick-note/submissions`);
                this.sakit = response.data.data;
                // console.log(this.sakit);
            }catch(error){
                console.log(error);
            }
        },
        async setTugas(){
            try{
                const response = await axiosClient.get(`callduty/submissions`);
                this.tugas = response.data.data;
                // console.log(this.tugas);
            }catch(error){
                console.log(error);
            }
        },
        async setBtAbsen(){
            try{
                const response = await axiosClient.get(`attendance/help/submissions`);
                this.btAbsen = response.data.data;
                console.log(this.btAbsen);
            }catch(error){
                console.log(error);
            }
        },
        async settleDinas(params){
            this.loader = true;
            try{
                const response = await axiosClient.post(`shift/ofc-travel/approval`, params);
                if(response.data?.success){
                    this.setDinas();
                    hideModals();
                    if(params.accepted){
                        showNotification('', 'Permintaan disetujui', 2000, 'var(--primary)');
                    }else{
                        showNotification('', 'Permintaan ditolak', 2000, 'var(--primary)');
                    }
                }
                this.loader = false;
                console.log(response);
            }catch(error){
                this.loader = false;
                console.log(error);
            }
        },
        async settleSakit(params){
            this.loader = true;
            try{
                const response = await axiosClient.post(`shift/sick-note/approval`, params);
                if(response.data?.success){
                    this.setSakit();
                    hideModals();
                    if(params.accepted){
                        showNotification('', 'Permintaan disetujui', 2000, 'var(--primary)');
                    }else{
                        showNotification('', 'Permintaan ditolak', 2000, 'var(--primary)');
                    }
                }
                this.loader = false;
                console.log(response);
            }catch(error){
                this.loader = false;
                console.log(error);
            }
        },
        async settleTugas(params){
            this.loader = true;
            try{
                const response = await axiosClient.post(`callduty/submission/approval`, params);
                if(response.data?.success){
                    this.setTugas();
                    hideModals();
                    if(params.accepted){
                        showNotification('', 'Permintaan disetujui', 2000, 'var(--primary)');
                    }else{
                        showNotification('', 'Permintaan ditolak', 2000, 'var(--primary)');
                    }
                }
                this.loader = false;
                console.log(response);
            }catch(error){
                this.loader = false;
                console.log(error);
            }
        },
        async settleBtAbsen(params){
            this.loader = true;
            try{
                const response = await axiosClient.post(`attendance/help/response`, params);
                if(response.data?.success){
                    this.setBtAbsen();
                    hideModals();
                    if(params.accept){
                        showNotification('', 'Permintaan disetujui', 2000, 'var(--primary)');
                    }else{
                        showNotification('', 'Permintaan ditolak', 2000, 'var(--primary)');
                    }
                }
                this.loader = false;
                console.log(response);
            }catch(error){
                this.loader = false;
                console.log(error);
            }
        },
        // ====================
        // async setData(){
        //     try{
        //         await useAccountStore().getProfile();
        //         const response = await axiosClient.get(`shift/ofc-travel`);
        //         this.data = response.data.data;
        //         console.log(this.data);
        //     }catch(error){
        //         console.log(error);
        //     }
        // },
        // async newData(params){
        //     this.loader = true;
        //     window.sessionStorage.setItem('authToken', getCookie('session'));
        //     try{
        //         const response = await axiosFormData.post(`shift/ofc-travel`, params);
        //         if(response.data.success){
        //             showNotification('', 'Permintaan Dinas dikirim', 5000, null);
        //             hideModals();
        //             this.setData();
        //         }
        //         this.loader = false;
        //         console.log(response);
        //     }catch(error){
        //         if(error.response?.status == 422){
        //             showNotification('', error.response.data.message, 2000, 'var(--red)');
        //         }
        //         console.log(error);
        //         this.loader = false;
        //     }
        // },
        // async updateData(params){
        //     this.loader = true;
        //     window.sessionStorage.setItem('authToken', getCookie('session'));
        //     try{
        //         const response = await axiosFormData.post(`shift/ofc-travel`, params);
        //         if(response.data.success){
        //             showNotification('', 'Permintaan Dinas diperbarui', 5000, null);
        //             hideModals();
        //             this.setData();
        //         }
        //         this.loader = false;
        //         // console.log(response);
        //     }catch(error){
        //         if(error.response?.status == 422){
        //             showNotification('', error.response.data.message, 2000, 'var(--red)');
        //         }
        //         console.log(error);
        //         this.loader = false;
        //     }
        // },
        // async deleteData(id){
        //     this.loader = true;
        //     window.sessionStorage.setItem('authToken', getCookie('session'));
        //     try{
        //         const response = await axiosFormData.delete(`shift/ofc-travel/` + id);
        //         if(response.data.success){
        //             showNotification('', 'Permintaan Dinas dibatalkan', 5000, null);
        //             hideModals();
        //             this.setData();
        //         }
        //         this.loader = false;
        //         console.log(response);
        //     }catch(error){
        //         if(error.response?.status == 422){
        //             showNotification('', error.response.data.message, 2000, 'var(--red)');
        //         }
        //         console.log(error);
        //         this.loader = false;
        //     }
        // }
    }
});