import { useKpiStore } from "@/stores/kpi";

export function showTab(cl, id){
    const button = document.getElementsByClassName(cl.button);
    const content = document.getElementsByClassName(cl.content);
    for(var i = 0; i < button.length; i++){
        button[i].classList.remove('shp-active');
    }
    for(i = 0; i < content.length; i++){
        content[i].classList.remove('sbp-active');
    }
    document.getElementById(id.button).classList.add('shp-active');
    document.getElementById(id.content).classList.add('sbp-active');
}
export function actionToggle(btnid){
    if(document.getElementById(btnid)){
        const button = document.getElementById(btnid);
        const buttons = document.getElementsByClassName('srp-accordion-button');
        if(button.nextElementSibling.classList.contains('sao-active')){
            button.nextElementSibling.classList.remove('sao-active');
            button.children[0].children[0].classList.remove('arrow-active');
        }else{
            for(var i = 0; i < buttons.length; i++){
                buttons[i].children[0].children[0].classList.remove('arrow-active');
                buttons[i].nextElementSibling.classList.remove('sao-active');
            }
            button.nextElementSibling.classList.add('sao-active');
            button.children[0].children[0].classList.add('arrow-active');
        }
    }
}
export function clearInputSf(inputid){
    const input = document.getElementById(inputid);
    input.value = '';
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
    useKpiStore().searchFriend(input.value);
}
export function inputSf(inputid){
    const input = document.getElementById(inputid);
    useKpiStore().searchFriend(input.value);
    if(input.value){
        input.parentElement.nextElementSibling.children[0].style.display = 'block';
    }else{
        input.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function selectFriend(){
    const sf = document.getElementById('sso-friend');
    sf.classList.remove('float-active');
}
export function newKpi(){
    const date = document.getElementById('input-date-new').value;
    const title = document.getElementById('input-title-new').value;
    const kpitype = document.getElementById('input-kpitype-new').value;
    const params = {
        action: 1,
        name: title,
        date: date,
        kpi_id: kpitype,
        description: document.getElementById('input-description-new').value,
    };
    useKpiStore().submitKpi(params, [{input: 'input-description-new', value: ''}]);
    // console.log(params);
}
export function updateKpi(data, index){
    const date = document.getElementById('input-date-' + index).value;
    const title = document.getElementById('input-title-' + index).value;
    const kpitype = document.getElementById('input-kpitype-' + index).value;
    const params = {
        action: 2,
        name: title,
        date: date,
        kpi_id: kpitype,
        description: document.getElementById('input-description-' + index).value,
        id: data.id
    };
    useKpiStore().submitKpi(params, []);
    // console.log(params);
}
export function inputLampiran(index){
    const inform = document.getElementById('flp-uplamp-' + index);
    const el = document.createElement('div');
    el.classList.add('element-relative');
    el.style.cssText = 'width: 100%;';
    el.innerHTML = '<input type="file"/><div class="sfi-input-remove" style="position: absolute; top: 0; right: 0; width: 50px; height: 100%; cursor: pointer; display: flex; justify-content: right; align-items: center; padding: 0.5rem; transition: 0.2s;">x</div>';
    el.children[1].addEventListener('click', function(e){
        e.target.parentElement.remove();
    });
    inform.appendChild(el);
}
export async function uplampKpi(data, index){
    const fd = new FormData();
    const attachments = document.getElementById('flp-uplamp-' + index);
    for(var i = 0; i < attachments.children.length; i++){
        if(attachments.children[i].children[0].value){
            const [files] = await attachments.children[i].children[0].files;
            fd.append(`files[${i}]`, files);
            // console.log(files);
            // if (files.type === 'image/png' || files.type === 'image/jpeg' || files.type === 'image/jpg' || files.type === 'image/webp') {
            //     console.log('File Sesuai');
            // } else {
            //     console.error('File harus berupa gambar dengan format PNG, JPG, JPEG, atau WEBP.');
            // }
        }
    }
    fd.append('action', 5);
    fd.append('id', data.id);
    useKpiStore().submitKpi(fd, []);
}
export function startJob(data){
    const params = {
        action: 7,
        id: data.id
    };
    useKpiStore().submitKpi(params, []);
    // console.log(params);
}
export function endJob(data){
    const params = {
        action: 4,
        id: data.id
    };
    useKpiStore().submitKpi(params, []);
    // console.log(params);
}
export function deleteJob(data){
    const params = {
        action: 3,
        id: data.id
    };
    useKpiStore().submitKpi(params, []);
    // console.log(params);
}
export function kpiFilterSelectDateFrom(inputid){
    const input = document.getElementById(inputid);
    useKpiStore().kpiFilterSelectDateFrom(input.value);
}
export function kpiFilterSelectDateTo(inputid){
    const input = document.getElementById(inputid);
    useKpiStore().kpiFilterSelectDateTo(input.value);
}
export function filterLinkLabel(label){
    if(label == '&laquo; Previous'){
        return 'Sebelumnya';
    }
    if(label == 'Next &raquo;'){
        return 'Selanjutnya';
    }
    return label;
}