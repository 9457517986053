<template>
    <section>
        <div class="section-inner" v-if="data">
            <div class="si-head">
                <div class="si-head-inner">
                    <div class="data-baru">
                        <div class="data-baru-button">
                            <button @click="showModal('data-baru-modal');">Ajukan Perjalanan Tugas</button>
                        </div>
                        <div class="modal srp-modal" id="data-baru-modal">
                            <div class="srp-modal-head">
                                <div class="smh-close" @click="hideModal('data-baru-modal')">
                                    <span>x</span>
                                </div>
                            </div>
                            <div class="srp-modal-body">
                                <div class="smb-form">
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Tanggal</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="date" id="input-data-date"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Mulai</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="time" id="input-data-mulai"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Selesai</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="time" id="input-data-selesai"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Keterangan</span>
                                        </div>
                                        <div class="sfi-input">
                                            <textarea id="input-data-keterangan"></textarea>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Lampiran</span>
                                        </div>
                                        <div class="sfi-input" :id="'flb-attachments'">
                                            <small><i>maksimal ukuran file adalah 1 MB</i></small>
                                            <div>
                                                <input type="file"/>
                                            </div>
                                        </div>
                                        <div class="sfi-input-more">
                                            <span @click="inputLampiran('flb-attachments')">+ tambah lampiran</span>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-button" v-if="!loader">
                                            <button @click="newData();">Submit</button>
                                        </div>
                                        <div class="sfi-button" v-else>
                                            <button>Loading.....</button>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="si-body">
                <div class="si-body-inner" v-if="data.length > 0">
                    <div class="sbi-table">
                        <div class="sbi-table-fixed">
                            <div class="sbi-table-head stf-head">
                                <div class="shp-num">
                                    <span>No</span>
                                </div>
                                <div class="shp-num">
                                    <span>Tanggal</span>
                                </div>
                            </div>
                            <div class="sbi-table-body">
                                <div :class="'stb-row stb-row-' + (index % 2)" v-for="(dt, index) in data" :key="dt">
                                    <div class="sbp-num">
                                        <span>{{ index + 1 }}</span>
                                    </div>
                                    <div class="sbp-num">
                                        <span>{{ dt.date }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sbi-table-scroll" @scroll="tableScroll">
                            <div class="sbi-table-head">
                                <div class="sts-head-part">
                                    <span>Mulai</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Selesai</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Status</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Keterangan</span>
                                </div>
                                <div class="sts-head-part">
                                    <span>Action</span>
                                </div>
                            </div>
                            <div class="sbi-table-body">
                                <div class="stb-row" v-for="(dt, index) in data" :key="dt">
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.time_start }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.time_end }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.status_string }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <span>{{ dt.description }}</span>
                                    </div>
                                    <div :class="'sts-row-part srp-' + (index % 2)">
                                        <div class="srp-accordion" v-if="dt.status < 1">
                                            <div class="srp-accordion-button" :id="'sab-data-action-' + index" @click="actionToggle('sab-data-action-' + index)">
                                                <div class="sab-icon">
                                                    <img src="../../assets/images/icons/arrow-1.png" alt="arrow"/>
                                                </div>
                                            </div>
                                            <div class="srp-accordion-options">
                                                <div class="sao-list">
                                                    <div class="">
                                                        <span @click="showModal('modal-sxlbr-' + index)">Update</span>
                                                    </div>
                                                    <div class="modal srp-modal" :id="'modal-sxlbr-' + index">
                                                        <div class="srp-modal-head">
                                                            <div class="smh-close" @click="hideModal('modal-sxlbr-' + index)">
                                                                <span>x</span>
                                                            </div>
                                                        </div>
                                                        <div class="srp-modal-body">
                                                            <div class="smb-form">
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-label">
                                                                        <span>Tanggal</span>
                                                                    </div>
                                                                    <div class="sfi-input">
                                                                        <input type="date" :id="'input-data-date' + index" :value="dt.date"/>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-label">
                                                                        <span>Mulai</span>
                                                                    </div>
                                                                    <div class="sfi-input">
                                                                        <input type="time" :id="'input-data-mulai' + index" :value="dt.time_start"/>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-label">
                                                                        <span>Selesai</span>
                                                                    </div>
                                                                    <div class="sfi-input">
                                                                        <input type="time" :id="'input-data-selesai' + index" :value="dt.time_end"/>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-label">
                                                                        <span>Keterangan</span>
                                                                    </div>
                                                                    <div class="sfi-input">
                                                                        <textarea :id="'input-data-keterangan' + index" :value="dt.description"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-label">
                                                                        <span>Lampiran</span>
                                                                    </div>
                                                                    <div class="sfi-input" :id="'flb-attachments' + index">
                                                                        <small><i>maksimal ukuran file adalah 1 MB</i></small>
                                                                        <div>
                                                                            <input type="file"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="sfi-input-more">
                                                                        <span @click="inputLampiran('flb-attachments' + index)">+ tambah lampiran</span>
                                                                    </div>
                                                                </div>
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-button" v-if="!loader">
                                                                        <button @click="updateData(index, dt.id);">Update</button>
                                                                    </div>
                                                                    <div class="sfi-button" v-else>
                                                                        <button>Loading.....</button>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sao-list">
                                                    <div class="">
                                                        <span @click="showModal('modal-dtdelelete-' + index)">Batalkan</span>
                                                    </div>
                                                    <div class="modal srp-modal" :id="'modal-dtdelelete-' + index">
                                                        <div class="srp-modal-head">
                                                            <div class="smh-close" @click="hideModal('modal-dtdelelete-' + index)">
                                                                <span>x</span>
                                                            </div>
                                                        </div>
                                                        <div class="srp-modal-body">
                                                            <div class="smb-form">
                                                                <p>Batalkan Permintaan ini ?</p>
                                                                <div class="smb-form-input">
                                                                    <div class="sfi-button" v-if="!loader">
                                                                        <button @click="deleteData(dt.id);">Batalkan</button>
                                                                    </div>
                                                                    <div class="sfi-button" v-else>
                                                                        <button>Loading.....</button>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="si-body-inner sso-note" v-else>
                    <p>Tidak ada data</p>
                </div>
            </div>
        </div>
        <CutiSkeleton v-else/>
    </section>
</template>

<script setup>
    import CutiSkeleton from '@/components/skeletons/sections/CutiSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useTugasStore } from '@/stores/tugas';
    import { showModal, hideModal, actionToggle } from '@/functions/general';
    import { newData, updateData, deleteData, inputLampiran } from '@/functions/tugas';
    const data = computed(() => useTugasStore().data);
    const loader = computed(() => useTugasStore().loader);
    useTugasStore().setData();
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        transition: 0.2s;
        /* border: 1px solid green; */
    }
    .si-head{
        padding: 0 0 2rem 0;
    }
    .data-baru-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        user-select: none;
        cursor: pointer;
    }
    .si-body-inner{
        padding: 0 0 2.5rem 0;
        /* border: 1px solid gold; */
    }
    .sbi-table{
        width: 100%;
        display: flex;
        /* border: 1px solid green; */
        padding: 0 0 2.5rem 0;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
        padding: 0 0 5rem 0;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
        padding: 0 0 5rem 0;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 75px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-0{
        background: var(--smoke);
    }
    .sts-row-part{
        min-width: 75px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .srp-0{
        background: var(--smoke);
    }
    .srp-accordion{
        position: relative;
        width: 100%;
        height: 37px;
        user-select: none;
    }
    .srp-accordion-button{
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .sab-icon img{
        transition: 0.2s;
    }
    .srp-accordion-options{
        position: absolute;
        top: 100%;
        left: 5%;
        width: 90%;
        background: var(--white);
        z-index: 1;
        display: none;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .sao-active{
        display: block;
    }
    .sao-list{
        padding: 0.5rem;
    }
    .srp-button{
        text-align: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .srp-modal{
        padding: 100px 25% 1rem 25%;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        max-height: 90%;
        overflow: auto;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 0;
        }
        .srp-modal-body{
            height: 100%;
        }
    }
    /*  */
</style>