<template>
    <section>
        <div class="section-head" v-if="documentDetail">
            <h1>{{ documentDetail.name }}</h1>
            <!-- <p>Description</p> -->
        </div>
        <div class="section-body" v-if="documentDetail">
            <!-- <iframe src="../../../public/goto_prospektus.pdf" width="100%" height="750" id="iframe-pdf"></iframe> -->
            <!-- <iframe src="https://assets.tokopedia.net/asts/goto/Ringkas%20Awal%20_%20GOTO%20-%2014%20Maret%2022_23.00.pdf#toolbar=0" width="100%" height="1000" id="iframe-pdf"></iframe> -->
            <!-- <embed type="application/pdf" src="https://assets.tokopedia.net/asts/goto/Ringkas%20Awal%20_%20GOTO%20-%2014%20Maret%2022_23.00.pdf#toolbar=0&view=fitV" width="100%" height="1000" id="embed-docdetail"/> -->
            <embed type="text/html" :src="embedsrc + documentDetail.slug + '?_token=' + getCookie('session') + '#view=fitV'" width="100%" id="embed-docdetail" style="height: fit-content; min-height: 1000px;"/>
            <!-- <embed type="text/html" src="http://localhost:8080/dokumen/test" width="100%" height="1000" id="zzzzz"/> -->
        </div>
    </section>
    <!-- <h1>Document Test</h1> -->
    <!-- <pdf src="https://assets.tokopedia.net/asts/goto/Ringkas%20Awal%20_%20GOTO%20-%2014%20Maret%2022_23.00.pdf"></pdf> -->
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from '@vue/reactivity';
    import { useDocumentStore } from '@/stores/document';
    import { getCookie } from '@/functions/general';
    const documentDetail = computed(() => useDocumentStore().documentDetail);
    const embedsrc = process.env.VUE_APP_ROOT_API + 'dms/doc/read/';
    useDocumentStore().setDocumentDetail(useRoute().params.slug);
    // import pdf from 'vue-pdf';
    document.oncontextmenu = function(){
        return false;
    }
    // window.onload = function(){
    //     setTimeout(function(){
    //         // document.children[0].children[1].children[1].children[1].children[0].children[0].children[0]
    //         // console.log(document.children[0].children[1].children[1].children[1].children[0].children[0].children[0]);
    //     }, 0);
    // }
    // window.frames["embed-docdetail"].contentDocument.oncontextmenu = function(){
    //     return false; 
    // };
    // console.log(window.frames['iframe-pdf']);
    window.onkeydown = function(e){
        if(e.keyCode == 83){
            if(e.ctrlKey){
                e.preventDefault();
                alert('Maaf, halaman ini tidak boleh disimpan');
            }
        }
        if(e.keyCode == 123){
            e.preventDefault();
            alert('Maaf, tindakan anda tidak diperbolehkan');
        }
        if(e.keyCode == 67){
            if(e.ctrlKey && e.shiftKey){
                e.preventDefault();
                alert('Maaf, tindakan anda tidak diperbolehkan');
            }
        }
        console.log(e);
    }
</script>

<style scoped>
    @media print{
        section{
            display: none;
        }
    }
    section{
        width: 100%;
        position: relative;
    }
    .section-head{
        text-align: center;
    }
    .section-body{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
</style>