<template>
	<nav class="navbar" id="navbar">
		<div class="nav-pad">
			<div class="navbar-inner">
				<div class="navbar-logo">
					<div class="navbar-logo-img">
						<router-link to="/" @click="pageScrollTop">
							<img src="./assets/images/logos/melindacvc-logo.webp" alt="melinda hcs" v-if="appname == 'CVC'">
							<img src="./assets/favicon.png" alt="melinda hcs" v-else>
						</router-link>
					</div>
					<div class="navbar-logo-text">
						<router-link to="/" @click="pageScrollTop">
							<span class="nlt-melinda">Melinda</span>
							<span class="nlt-hcs">HCS</span>
						</router-link>
					</div>
				</div>
				<div class="navbar-menu" id="navbar-menu">
					<div class="nm-inner">
						<div class="nm-part">
							<div class="dropdown" id="dropdown-document">
								<div class="dropdown-button trigger" @click="dropdownToggle('dropdown-document')">
									<div class="dropdown-button-text trigger">
										<span class="trigger">Dokumen</span>
									</div>
									<div class="dropdown-button-icon trigger">
										<img src="../src/assets/images/icons/arrow-1.png" class="trigger" alt="melinda cvc"/>
									</div>
								</div>
								<div class="dropdown-option">
									<div class="dropdown-list">
										<div class="dropdown-list-part">
											<router-link to="/dokumen" class="nm-part-link" @click="dropdownToggle('dropdown-document'), menuToggle()">List Dokumen</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/request" class="nm-part-link" @click="dropdownToggle('dropdown-document'), menuToggle()">Permintaan Akses</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="nm-part">
							<router-link to="/absen" class="nm-part-link" @click="menuToggle">Absen</router-link>
						</div>
						<div class="nm-part">
							<div class="dropdown" id="dropdown-schedule">
								<div class="dropdown-button trigger" @click="dropdownToggle('dropdown-schedule')">
									<div class="dropdown-button-text trigger">
										<span class="trigger">Jadwal</span>
									</div>
									<div class="dropdown-button-icon trigger">
										<img src="../src/assets/images/icons/arrow-1.png" class="trigger" alt="melinda cvc"/>
									</div>
								</div>
								<div class="dropdown-option">
									<div class="dropdown-list">
										<div class="dropdown-list-part">
											<router-link to="/jadwal-kerja" class="nm-part-link" @click="dropdownToggle('dropdown-schedule'), menuToggle()">Jadwal Kerja</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/presensi" class="nm-part-link" @click="dropdownToggle('dropdown-schedule'), menuToggle()">Presensi</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/lembur" class="nm-part-link" @click="dropdownToggle('dropdown-schedule'), menuToggle()">Lembur</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="nm-part">
							<div class="dropdown" id="dropdown-perizinan">
								<div class="dropdown-button trigger" @click="dropdownToggle('dropdown-perizinan')">
									<div class="dropdown-button-text trigger">
										<span class="trigger">Perizinan</span>
									</div>
									<div class="dropdown-button-icon trigger">
										<img src="../src/assets/images/icons/arrow-1.png" class="trigger" alt="melinda cvc"/>
									</div>
								</div>
								<div class="dropdown-option">
									<div class="dropdown-list">
										<div class="dropdown-list-part">
											<router-link to="/cuti" class="nm-part-link" @click="dropdownToggle('dropdown-perizinan'), menuToggle()">Cuti</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/dinas" class="nm-part-link" @click="dropdownToggle('dropdown-perizinan'), menuToggle()">Dinas</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/sakit" class="nm-part-link" @click="dropdownToggle('dropdown-perizinan'), menuToggle()">Sakit</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/tugas" class="nm-part-link" @click="dropdownToggle('dropdown-perizinan'), menuToggle()">Tugas</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/resign" class="nm-part-link" @click="dropdownToggle('dropdown-perizinan'), menuToggle()">Resign</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/absen/bantuan" class="nm-part-link" @click="dropdownToggle('dropdown-perizinan'), menuToggle()">Bantuan Absen</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="nm-part">
							<router-link to="/presensi" class="nm-part-link" @click="menuToggle">Presensi</router-link>
						</div>
						<div class="nm-part">
							<router-link to="/lembur" class="nm-part-link" @click="menuToggle">Lembur</router-link>
						</div> -->
						<!-- <div class="nm-part">
							<router-link to="/cuti" class="nm-part-link" @click="menuToggle">Cuti</router-link>
						</div> -->
						<!-- <div class="nm-part">
							<router-link to="/resign" class="nm-part-link" @click="menuToggle">Resign</router-link>
						</div> -->
						<!-- <div class="nm-part">
							<router-link to="/payroll" class="nm-part-link" @click="menuToggle">Payroll</router-link>
						</div> -->
						<div class="nm-part">
							<div class="dropdown" id="dropdown-profile">
								<div class="dropdown-button trigger" @click="dropdownToggle('dropdown-profile')">
									<div class="dropdown-button-text trigger">
										<span class="trigger">User</span>
									</div>
									<div class="dropdown-button-icon trigger">
										<img src="../src/assets/images/icons/arrow-1.png" class="trigger" alt="melinda cvc"/>
									</div>
								</div>
								<div class="dropdown-option">
									<div class="dropdown-list">
										<div class="dropdown-list-part">
											<router-link to="/profil" class="nm-part-link" @click="dropdownToggle('dropdown-profile'), menuToggle()">Profil</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/kpi" class="nm-part-link" @click="dropdownToggle('dropdown-profile'), menuToggle()">KPI</router-link>
										</div>
										<div class="dropdown-list-part">
											<router-link to="/payroll" class="nm-part-link" @click="dropdownToggle('dropdown-profile'), menuToggle()">Payroll</router-link>
										</div>
										<!-- <div class="dropdown-list-part">
											<router-link to="/dokumen/unggah" class="nm-part-link" @click="dropdownToggle('dropdown-profile'), menuToggle()">Unggah Dokumen</router-link>
										</div> -->
										<div class="dropdown-list-part" v-if="account && leaders.includes(account.org_active?.id)">
											<router-link to="/approval" class="nm-part-link" @click="dropdownToggle('dropdown-profile'), menuToggle()">Approval</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="nm-part">
							<router-link to="/profil" class="nm-part-link" @click="menuToggle">Profil</router-link>
						</div> -->
						<div class="nm-part nm-mobile">
							<router-link to="/login" v-if="!account">
								<span @click="menuToggle();">Login</span>
							</router-link>
							<span v-else @click="showModal('modal-logout');">Logout</span>
						</div>
					</div>
					<div class="nm-mobile-close" @click="menuHide">
						<img src="./assets/images/icons/mbclose.png" alt="p3k melinda" />
					</div>
				</div>
				<div class="navbar-menu-bg" id="navbar-menu-bg" @click="menuHide"></div>
				<div class="navbar-button">
					<div class="nb-login">
						<div class="nb-login-button">
							<router-link to="/login" v-if="!account">
								<span>Login</span>
							</router-link>
							<span v-else @click="showModal('modal-logout');">Logout</span>
						</div>
					</div>
					<div class="nb-menumob">
						<img src="./assets/images/icons/mobmenu.png" alt="p3k melinda" @click="menuShow" />
					</div>
				</div>
			</div>
		</div>
	</nav>
	<div class="content">
		<router-view/>
	</div>
	<footer>
		<!-- <div class="footer-top">
			<div class="staff">
				<div class="staff-icon">
					<img src="./assets/images/icons/cb-phone.png" alt="RSIA Melinda phone" class="staff-icon-phone" />
					<img src="./assets/images/icons/male-staff.png" alt="RSIA Melinda staff" class="staff-icon-male" />
				</div>
				<div class="staff-text">
					<p>Please feel free to contact our friendly reception staff with any medical enquiry, or call (022) 4233777</p>
				</div>
			</div>
			<div class="sosign">
				<div class="socials">
					<a href="https://www.facebook.com">
					<div class="socials-icon">
						<img src="./assets/images/icons/cc-facebook.png" alt="RSIA Melinda socials">
					</div>
					</a>
					<a href="https://www.twitter.com">
					<div class="socials-icon">
						<img src="./assets/images/icons/cc-twitter.png" alt="RSIA Melinda socials">
					</div>
					</a>
					<a href="https://www.linkedin.com">
					<div class="socials-icon">
						<img src="./assets/images/icons/cc-linkedin.png" alt="RSIA Melinda socials">
					</div>
					</a>
					<a href="https://www.instagram.com">
					<div class="socials-icon">
						<img src="./assets/images/icons/cc-instagram.png" alt="RSIA Melinda socials">
					</div>
					</a>
				</div>
				<div class="signup">
					<div class="signup-button">
						<button @click="showModal('signup-modal');">Pendaftaran</button>
					</div>
					<div class="modal signup-modal" id="signup-modal">
						<div class="signup-modal-head">
							<div class="smh-close" @click="hideModal('signup-modal');">
								<span>x</span>
							</div>
						</div>
						<div class="signup-modal-body">
							<div class="smb-inner">
								<div class="smb-form">
									<div class="smb-form-input poli-elput" v-if="poliSelect">
										<input type="hidden" name="specialist" label="Poli" class="poli-input" id="sfi-input-specialist"/>
										<div class="sfi-label">Pilih Poli</div>
										<div class="sfi-select">
											<div class="sfi-select-button" id="ssb-signup-specialist" @click="optionToggle('ssb-signup-specialist');">
												<div class="btn-cover float-button"></div>
												<div class="ssb-text" id="ssb-text-signup" v-if="poliSelect.selected">
													<span>{{ poliSelect.selected.name }}</span>
												</div>
												<div class="ssb-text" id="ssb-text-signup" v-else>
													<span>Pilih Spesialis</span>
												</div>
												<div class="float-icon">
													<img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
												</div>
											</div>
											<div class="sfi-select-option float">
												<div class="sso-notif" v-if="poliSelect.specialists.length > 0">
													<span>{{ 'ada ' + poliSelect.specialists.length + ' poli' }}</span>
												</div>
												<div class="sso-button-list">
													<div class="sso-button" v-for="sp in poliSelect.specialists" :key="sp" @click="selectOption(sp, {button: 'ssb-signup-specialist', input: 'sfi-input-specialist', name: 'ssb-text-signup'}); useDoctorStore().selectPoli(sp.slug);">
														<span>{{ sp.name }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="smb-form-input poli-elput" v-if="poliSelect?.doctors">
										<input type="hidden" name="doctor" label="Dokter" class="poli-input" id="sfi-input-doctor"/>
										<div class="sfi-label">Pilih Dokter</div>
										<div class="sfi-select">
											<div class="sfi-select-button" id="ssb-signup-doctor" @click="optionToggle('ssb-signup-doctor');">
												<div class="btn-cover float-button"></div>
												<div class="ssb-text" id="st-signup-doctor" v-if="poliSelect.doctors.selected">
													<span>{{ poliSelect.doctors.selected.name }}</span>
												</div>
												<div class="ssb-text" id="st-signup-doctor" v-else>
													<span>Pilih Dokter</span>
												</div>
												<div class="float-icon">
													<img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
												</div>
											</div>
											<div class="sfi-select-option float">
												<div class="sso-notif" v-if="poliSelect.doctors.list.length > 0">
													<span>{{ 'ada ' + poliSelect.doctors.list.length + ' dokter' }}</span>
												</div>
												<div class="sso-button-list">
													<div class="sso-button" v-for="doctor in poliSelect.doctors.list" :key="doctor" @click="useDoctorStore().selectPoliDoctor(doctor);">
														<span>{{ doctor.name }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="smb-form-input poli-elput" v-if="poliSelect?.schedule">
										<input type="hidden" name="schedule" label="Jam Praktek" class="poli-input" id="sfi-input-schedule"/>
										<div class="sfi-label">Pilih Jam Praktek</div>
										<div class="sfi-select">
											<div class="sfi-select-button" id="ssb-signup-schedule" @click="optionToggle('ssb-signup-schedule');">
												<div class="btn-cover float-button"></div>
												<div class="ssb-text" id="st-signup-schedule">
													<span>Pilih Jam Praktek</span>
												</div>
												<div class="float-icon">
													<img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
												</div>
											</div>
											<div class="sfi-select-option float">
												<div class="sso-notif" v-if="poliSelect.schedule.length > 0">
													<span>{{ 'ada ' + poliSelect.schedule.length + ' jadwal, klik pada tombol waktu untuk memilih' }}</span>
												</div>
												<div class="sso-button-list">
													<div class="sso-button sso-button-daytime" v-for="sc in poliSelect.schedule" :key="sc">
														<div class="sso-button-schedule">
															<div class="sbs-day">
																<span>{{ sc.name }}</span>
															</div>
															<div class="sbs-clock">
																<div class="sbs-clock-list" v-for="time in sc.schedule" :key="time">
																	<div class="scl-inner" @click="selectSchedule(sc, {button: 'ssb-signup-schedule', input: 'sfi-input-schedule', name: 'st-signup-schedule'}, time.doctor_schedule_id);" v-if="time.appoinment != 1">
																		<span>{{ scheduleHour(time.time_start) }} - {{ scheduleHour(time.time_end) }}</span>
																	</div>
																	<div class="scl-inner scl-inner-appointment" @click="selectSchedule(sc, {button: 'ssb-signup-schedule', input: 'sfi-input-schedule', name: 'st-signup-schedule'}, time.doctor_schedule_id);" v-else>
																		<span>By Appointment</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="smb-form-input poli-elput">
										<div class="sfi-label">Nama Pasien</div>
										<input type="text" name="name" class="poli-input" id="poli-input-name" label="Nama Pasien" placeholder="masukkan nama pasien" autocomplete="off"/>
										<div class="sfi-alert"></div>
									</div>
									<div class="smb-form-input poli-elput">
										<div class="sfi-label">Nomor Ponsel</div>
										<input type="text" name="phone" class="poli-input" id="poli-input-phone" label="Nomor Ponsel" placeholder="nomor ponsel" autocomplete="off"/>
										<div class="sfi-alert"></div>
									</div>
									<div class="smb-form-input">
										<button @click="poliRegistration">Daftar Sekarang</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<!-- <div class="footer-middle">
			<div class="fm-logo">
				<img src="./assets/favicon.png" alt="RSIA Melinda logo"/>
			</div>
			<div class="fm-text">
				<div class="fm-office">
					<h2>Office</h2>
					<p>Jl. Dr. Cipto no 1, Bandung. Jawa Barat - Indonesia</p>
				</div>
				<div class="fm-contact">
					<h2>Contact</h2>
					<p>Hunting  : (022) 4233777 Pendaftaran : 08157100888 (Call & WhatsApp) UGD       : (022) 4232777</p>
				</div>
			</div>
		</div> -->
		<div class="footer-bottom">
			<div class="fb-left">
				<p>© {{ new Date().getFullYear() }} Melinda HCS, All Rights Reserved.</p>
			</div>
			<div class="fb-right">
				<!-- <div class="fbr-medals">
					<img src="./assets/images/icons/medals.png" alt="RSIA Melinda medals">
				</div>
				<div class="fbr-text">
					<router-link to="/">Privacy Policy</router-link>
					<router-link to="/" class="cookies-link">Cookies</router-link>
				</div> -->
			</div>
		</div>
		<div class="notification" id="notification">
			<div class="notification-head">
				<h5 class="notification-message" id="notification-title"></h5>
			</div>
			<div class="notification-body">
				<p class="notification-message" id="notification-message"></p>
			</div>
		</div>
		<div class="modals">
			<div class="modal modal-logout" id="modal-logout" v-if="account">
				<div class="modal-logout-head">
					<div class="mlh-close" @click="hideModal('modal-logout');">
						<span>x</span>
					</div>
				</div>
				<div class="modal-logout-body">
					<div class="mlb-inner">
						<div class="mlb-text">
							<p>Logout ?</p>
						</div>
						<div class="mlb-button">
							<div class="mlb-button-part mbp-yes">
								<button @click="useAccountStore().logout();" v-if="!loader">Ya</button>
								<button v-else>Loading.....</button>
							</div>
							<div class="mlb-button-part mbp-no">
								<button @click="hideModal('modal-logout');">Tidak</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal modal-logout" id="modal-announcement">
				<div class="modal-logout-head">
					<div class="mlh-close" @click="hideModal('modal-announcement');">
						<span>x</span>
					</div>
				</div>
				<div class="modal-logout-body">
					<div class="mlb-inner">
						<div class="mlb-text">
							<h5>Pengumuman</h5>
							<p>Untuk Per Bulan OKTOBER Absensi WAJIB Menggunakan Sidik Jari. Seluruh Staff Diwajibkan mendaftarkan Sidik jarinya Sebelum Tgl 30.</p>
							<p>Untuk Pendaftaran Sidik Jari Silahkan menghubungi Rizky IT</p>
						</div>
						<div class="mlb-button">
							<div class="mbp-yes">
								<button @click="hideModal('modal-announcement');">Oke !</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="go-top" @click="pageScrollTop">
			<img src="./assets/images/icons/arrow-1.png" alt="RSIA Melinda" />
		</div>
	</footer>
</template>
<script setup>
	import { computed } from '@vue/reactivity';
	import { showModal, hideModal, getCookie } from './functions/general';
	// import { getCookie } from './functions/general';
	import { useAccountStore } from './stores/account';
	import { dropdownToggle } from '@/assets/js/dropdown';
	// import { onMounted } from 'vue';
	const appname = process.env.VUE_APP_NAME;
	// const announcement = process.env.VUE_APP_ANNOUNCEMENT;
	const account = computed(() => useAccountStore().account);
	const loader = computed(() => useAccountStore().loader);
	const leaders = computed(() => useAccountStore().leaders);
	// import { computed } from '@vue/reactivity';
	// import { useDoctorStore } from './stores/doctor';
	// import { navbarMenuSelect, showModal, hideModal, optionToggle, selectOption, scheduleHour } from '@/functions/general';
	// import { selectSchedule, poliRegistration } from '@/functions/doctor';
	// const poliSelect = computed(() => useDoctorStore().poliSelect);
	// useDoctorStore().setSpecialists();
	// onMounted(() => {
	// 	if(announcement == 1){
	// 		showModal('modal-announcement');
	// 	}
	// });
	function menuToggle(){
	const nm = document.getElementById('navbar-menu');
	const nb = document.getElementById('navbar-menu-bg');
	if(screen.width <= 750){
		if(nm.style.left == '26%'){
			nm.style.left = '110%';
			nb.style.width = '0';
		}else{
			nm.style.left = '26%';
			nb.style.width = '100%';
		}
	}
	const np = document.getElementsByClassName('npa-option');
	for(var i = 0; i < np.length; i++){
		np[i].classList.remove('npa-option-active');
		np[i].style.height = 0;
	}
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	}
	function menuShow(){
	document.getElementById('navbar-menu').style.left = '26%';
	document.getElementById('navbar-menu-bg').style.width = '100%';
	}
	function menuHide(){
	document.getElementById('navbar-menu').style.left = '110%';
	document.getElementById('navbar-menu-bg').style.width = '0';
	}
	function pageScrollTop(){
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	}
	if(getCookie('session')){
		useAccountStore().getLeaders();
	}
</script>
<style>
	@import url('../src/assets/css/dropdown.css');
	@font-face {
		font-family: 'roboto';
		src: url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
		font-display: swap;
	}
	@font-face {
		font-family: 'poppins';
		src: url('assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
		font-display: swap;
		font-weight: bold;
	}
	@font-face {
		font-family: 'poppins-light';
		src: url('assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
		font-display: swap;
		/* font-weight: bold; */
	}
	@font-face {
		font-family: 'varela-round';
		src: url('assets/fonts/varela-round/VarelaRound-Regular.ttf') format('truetype');
		font-display: swap;
		/* font-weight: bold; */
	}
	@font-face {
		font-family: 'trueno';
		src: url('assets/fonts/trueno/TruenoRg.otf') format('opentype');
		font-display: swap;
		/* font-weight: bold; */
	}
	:root {
		--primary: #75C6EF;
		--primary-hover: #65b7e0;
		--white: #ffffff;
		--grey: #575757;
		--grey-hover: #6d6b6b;
		--softgrey: lightgrey;
		--smoke: whitesmoke;
		--navy: #1B4674;
		--red: #CD4051;
		--red-hover: #b13746;
		--cream: #FCE8CE;
		--cream-bg: #FEF8F0;
		--softcream: #F5F4F4;
	}
	*{
		box-sizing: border-box;
	}
	html{
		scroll-behavior: smooth;
		display: flex;
		justify-content: center;
		font-size: 16px;
	}
	body{
		width: 100%;
		margin: 0;
		font-family: roboto;
		font-size: 1rem;
		min-width: 250px;
		max-width: 2500px; /* same as .nav-pad */
		color: var(--grey);
	}
	::-webkit-scrollbar{
		width: 0.5rem;
	}
	::-webkit-scrollbar-thumb{
		background: var(--primary);
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active{
		-webkit-box-shadow: 0 0 0 30px white inset !important;
	}
	input:-webkit-autofill{
		-webkit-text-fill-color: var(--grey) !important;
	}
	:any-link{
		color: var(--primary);
		text-decoration: none;
	}
	.link-navy a{
		color: var(--navy);
	}
	.link-white a{
		color: var(--white);
	}
	.section-bg-cream{
		background: var(--cream-bg);
	}
	.section-bg-navy{
		background: var(--navy);
	}
	.section-bg-red{
		background: var(--red);
	}
	.section-bg-white{
		background: var(--white);
	}
	.section-color-navy{
		color: var(--navy);
	}
	.section-color-white{
		color: var(--white);
	}
	.color-gold{
		color: gold;
	}
	.color-green{
		color: green;
	}
	.color-lime{
		color: lime;
	}
	.color-red{
		color: red;
	}
	.button-main a{
		background: var(--primary);
		color: var(--white);
		padding: 0.75rem;
		padding-left: 2rem;
		padding-right: 2rem;
		border-radius: 2rem;
		text-decoration: none;
	}
	.element-relative{
		position: relative;
	}
	/* skeleton starts */
	.skeleton-circle{
		animation: pulse-bg 1s infinite;
	}
	.skeleton-circle-white{
		animation: pulse-bg-white 1s infinite;
	}
	.skeleton-circle-50{
		width: 50px;
		height: 50px;
		border-radius: 50px;
	}
	.skeleton-square{
		animation: pulse-bg 1s infinite;
	}
	.skeleton-square-white{
		animation: pulse-bg-white 1s infinite;
	}
	.sq-100{
		width: 100px;
		height: 100px;
	}
	.sq-50{
		width: 50px;
		height: 50px;
	}
	.skeleton-text p{
		margin: 0.5rem 0 0.5rem 0;
		height: 1rem;
		animation: pulse-bg 1s infinite;
		border-radius: 1rem;
	}
	.st-100{
		width: 100%;
	}
	.st-75{
		width: 75%;
	}
	.st-50{
		width: 50%;
	}
	/* skeleton ends */
	@media print {
		.no-print{
			display: none;
		}
	}
	@keyframes pulse-bg{
		0%{
			background-color: #eee;
		}
		50%{
			background-color: #e0e0e0;
		}
		100%{
			background-color: #eee;
		}
	}
	@keyframes pulse-bg-transparent{
		0%{
			background-color: rgba(238, 238, 238, 0.658);
		}
		50%{
			background-color: #e0e0e065;
		}
		100%{
			background-color: rgba(238, 238, 238, 0.548);
		}
	}
	@keyframes pulse-bg-white{
		0%{
			background-color: #eee;
		}
		50%{
			background-color: #ffffff;
		}
		100%{
			background-color: #eee;
		}
	}
	@keyframes pulse-bg-grey{
		0%{
			background-color: rgb(160, 157, 157);
		}
		50%{
			background-color: #a1a1a1;
		}
		100%{
			background-color: rgb(160, 157, 157);
		}
	}
	@keyframes pulse-bg-navy{
		0%{
			background-color: #a1c1e2;
		}
		50%{
			background-color: #1B4674;
		}
		100%{
			background-color: #a1c1e2;
		}
	}
	@keyframes pulse-bg-bluesky{
		0%{
			background-color: #cceeff;
		}
		50%{
			background-color: #75C6EF;
		}
		100%{
			background-color: #cceeff;
		}
	}
	@keyframes pulse-bg-red{
		0%{
			background-color: var(--red);
		}
		50%{
			background-color: #9b656e;
		}
		100%{
			background-color: var(--red);
		}
	}
	@-webkit-keyframes animatenlloption {
		from {top:200%; opacity:0}
		to {top:75%; opacity:1}
	}
	@keyframes animatenlloption {
		from {top:200%; opacity:0}
		to {top:75%; opacity:1}
	}
	@-webkit-keyframes animatenotification {
		from {right:-100%; opacity:0}
		to {right:10%; opacity:1}
	}
	@keyframes animatenotification {
		from {right:-100%; opacity:0}
		to {right:10%; opacity:1}
	}
	@-webkit-keyframes animatesignup {
		from {top:-100%; opacity:0}
		to {top:0; opacity:1}
	}
	@keyframes animatesignup {
		from {top:-100%; opacity:0}
		to {top:0; opacity:1}
	}
	@-webkit-keyframes fromTop {
		from {top:-100%; opacity:0}
		to {top:0; opacity:1}
	}
	@keyframes fromTop {
		from {top:-100%; opacity:0}
		to {top:0; opacity:1}
	}
	/* navbar */
	.navbar{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 4.81rem;
		display: flex;
		border: 1px solid whitesmoke;
		font-family: roboto;
		font-weight: 600;
		background: var(--white);
		z-index: 16;
		white-space: nowrap;
		user-select: none;
		transition: 0.2s;
		justify-content: center;
	}
	.navbar-down{
		z-index: 1;
	}
	.nav-pad{
		width: 100%;
		max-width: 2500px; /* same as body */
		padding-top: 0.5rem;
		padding-left: 10%;
		padding-right: 10%;
		transition: 0.2s;
	}
	.navbar-inner{
		display: flex;
		flex-wrap: nowrap;
		text-align: center;
		height: 100%;
	}
	.navbar-logo{
		width: 51%;
		text-align: left;
		transition: 0.2s;
		padding: 0 1rem 0 0;
		/* border: 1px solid red; */
		display: flex;
	}
	.navbar-logo a{
		text-decoration: none;
		color: #79b7f1;
	}
	.navbar-logo-img{
		width: 75px;
		height: 100%;
		display: flex;
	}
	.navbar-logo-img img{
		height: 90%;
	}
	.navbar-logo-text{
		display: flex;
		align-items: center;
		font-size: 1.5rem;
		font-weight: 700;
	}
	.nlt-melinda{
		color: var(--primary);
	}
	.nlt-hcs{
		color: var(--navy);
	}
	.navbar-menu{
		width: 34%;
		padding-top: 0.7rem;
		transition: 0.2s;
		z-index: 15;
		/* border: 1px solid green; */
	}
	.navbar-menu-bg{
		position: fixed;
		top: 0;
		left: 110%;
		width: 0;
		height: 150%;
		background: #000000;
		opacity: 0.5;
		z-index: 10;
	}
	.navbar-login-form{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 150%;
		background: #58585844;
		z-index: 15;
		display: none;
		padding-top: 5rem;
		font-family: poppins;
	}
	.nlf-inner{
		position: relative;
		width: 37%;
		min-height: 370px;
		background: var(--white);
		margin: auto;
		border-radius: 10px;
		-webkit-animation-name: animatetop;
		-webkit-animation-duration: 0.4s;
		animation-name: animatetop;
		animation-duration: 0.4s;
		transition: 0.2s;
	}
	@-webkit-keyframes animatetop {
		from {top:-300px; opacity:0} 
		to {top:0; opacity:1}
	}
	@keyframes animatetop {
		from {top:-300px; opacity:0}
		to {top:0; opacity:1}
	}
	.ni-head{
		position: relative;
		height: 2rem;
		z-index: 5;
	}
	.nlf-content{
		display: flex;
		width: 100%;
		overflow: hidden;
		text-align: left;
		scroll-behavior: smooth;
		z-index: 1;
	}
	.nlf-inner h2{
		text-align: center;
		margin: 0;
		margin-bottom: 1rem;
	}
	.nlf-part{
		position: relative;
		min-width: 100%;
		width: 100%;
		padding: 1%;
		padding-left: 5%;
		padding-right: 5%;
	}
	.nlf-close{
		position: absolute;
		top: 0;
		right: 0;
		padding: 1rem;
		cursor: pointer;
		transition: 0.2s;
	}
	.nlf-close:hover{
		color: var(--primary-hover);
	}
	.nlf-input{
		position: relative;
		height: 2rem;
		border-bottom: 1px solid lightgrey;
		margin-bottom: 1.5rem;
		text-align: left;
	}
	.nlf-input input{
		height: 100%;
		width: 90%;
		border: none;
		outline: none;
		font-size: 1rem;
		color: #575757;
	}
	.nlf-label{
		position: absolute;
		top: 0;
		left: 0;
		background: var(--white);
		color: #575757;
		font-size: 0.9rem;
		padding: 1%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		height: 100%;
		width: 100%;
		transition: 0.2s;
		text-align: left;
		border-radius: 5px;
		cursor: text;
	}
	.nlf-button{
		text-align: center;
		font-weight: 300;
		margin-bottom: 2rem;
	}
	.nlf-button-text{
		padding: 1%;
		padding-left: 5%;
		padding-right: 5%;
		background: var(--primary);
		color: var(--white);
		cursor: pointer;
	}
	.nlf-button-text:hover{
		background: var(--primary-hover);
	}
	.nlf-link{
		color: var(--primary);
		text-decoration: none;
	}
	.nm-inner{
		display: flex;
		flex-wrap: nowrap;
		background: var(--white);
	}
	.nm-part{
		padding: 0.5rem;
		width: auto;
		margin: auto;
	}
	.nm-part-link{
		text-decoration: none;
		color: #575757;
		transition: 0.2s;
	}
	.nm-part-link:hover{
		color: var(--primary);
	}
	.nm-part-arrow{
		position: relative;
		display: flex;
		justify-content: center;
		color: var(--grey);
	}
	.npa-button{
		display: flex;
		width: 100%;
		font-size: 0.9rem;
	}
	.npa-button-text{
		padding: 0.1rem 0 0 0;
	}
	.npa-button-icon{
		padding: 0 0.5rem 0 0.5rem;
	}
	.npa-button-icon img{
		transition: 0.2s;
	}
	.npa-option{
		position: absolute;
		top: 100%;
		padding: 1rem 0 0 0;
		-webkit-animation-name: animatenpaoption;
		-webkit-animation-duration: 0.2s;
		animation-name: animatenpaoption;
		animation-duration: 0.2s;
		display: none;
		transition: 0.2s;
	}
	.npa-option-active{
		display: block;
	}
	.npa-option-inner{
		min-width: 150px;
		width: auto;
		background: var(--white);
		padding: 0.5rem 0 0.5rem 0;
		border-radius: 5px;
		box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
	}
	@-webkit-keyframes animatenpaoption {
		from {top:200%; opacity:0}
		to {top:100%; opacity:1}
	}
	@keyframes animatenpaoption {
		from {top:200%; opacity:0}
		to {top:100%; opacity:1}
	}
	.npa-option-button{
		text-align: left;
		padding: 0.5rem;
	}
	.npa-option-button a{
		display: block;
		text-decoration: none;
		color: var(--grey);
	}
	.router-link-active{
		color: var(--primary);
	}
	.nm-mobile{
		display: none;
		padding-top: 2rem;
	}
	.nm-mobile a{
		text-decoration: none;
		color: var(--grey);
	}
	.nm-mobile span{
		background: #FCE8CE;
		color: #575757;
		padding: 0.5rem;
		padding-left: 2rem;
		padding-right: 2rem;
		border-radius: 5px;
		transition: 0.2s;
	}
	.nm-mobile-close{
		display: none;
	}
	.nm-mobile-loggedin{
		display: none;
	}
	.navbar-button{
		width: 15%;
		text-align: right;
		padding: 1.2rem;
		padding-right: 0;
		transition: 0.2s;
	}
	.navbar-button a{
		text-decoration: none;
		color: var(--grey);
	}
	.navbar-button span{
		background: #FCE8CE;
		color: #575757;
		padding: 0.75rem;
		padding-left: 2rem;
		padding-right: 2rem;
		border-radius: 2rem;
		transition: 0.2s;
	}
	.navbar-button span:hover{
		background: #f7ce9a;
	}
	.nb-login-loggedin{
		position: relative;
		min-height: 50px;
		cursor: pointer;
	}
	.nll-button{
		background: unset;
		color: #575757;
		border-radius: 0;
		transition: 0.2s;
		font-size: 0.9rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.nll-button:hover{
		background: var(--white);
	}
	.nll-select{
		position: absolute;
		top: 75%;
		right: 0;
		width: 100%;
		background: var(--white);
		color: var(--grey);
		border-radius: 5px;
		padding: 0.5rem;
		text-align: left;
		display: none;
		box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
		animation-name: animatenlloption;
		animation-duration: 0.2s;
		transition: 0.2s;
		overflow: hidden;
	}
	.nll-option{
		cursor: pointer;
		padding: 0.5rem;
		transition: 0.2s;
	}
	.nll-option:hover{
		background: var(--smoke);
	}
	.modal-logout{
		padding: 1rem 25% 1rem 25%;
	}
	.modal-logout-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
	.mlh-close{
		cursor: pointer;
		color: var(--white);
	}
	.mlh-close span{
		background: none;
	}
	.mlh-close span:hover{
		background: none;
	}
    .modal-logout-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
	.mlb-text{
		text-align: center;
	}
	.mlb-button{
		display: flex;
		justify-content: center;
	}
	.mlb-button-part{
		width: 50%;
		padding: 1rem;
	}
	.mlb-button button{
		border: none;
		min-width: 100px;
		padding: 0.5rem 1rem 0.5rem 1rem;
		transition: 0.2s;
		color: var(--white);
	}
	.mbp-yes button{
		background: var(--primary);
	}
	.mbp-yes button:hover{
		background: var(--primary-hover);
	}
	.mbp-no button{
		background: var(--red);
	}
	.mbp-no button:hover{
		background: var(--red-hover);
	}
	.nb-menumob{
		display: none;
		z-index: 100;
	}
	.nb-menumob img{
		max-width: 2rem;
	}
	.stb-active{
		position: fixed;
		top: 5.5rem;
		right: 10%;
		width: 24%;
	}
	.stb-absolute{
		position: absolute;
		right: 0;
		bottom: 1rem;
		width: 100%;
	}
	@media only screen and (min-width: 2501px){
		.nav-pad{
			padding-left: 16rem;
			padding-right: 16rem;
		}
	}
	@media only screen and (max-width: 1500px){
		@-webkit-keyframes animatenotification {
			from {right:-100%; opacity:0}
			to {right:5%; opacity:1}
		}
		@keyframes animatenotification {
			from {right:-100%; opacity:0}
			to {right:5%; opacity:1}
		}
		.nav-pad{
			padding-left: 5%;
			padding-right: 5%;
		}
		.navbar-logo{
			width: 40%;
		}
		.navbar-logo-img img{
			height: 75%;
		}
		.navbar-menu{
			width: 48%;
		}
		.navbar-button{
			width: 12%;
		}
		.stb-active{
			right: 5%;
			width: 27%;
		}
	}
	@media only screen and (max-width: 1200px){
		.nlf-inner{
			width: 50%;
		}
	}
	@media only screen and (max-width: 1050px){
		html{
			display: inline;
		}
		.navbar{
			font-size: 75%;
		}
		.navbar-logo-img img{
			height: 71%;
		}
		.navbar-button span{
			padding: 0.5rem;
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.stb-active{
			position: relative;
			width: 100%;
			top: 0;
			right: 0;
		}
	}
	@media only screen and (max-width: 750px){
		.nav-pad{
			padding-top: 0.75rem;
			padding-left: 2.5%;
			padding-right: 2.5%;
		}
		.navbar-logo{
			width: 23%;
		}
		.navbar-menu{
			position: fixed;
			top: 0;
			left: 110%;
			width: 75%;
			height: 150%;
			transition: 0.2s;
			text-align: left;
			padding-left: 1%;
			padding-right: 15%;
			font-size: 1rem;
			background: var(--white);
		}
		.nm-mobile-close{
			display: block;
			position: absolute;
			top: 1rem;
			right: 4.1%;
			width: 1.5rem;
			height: 1.5rem;
		}
		.nm-mobile-close img{
			width: 100%;
			max-width: 1.5rem;
			height: 100%;
		}
		.navbar-menu-bg{
			left: 0;
		}
		.navbar-login-form{
			padding-top: 0;
		}
		.nlf-inner{
			width: 100%;
			height: 100%;
			border-radius: 0;
		}
		.nm-inner{
			display: inline;
		}
		.nm-part-arrow{
			display: inline;
			justify-content: left;
		}
		.npa-option{
			position: relative;
			display: block;
			height: 0;
			overflow: hidden;
			padding: 0 0 0 0;
		}
		.npa-option-inner{
			min-width: unset;
			width: 100%;
			box-shadow: unset;
			padding: 0 0 0 0;
			border: 1px solid var(--softgrey);
			margin: 1rem 0 1rem 0;
		}
		.nm-mobile{
			display: block;
		}
		.nm-mobile-loggedin{
			display: block;
		}
		.nm-mobile img{
			width: 1.5rem;
		}
		.navbar-button{
			width: 77%;
			padding: 1rem;
			padding-top: 0.5rem;
			padding-right: 0;
		}
		.nb-login{
			display: none;
		}
		.nb-login-loggedin{
			display: none;
		}
		.modal-logout{
			padding: 0;
		}
		.modal-logout-body{
			height: 100%;
		}
		.nb-menumob{
			display: block;
		}
	}
	/* content */
	.content{
		padding: 4.81rem 0 0 0;
		min-height: 500px;
	}
	/* footer */
	footer{
		position: relative;
		background: var(--white);
	}
	.footer-top{
		display: flex;
		flex-wrap: wrap;
		padding-left: 10%;
		padding-right: 10%;
		background: #FEF8F0;
		height: 10.25rem;
		transition: 0.2s;
	}
	.staff{
		width: 60%;
		display: flex;
		padding-top: 3.87rem;
		transition: 0.2s;
	}
	.staff-icon{
		position: relative;
		min-width: 100px;
		width: 19%;
		max-width: 110px;
		min-height: 75px;
	}
	.staff-icon img{
		position: absolute;
		width: 55%;
	}
	.staff-icon-phone{
		top: 0;
		left: 0;
		z-index: 1;
	}
	.staff-icon-male{
		top: 0;
		right: 0;
		z-index: 0;
	}
	.staff-text{
		font-weight: 600;
		padding-left: 3.6rem;
		color: #1B4674;
		transition: 0.2s;
	}
	.staff-text p{
		margin: 0;
	}
	.sosign{
		width: 40%;
		display: flex;
		flex-wrap: wrap;
		justify-content: right;
	}
	.socials{
		width: 50%;
		display: flex;
		justify-content: right;
		padding-top: 3.87rem;
		transition: 0.2s;
	}
	.socials-icon{
		margin: 0.5rem;
		height: 1.9rem;
	}
	.socials-icon img{
		height: 100%;
	}
	.signup{
		width: 50%;
		display: flex;
		justify-content: right;
		align-items: center;
	}
	.signup-button button{
		border: none;
		border-radius: 5rem;
		background: var(--primary);
		color: var(--white);
		padding: 0.5rem 1rem 0.5rem 1rem;
		cursor: pointer;
		user-select: none;
	}
	.modal{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #51525369;
		z-index: 17;
		display: none;
		/* overflow: auto; */
	}
	.modal-active{
		display: block;
	}
	.signup-modal{
		padding: 1rem 25% 5rem 25%;
		transition: 0.2s;
	}
	.signup-modal-head{
		position: absolute;
		top: 0;
		right: 0;
		color: var(--white);
		padding: 1rem;
		z-index: 19;
	}
	.smh-close{
		cursor: pointer;
	}
	.signup-modal-body{
		position: relative;
		animation-name: animatesignup;
		animation-duration: 0.5s;
		transition: 0.2s;
	}
	.smb-inner{
		background: var(--white);
		color: var(--grey);
		min-height: 200px;
		width: 100%;
		padding: 1rem;
		border-radius: 10px;
	}
	.smb-form-input{
		width: 100%;
		margin-bottom: 1rem;
	}
	.smb-form-input input{
		width: 100%;
        border: none;
        border-bottom: 1px solid #D3D3D3;
        outline: none;
        padding: 1% 0 1% 0;
        color: var(--grey);
	}
	.smb-form-input textarea{
		width: 100%;
		min-height: 100px;
		outline: none;
		border: 1px solid var(--softgrey);
		padding: 1%;
		color: var(--grey);
	}
	.smb-form-input button{
		background: var(--primary);
		color: var(--white);
		border: none;
		padding: 0.5rem 1rem 0.5rem 1rem;
		transition: 0.2s;
		cursor: pointer;
		user-select: none;
	}
	.smb-form-input button:hover{
		background: var(--primary-hover);
	}
	.smb-confirmation{
		text-align: center;
	}
	.sfi-button-multi{
		width: 100%;
		display: flex;
		justify-content: center;
		overflow: auto;
	}
	.sbm-part{
		min-width: 100px;
		display: flex;
		justify-content: center;
	}
	.sbm-part-yes button{
		background: var(--primary);
	}
	.sbm-part-yes button:hover{
		background: var(--primary-hover);
	}
	.sbm-part-no button{
		background: var(--red);
	}
	.sbm-part-no button:hover{
		background: var(--red-hover);
	}
	.sfi-label{
		font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
	}
	.sfi-select{
        position: relative;
        color: var(--grey);
        font-size: 0.9rem;
        user-select: none;
    }
    .sfi-select-button{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        background: var(--smoke);
        padding: 1rem;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
    }
    .btn-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .ssb-text{
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .float-icon{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .float-icon img{
        transition: 0.2s;
    }
    .float-icon-active img{
        transform: rotate(180deg);
    }
    .sfi-select-option{
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        min-height: 50px;
        display: none;
        background: var(--white);
        border: 2px solid var(--smoke);
        overflow: auto;
        z-index: 5;
    }
	.sso-search{
		position: relative;
		width: 100%;
		height: 50px;
		padding: 0.5rem;
	}
	.sso-search input{
		border: 1px solid var(--softgrey);
		border-radius: 5px;
		width: 100%;
		height: 100%;
		padding: 1%;
	}
	.sso-search-inner{
        width: 100%;
        height: 100%;
        display: flex;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .sso-search-input{
        width: 90%;
    }
    .sso-search-input input{
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        outline: none;
        padding: 1%;
    }
    .sso-search-close{
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
    }
    .ssc-button{
        width: 20px;
        height: 20px;
        display: none;
        justify-content: right;
        align-items: center;
        line-height: 1;
    }
    .float-active{
        display: block;
    }
	.sso-notif{
		padding: 0.5rem 1rem 0.5rem 1rem;
		color: var(--primary);
		font-size: 0.75rem;
		font-style: italic;
	}
	.sso-time{
		display: flex;
	}
	.sso-button-list{
		max-height: 200px;
		overflow: auto;
	}
	.sbl-time::-webkit-scrollbar{
		display: none;
	}
    .sso-button{
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
        cursor: pointer;
        user-select: none;
    }
    .sso-button:hover{
        background: var(--smoke);
    }
	.sso-button-daytime{
		cursor: auto;
		height: auto;
	}
	.sso-button-daytime:hover{
		background: var(--white);
	}
	.sso-button-schedule{
		display: flex;
		width: 100%;
		border-bottom: 1px solid var(--softgrey);
	}
	.sbl-note{
		font-size: 0.75rem;
		font-style: italic;
		color: var(--softgrey);
		padding: 0.5rem 1rem 0.5rem 1rem;
	}
	.sbs-day{
		width: 30%;
	}
	.sbs-clock{
		width: 70%;
		text-align: center;
	}
	.sbs-clock-list{
		padding: 0.5rem;
	}
	.scl-inner{
		cursor: pointer;
		background: var(--primary);
		color: var(--white);
		padding: 0.5rem;
		transition: 0.2s;
	}
	.scl-inner:hover{
		background: var(--primary-hover);
	}
	.scl-inner-appointment{
		font-style: italic;
		font-size: 0.75rem;
	}
    .sso-note{
        margin: 1rem 0 1rem 0;
        text-align: center;
        font-size: 1rem;
        font-family: roboto;
        font-weight: 300;
        font-style: italic;
        color: var(--softgrey);
    }
	.sfi-input-more{
		padding: 1rem 0 1rem 0;
		font-size: 0.75rem;
	}
	.sfi-input-more span{
		cursor: pointer;
		user-select: none;
	}
	.footer-middle{
		display: flex;
		flex-wrap: wrap;
		height: 14.69rem;
		width: 100%;
		padding-left: 10%;
		padding-right: 10%;
		transition: 0.2s;
	}
	.fm-logo{
		width: 40%;
		padding-top: 5.94rem;
		transition: 0.2s;
	}
	.fm-text{
		display: flex;
		flex-wrap: wrap;
		width: 60%;
		padding-top: 5.37rem;
		color: #1C4677;
		transition: 0.2s;
	}
	.fm-office{
		width: 50%;
		padding-left: 1rem;
		padding-right: 1rem;
		transition: 0.2s;
	}
	.fm-contact{
		width: 50%;
		transition: 0.2s;
	}
	.fm-text h2{
		font-size: 1.125rem;
		font-weight: 600;
		margin: 0;
		margin-bottom: 1rem;
	}
	.fm-text p{
		margin: 0;
		margin-top: 0.2rem;
		font-weight: 400;
		font-size: 1rem;
		transition: 0.2s;
	}
	.footer-bottom{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		height: 8.0625rem;
		padding-left: 10%;
		padding-right: 10%;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		transition: 0.2s;
	}
	.footer-bottom p{
		font-size: 0.9rem;
		transition: 0.2s;
	}
	.fb-left{
		width: 50%;
		padding-top: 1.25rem;
		color: #1C4677;
		transition: 0.2s;
	}
	.fb-right{
		position: relative;
		display: flex;
		justify-content: center;
		width: 50%;
		transition: 0.2s;
	}
	.fbr-medals{
		margin-top: 2.0625rem;
	}
	.fbr-text{
		margin-top: 2.5rem;
		margin-left: 1.3125rem;
		transition: 0.2s;
		font-size: 0.9rem;
	}
	.fbr-text a{
		text-decoration: none;
		color: #1C4677;
	}
	.cookies-link{
		margin-left: 1rem;
	}
	.notification{
		display: none;
		position: fixed;
		top: 1rem;
		right: 10%;
		background: rgb(76, 155, 230);
		color: var(--white);
		min-width: 200px;
		min-height: 50px;
		border-radius: 5px;
		padding: 0.5rem;
		box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
		user-select: none;
		animation-name: animatenotification;
		animation-duration: 0.5s;
		z-index: 100;
		font-family: sans-serif;
		font-size: 0.9rem;
		font-style: italic;
	}
	.chat-box{
		position: fixed;
		right: 10%;
		bottom: 5%;
		background: var(--white);
		box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		z-index: 15;
		transition: 0.2s;
	}
	.chatbox-active{
		height: 500px;
	}
	.chat-box-button{
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		user-select: none;
		height: 75px;
		width: 370px;
	}
	.cbb-chaticon{
		width: 15%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem;
	}
	.cbb-chaticon img{
		width: 100%;
	}
	.cbb-text{
		width: 75%;
		text-align: center;
	}
	.cbb-icon{
		width: 10%;
		text-align: center;
	}
	.chat-box-body{
		width: 370px;
		overflow: hidden;
		display: none;
	}
	.cbody-active{
		display: block;
	}
	.chat-box-stage{
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		border-top: 1px solid var(--softgrey);
		height: 450px;
	}
	.cbs-part{
		height: 100%;
	}
	.chat-box-select{
		min-width: 100%;
		padding: 0.5rem;
	}
	.chat-box-option{
		margin-bottom: 0.5rem;
		cursor: pointer;
		transition: 0.2s;
		padding: 0.5rem;
		user-select: none;
	}
	.chat-box-option:hover{
		background: var(--smoke);
	}
	.cbs-doctor{
		min-width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.cbs-doctor-inner{
		padding: 0.5rem;
		height: 100%;
		overflow: auto;
		background: var(--primary);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.cbs-doctor-head{
		height: 7.5%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.cbs-doctor-body{
		height: 92.5%;
	}
	.cbs-back{
		cursor: pointer;
	}
	.cbs-back img{
		transform: rotate(90deg);
	}
	.cdb-inner{
		height: 100%;
	}
	.cdb-tab{
		height: 100%;
	}
	.cdb-tab-head{
		display: flex;
		height: 15%;
		overflow: auto;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		align-items: center;
	}
	.cdb-tab-head::-webkit-scrollbar{
		display: none;
	}
	.cth-inner{
		min-width: 100px;
		height: 37px;
		border-radius: 5px;
		padding: 0.15rem;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
		align-items: center;
		font-size: 0.9rem;
		margin-right: 0.5rem;
		cursor: pointer;
		user-select: none;
		background: var(--white);
		color: var(--grey);
		box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
	}
	.cth-inner-active{
		background: var(--primary);
		color: var(--white);
	}
	.cth-inner-text{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.cdb-tab-body{
		display: flex;
		height: 85%;
	}
	.ctb-inner{
		position: relative;
		display: none;
		padding-top: 0.5rem;
		height: 100%;
		min-width: 100%;
	}
	.ctb-inner-active{
		display: block;
	}
	.ctb-screen{
		height: 75%;
		padding: 0.5rem;
		overflow: auto;
		scroll-behavior: smooth;
	}
	.ctb-screen-board{
		display: flex;
	}
	.csboard-you{
		justify-content: right;
		text-align: left;
	}
	.csboard-they{
		justify-content: left;
		text-align: right;
	}
	.csboard-inner{
		background: var(--white);
		box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 5px 0 rgba(0, 0, 0, 0.15);
		border: 1px solid var(--smoke);
		border-radius: 5px;
		margin-top: 0.5rem;
		padding: 0.5rem;
		overflow-wrap: break-word;
		min-width: 37%;
		max-width: 90%;
	}
	.csb-sender{
		font-size: 0.75rem;
		font-style: italic;
	}
	.csb-sender-you{
		color: rgb(14, 147, 209);
	}
	.csb-sender-they{
		color: rgb(126, 199, 159);
	}
	.csb-message{
		font-size: 0.9rem;
		color: var(--grey);
	}
	.csb-report-you{
		text-align: right;
	}
	.csb-report-they{
		text-align: left;
	}
	.csb-sender-time{
		font-size: 0.67rem;
		color: var(--softgrey);
	}
	.csb-sender-check{
		font-size: 0.75rem;
		color: var(--grey);
		padding-left: 0.5rem;
	}
	.csc-clock img{
		max-width: 10px;
	}
	.csc-one img{
		max-width: 10px;
	}
	.ctb-field{
		position: absolute;
		left: 0;
		bottom: 0;
		display: flex;
		height: 15%;
		width: 100%;
		padding-top: 0.5rem;
		padding-left: 0.5rem;
		padding-right: 1rem;
	}
	.ctb-field p{
		margin: 0;
		font-size: 0.9rem;
	}
	.ctb-field-input{
		width: 85%;
		padding-right: 0.5rem;
	}
	.cfi-inner{
		height: 100%;
		width: 100%;
		border: 1px solid var(--softgrey);
		border-radius: 100px;
		overflow: hidden;
		padding: 0.5rem;
		background: var(--white);
	}
	.ctb-field-input input{
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		padding: 1%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.ctb-field-button{
		width: 15%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--grey);
		color: var(--white);
		font-size: 0.9rem;
		cursor: pointer;
		user-select: none;
		padding: 0.5rem;
	}
	.ctb-field-unpaid{
		justify-content: center;
		height: auto;
		padding: 0.5rem;
		background: var(--white);
		border: 1px solid var(--softgrey);
		border-radius: 5px;
	}
	.ctb-field-unpaid p{
		font-style: italic;
		color: red;
		text-align: center;
	}
	.ctb-field-paid{
		justify-content: center;
		height: auto;
		padding: 0.5rem;
		background: var(--white);
		border: 1px solid var(--softgrey);
		border-radius: 5px;
	}
	.ctb-field-paid p{
		font-style: italic;
		color: navy;
		text-align: center;
	}
	.ctb-field-done{
		justify-content: center;
		height: auto;
		padding: 0.5rem;
		background: var(--white);
		border: 1px solid var(--softgrey);
		border-radius: 5px;
	}
	.ctb-field-done p{
		font-style: italic;
		color: navy;
		text-align: center;
	}
	.cfd-inner{
		position: relative;
		height: 100%;
		overflow: hidden;
	}
	.rate-button{
		display: flex;
		justify-content: center;
		padding-top: 0.5rem;
	}
	.rate-button button{
		padding: 0.5rem 1rem 0.5rem 1rem;
		background: var(--primary);
		color: var(--white);
		border: none;
	}
	.go-top{
		display: flex;
		position: fixed;
		right: 1%;
		bottom: -15%;
		width: 75px;
		height: 75px;
		background: #a1a2a359;
		color: #515253;
		justify-content: center;
		align-items: center;
		z-index: 19;
		border-radius: 5px;
		cursor: pointer;
		transition: 0.2s;
		user-select: none;
	}
	.go-top img{
		transform: rotate(180deg);
	}
	@media only screen and (max-width: 1650px){
		.fm-logo{
			width: 26%;
		}
		.fm-text{
			width: 74%;
		}
	}
	@media only screen and (max-width: 1500px){
		.footer-top{
			padding-left: 5%;
			padding-right: 5%;
		}
		.footer-middle{
			padding-left: 5%;
			padding-right: 5%;
		}
		.footer-bottom{
			padding-left: 5%;
			padding-right: 5%;
		}
		.notification{
			right: 5%;
		}
		.chat-box{
			right: 7.5%;
		}
		.go-top{
			width: 50px;
			height: 50px;
			right: 1%;
		}
	}
	@media only screen and (max-width: 1015px){
		.footer-top{
			height: auto;
		}
		.staff{
			width: 100%;
		}
		.sosign{
			width: 100%;
			padding: 0 0 1rem 0;
		}
		.socials{
			justify-content: left;
			align-items: center;
			padding: 0;
		}
	}
	@media only screen and (max-width: 750px){
		.cbs-doctor-body{
			min-height: 666px;
		}
		.staff{
			padding-top: 2.5rem;
		}
		.staff-text{
			padding-left: 5%;
		}
		.footer-top{
			padding-left: 2.5%;
			padding-right: 2.5%;
		}
		.signup-modal{
			padding: 0;
		}
		.smh-close{
			color: var(--navy);
		}
		.signup-modal-body{
			height: 100%;
		}
		.smb-inner{
			border-radius: 0;
			height: 100%;
			overflow: auto;
			padding: 2.5rem 1rem 1rem 1rem;
		}
		.footer-middle{
			height: auto;
			padding-left: 2.5%;
			padding-right: 2.5%;
		}
		.fm-logo{
			width: 100%;
			padding-top: 2rem;
		}
		.fm-text{
			width: 100%;
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
		.fm-text p{
			font-size: 0.9rem;
		}
		.fm-office{
			width: 100%;
			padding-left: 0;
		}
		.fm-contact{
			width: 100%;
			margin-top: 2rem;
		}
		.footer-bottom{
			height: 10rem;
			width: 100%;
			padding-left: 2.5%;
			padding-right: 2.5%;
		}
		.footer-bottom p{
			font-size: 0.75rem;
		}
		.fb-left{
			width: 100%;
			padding-top: 2rem;
		}
		.fb-left p{
			margin: 0;
		}
		.fb-right{
			width: 100%;
			justify-content: left;
		}
		.fbr-text{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			margin-top: 0;
			margin-left: 0;
			font-size: 0.75rem;
		}
		.notification{
			right: 2.5%;
		}
		.go-top{
			right: 2.5%;
			border-radius: 50px;
		}
	}
	@media only screen and (max-width: 370px){
		.socials{
			width: 100%;
			padding: 0.5rem 0 0.5rem 0;
		}
		.signup{
			width: 100%;
			justify-content: left;
			padding: 0.5rem 0 0.5rem 0;
		}
	}
</style>