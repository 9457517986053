<template>
    <div class="si-body">
        <div class="si-body-tab">
            <div class="sbt-body">
                <div class="sbt-body-part sbp-active" id="sbp-all">
                    <!-- <div class="sbp-filter">
                        <h2>Filter</h2>
                        <div class="sbp-filter-board">
                            <div class="sfb-part sfb-part-from">
                                <h3>Dari Tanggal</h3>
                                <div class="date-loader"></div>
                            </div>
                            <div class="sfb-part sfb-part-to">
                                <h3>Hingga Tanggal</h3>
                                <div class="date-loader"></div>
                            </div>
                            <div class="sfb-part sfb-part-button">
                                <div class="spb-inner">
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="sbp-content">
                        <div class="sbi-table">
                            <div class="sbi-table-fixed">
                                <div class="sbi-table-head stf-head">
                                    <div class="shp-num">
                                        <span>No</span>
                                    </div>
                                    <div class="stf-head-part">
                                        <span>Nama Dokumen</span>
                                    </div>
                                </div>
                                <div class="sbi-table-body">
                                    <div :class="'stb-row stb-row-' + (index % 2)" v-for="(day, index) in 30" :key="day">
                                        <div class="sbp-num">
                                            <span>{{ index + 1 }}</span>
                                        </div>
                                        <div class="stf-body-part skeleton-text">
                                            <p class="st-75"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbi-table-scroll" @scroll="tableScroll">
                                <div class="sbi-table-head">
                                    <div class="sts-head-part">
                                        <span>Divisi</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Kategori</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Tanggal</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Status</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Action</span>
                                    </div>
                                </div>
                                <div class="sbi-table-body">
                                    <div class="stb-row" v-for="(day, index) in 30" :key="day">
                                        <div :class="'sts-row-part skeleton-text srp-' + (index % 2)">
                                            <p class="st-50"></p>
                                        </div>
                                        <div :class="'sts-row-part skeleton-text srp-' + (index % 2)">
                                            <p class="st-50"></p>
                                        </div>
                                        <div :class="'sts-row-part skeleton-text srp-' + (index % 2)">
                                            <p class="st-50"></p>
                                        </div>
                                        <div :class="'sts-row-part skeleton-text srp-' + (index % 2)">
                                            <p class="st-50"></p>
                                        </div>
                                        <div :class="'sts-row-part skeleton-text srp-' + (index % 2)">
                                            <p class="st-50"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sbt-body-part" id="sbp-transfer">
                    <div class="sbp-content" v-if="transfer?.length > 0">
                        <div class="sbi-table">
                            <div class="sbi-table-fixed">
                                <div class="sbi-table-head stf-head">
                                    <div class="shp-num">
                                        <span>No</span>
                                    </div>
                                    <div class="stf-head-part">
                                        <span>Tanggal</span>
                                    </div>
                                </div>
                                <div class="sbi-table-body">
                                    <div :class="'stb-row stb-row-' + (index % 2)" v-for="(day, index) in transfer" :key="day">
                                        <div class="sbp-num">
                                            <span>{{ index + 1 }}</span>
                                        </div>
                                        <div class="stf-body-part">
                                            <span>{{ day.schedule.workday.date }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbi-table-scroll" @scroll="tableScroll">
                                <div class="sbi-table-head">
                                    <div class="sts-head-part">
                                        <span>Tujuan</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Shift</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Jam Mulai</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Jam Selesai</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Action</span>
                                    </div>
                                </div>
                                <div class="sbi-table-body">
                                    <div class="stb-row" v-for="(day, index) in transfer" :key="day">
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <span>{{ day.employe_target.name }}</span>
                                        </div>
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <span>{{ day.shift.name }}</span>
                                        </div>
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <span v-if="day.shift?.time_start">{{ day.shift.time_start }}</span>
                                            <span v-else>-</span>
                                        </div>
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <span v-if="day.shift?.time_end">{{ day.shift.time_end }}</span>
                                            <span v-else>-</span>
                                        </div>
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <div class="srp-status" v-if="day.status == 0">
                                                <div class="srp-button">
                                                    <span @click="showModal('modal-sxl-' + index)">Batalkan</span>
                                                </div>
                                                <div class="modal srp-modal" :id="'modal-sxl-' + index">
                                                    <div class="srp-modal-head">
                                                        <div class="smh-close" @click="hideModal('modal-sxl-' + index)">
                                                            <span>x</span>
                                                        </div>
                                                    </div>
                                                    <div class="srp-modal-body">
                                                        <div class="smb-form">
                                                            <p>Batalkan ?</p>
                                                            <div class="smb-form-input">
                                                                <div class="sfi-button" v-if="!loader">
                                                                    <button>Ya</button>
                                                                </div>
                                                                <div class="sfi-button" v-else>
                                                                    <button>Loading.....</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <span v-if="day.status == 1">Diterima</span>
                                                <span v-else>Ditolak</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sbp-content" v-else>
                        <div class="sbp-content-note">
                            <p>Tidak ada transfer jadwal</p>
                        </div>
                    </div>
                </div>
                <div class="sbt-body-part" id="sbp-request">
                    <div class="sbp-content" v-if="request?.length > 0">
                        <div class="sbi-table">
                            <div class="sbi-table-fixed">
                                <div class="sbi-table-head stf-head">
                                    <div class="shp-num">
                                        <span>No</span>
                                    </div>
                                    <div class="stf-head-part">
                                        <span>Tanggal</span>
                                    </div>
                                </div>
                                <div class="sbi-table-body">
                                    <div :class="'stb-row stb-row-' + (index % 2)" v-for="(day, index) in request" :key="day">
                                        <div class="sbp-num">
                                            <span>{{ index + 1 }}</span>
                                        </div>
                                        <div class="stf-body-part">
                                            <span>{{ day.schedule.workday.date }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sbi-table-scroll" @scroll="tableScroll">
                                <div class="sbi-table-head">
                                    <div class="sts-head-part">
                                        <span>Dari</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Shift</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Jam Mulai</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Jam Selesai</span>
                                    </div>
                                    <div class="sts-head-part">
                                        <span>Action</span>
                                    </div>
                                </div>
                                <div class="sbi-table-body">
                                    <div class="stb-row" v-for="(day, index) in request" :key="day">
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <span>{{ day.employe_request.name }}</span>
                                        </div>
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <span>{{ day.shift.name }}</span>
                                        </div>
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <span v-if="day.shift?.time_start">{{ day.shift.time_start }}</span>
                                            <span v-else>-</span>
                                        </div>
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <span v-if="day.shift?.time_end">{{ day.shift.time_end }}</span>
                                            <span v-else>-</span>
                                        </div>
                                        <div :class="'sts-row-part srp-' + (index % 2)">
                                            <div class="srp-status" v-if="day.status == 0">
                                                <div class="srp-button">
                                                    <span @click="showModal('modal-sxr-' + index)">Lihat Detail</span>
                                                </div>
                                                <div class="modal srp-modal" :id="'modal-sxr-' + index">
                                                    <div class="srp-modal-head">
                                                        <div class="smh-close" @click="hideModal('modal-sxr-' + index)">
                                                            <span>x</span>
                                                        </div>
                                                    </div>
                                                    <div class="srp-modal-body">
                                                        <div class="smb-form">
                                                            <div class="smb-confirmation">
                                                                <p>{{ day.description }}</p>
                                                            </div>
                                                            <div class="smb-form-input">
                                                                <div class="sfi-button">
                                                                    <div class="sfi-button-multi" v-if="!loader">
                                                                        <div class="sbm-part sbm-part-yes">
                                                                            <button>Terima</button>
                                                                        </div>
                                                                        <div class="sbm-part sbm-part-no">
                                                                            <button>Tolak</button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="sfi-button-multi" v-else>
                                                                        <div class="sbm-part">
                                                                            <p>Loading.....</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <span v-if="day.status == 1">Diterima</span>
                                                <span v-else>Ditolak</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sbp-content" v-else>
                        <div class="sbp-content-note">
                            <p>Tidak ada konfirmasi</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { 
        showModal, 
        hideModal
    } from '@/functions/general';
    const transfer = null;
    const request = null;
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        transition: 0.2s;
    }
    .si-head h1{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        transition: 0.2s;
    }
    .si-body-tab{
        border: 1px solid var(--softgrey);
    }
    .sbt-head{
        width: 100%;
        display: flex;
        background: var(--softgrey);
        overflow: auto;
    }
    .sbt-head::-webkit-scrollbar{
        display: none;
    }
    .sbt-head-part{
        min-width: 100px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 1rem 0;
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
    .shp-active{
        color: var(--primary);
        background: var(--white);
    }
    .sbt-body-part{
        display: none;
    }
    .sbp-active{
        display: block;
    }
    /* filter starts */
    .sbp-filter{
        padding: 1rem;
        transition: 0.2s;
    }
    .sbp-filter h2{
        margin: 1rem 0 1rem 0;
        padding: 0 0.5rem 0 0.5rem;
        font-size: 1.2rem;
        transition: 0.2s;
    }
    .sbp-filter-board{
        width: 75%;
        display: flex;
        flex-wrap: wrap;
        user-select: none;
        transition: 0.2s;
    }
    .sfb-part{
        width: 33.33%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sfb-part h3{
        margin: 0 0 1rem 0;
        font-size: 1.1rem;
        transition: 0.2s;
    }
    .date-loader{
        width: 100%;
        height: 35px;
        animation: pulse-bg 1s infinite;
    }
    /* .sfb-part input{
        width: 100%;
        height: 35px;
        border: 1px solid var(--softgrey);
        outline: none;
        padding: 0.5rem;
    } */
    .sfb-part-button{
        padding: 0 0 0.5rem 0.5rem;
        transition: 0.2s;
    }
    .spb-inner{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: end;
    }
    .spb-inner button{
        border: none;
        color: var(--white);
        width: 100%;
        height: 35px;
        transition: 0.2s;
    }
    .spb-reset{
        width: 50%;
        padding: 0 0.25rem 0 0;
    }
    .spb-reset button{
        background: var(--grey);
    }
    .spb-reset button:hover{
        background: var(--grey-hover);
    }
    .spb-filter{
        width: 50%;
        padding: 0 0 0 0.25rem;
    }
    .spb-filter button{
        background: var(--primary);
    }
    .spb-filter button:hover{
        background: var(--primary-hover);
    }
    /* filter ends */
    .sbp-content{
        padding: 1rem 0 0 0;
    }
    .sbp-content-note{
        text-align: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .sbi-table{
        width: 100%;
        display: flex;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 75px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-0{
        background: var(--smoke);
    }
    .sts-row-part{
        min-width: 75px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* text-align: center; */
    }
    .srp-0{
        background: var(--smoke);
    }
    .srp-button{
        text-align: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .srp-modal{
        padding: 1rem 25% 1rem 25%;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .sbp-filter-board{
            width: 100%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        /* filter starts */
        .sbp-filter{
            padding: 1rem 2.5% 1rem 2.5%;
        }
        .sbp-filter h2{
            padding: 0;
            font-size: 1.1rem;
        }
        .sfb-part{
            width: 50%;
        }
        .sfb-part h3{
            font-size: 0.9rem;
        }
        .sfb-part-from{
            padding: 0 0.25rem 0.5rem 0;
        }
        .sfb-part-to{
            padding: 0 0 0.5rem 0.25rem;
        }
        .sfb-part-button{
            width: 100%;
            padding: 0 0 0.5rem 0;
        }
        /* filter ends */
        .si-head h1{
            font-size: 1.5rem;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 0;
        }
        .srp-modal-body{
            height: 100%;
        }
    }
</style>