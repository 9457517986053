<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <div class="si-head-inner">
                    <!-- <div class="data-baru">
                        <div class="data-baru-button">
                            <button @click="showModal('data-baru-modal');">Ajukan Perjalanan Dinas</button>
                        </div>
                        <div class="modal srp-modal" id="data-baru-modal">
                            <div class="srp-modal-head">
                                <div class="smh-close" @click="hideModal('data-baru-modal')">
                                    <span>x</span>
                                </div>
                            </div>
                            <div class="srp-modal-body">
                                <div class="smb-form">
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Tanggal Mulai</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="date" id="input-data-mulai"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Tanggal Selesai</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="date" id="input-data-selesai"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Keterangan</span>
                                        </div>
                                        <div class="sfi-input">
                                            <textarea id="input-data-keterangan"></textarea>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Lampiran</span>
                                        </div>
                                        <div class="sfi-input" :id="'flb-attachments'">
                                            <small><i>maksimal ukuran file adalah 1 MB</i></small>
                                            <div>
                                                <input type="file"/>
                                            </div>
                                        </div>
                                        <div class="sfi-input-more">
                                            <span @click="inputLampiran('flb-attachments')">+ tambah lampiran</span>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-button" v-if="!loader">
                                            <button @click="newData();">Submit</button>
                                        </div>
                                        <div class="sfi-button" v-else>
                                            <button>Loading.....</button>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="si-body">
                <div class="si-body-tab">
                    <div class="sbt-head">
                        <div class="sbt-head-button shb-active" id="shb-dinas" @click="showTab('sbt-head-button', 'shb-dinas', 'sbt-body-content', 'sbc-dinas', 'shb-active', 'sbc-active');">
                            <span>Dinas</span>
                        </div>
                        <div class="sbt-head-button" id="shb-sakit" @click="showTab('sbt-head-button', 'shb-sakit', 'sbt-body-content', 'sbc-sakit', 'shb-active', 'sbc-active');">
                            <span>Sakit</span>
                        </div>
                        <div class="sbt-head-button" id="shb-tugas" @click="showTab('sbt-head-button', 'shb-tugas', 'sbt-body-content', 'sbc-tugas', 'shb-active', 'sbc-active');">
                            <span>Tugas</span>
                        </div>
                        <div class="sbt-head-button" id="shb-btabsen" @click="showTab('sbt-head-button', 'shb-btabsen', 'sbt-body-content', 'sbc-btabsen', 'shb-active', 'sbc-active');">
                            <span>Bantuan Absen</span>
                        </div>
                    </div>
                    <div class="sbt-body">
                        <div class="sbt-body-content sbc-active" id="sbc-dinas" v-if="dinas">
                            <div class="si-body-inner" v-if="dinas.length > 0">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Nama</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(dt, index) in dinas" :key="dt">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <span>{{ dt.employe?.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Mulai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Selesai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Status</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Keterangan</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(dt, index) in dinas" :key="dt">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.date_start }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.date_end }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.status_string }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.description }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-accordion" v-if="dt.status < 1">
                                                        <div class="srp-accordion-button" :id="'sab-dinas-action-' + index" @click="actionToggle('sab-dinas-action-' + index)">
                                                            <div class="sab-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="arrow"/>
                                                            </div>
                                                        </div>
                                                        <div class="srp-accordion-options">
                                                            <div class="sao-list">
                                                                <div class="">
                                                                    <span @click="showModal('modal-dinasacc-' + index)">Setujui</span>
                                                                </div>
                                                                <div class="modal srp-modal" :id="'modal-dinasacc-' + index">
                                                                    <div class="srp-modal-head">
                                                                        <div class="smh-close" @click="hideModal('modal-dinasacc-' + index)">
                                                                            <span>x</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="srp-modal-body">
                                                                        <div class="smb-form">
                                                                            <p>Setujui permintaan ini ?</p>
                                                                            <div class="smb-form-input">
                                                                                <div class="sfi-button" v-if="!loader">
                                                                                    <button @click="settleDinas(dt.id, 1);">Setujui</button>
                                                                                </div>
                                                                                <div class="sfi-button" v-else>
                                                                                    <button>Loading.....</button>
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="sao-list">
                                                                <div class="">
                                                                    <span @click="showModal('modal-dinasdeny-' + index)">Tolak</span>
                                                                </div>
                                                                <div class="modal srp-modal" :id="'modal-dinasdeny-' + index">
                                                                    <div class="srp-modal-head">
                                                                        <div class="smh-close" @click="hideModal('modal-dinasdeny-' + index)">
                                                                            <span>x</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="srp-modal-body">
                                                                        <div class="smb-form">
                                                                            <p>Tolak permintaan ini ?</p>
                                                                            <div class="smb-form-input">
                                                                                <div class="sfi-button" v-if="!loader">
                                                                                    <button @click="settleDinas(dt.id, 0);">Tolak</button>
                                                                                </div>
                                                                                <div class="sfi-button" v-else>
                                                                                    <button>Loading.....</button>
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="si-body-inner sso-note" v-else>
                                <p>Tidak ada data</p>
                            </div>
                        </div>
                        <ApprovalSkeleton id="sbc-dinas" v-else/>
                        <div class="sbt-body-content" id="sbc-sakit" v-if="sakit">
                            <div class="si-body-inner" v-if="sakit.length > 0">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Nama</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(dt, index) in sakit" :key="dt">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <span>{{ dt.employe?.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Mulai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Selesai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Status</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Keterangan</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(dt, index) in sakit" :key="dt">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.date_start }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.date_end }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.status_string }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.description }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-accordion" v-if="dt.status < 1">
                                                        <div class="srp-accordion-button" :id="'sab-sakit-action-' + index" @click="actionToggle('sab-sakit-action-' + index)">
                                                            <div class="sab-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="arrow"/>
                                                            </div>
                                                        </div>
                                                        <div class="srp-accordion-options">
                                                            <div class="sao-list">
                                                                <div class="">
                                                                    <span @click="showModal('modal-dinasacc-' + index)">Setujui</span>
                                                                </div>
                                                                <div class="modal srp-modal" :id="'modal-dinasacc-' + index">
                                                                    <div class="srp-modal-head">
                                                                        <div class="smh-close" @click="hideModal('modal-dinasacc-' + index)">
                                                                            <span>x</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="srp-modal-body">
                                                                        <div class="smb-form">
                                                                            <p>Setujui permintaan ini ?</p>
                                                                            <div class="smb-form-input">
                                                                                <div class="sfi-button" v-if="!loader">
                                                                                    <button @click="settleSakit(dt.id, 1);">Setujui</button>
                                                                                </div>
                                                                                <div class="sfi-button" v-else>
                                                                                    <button>Loading.....</button>
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="sao-list">
                                                                <div class="">
                                                                    <span @click="showModal('modal-dinasdeny-' + index)">Tolak</span>
                                                                </div>
                                                                <div class="modal srp-modal" :id="'modal-dinasdeny-' + index">
                                                                    <div class="srp-modal-head">
                                                                        <div class="smh-close" @click="hideModal('modal-dinasdeny-' + index)">
                                                                            <span>x</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="srp-modal-body">
                                                                        <div class="smb-form">
                                                                            <p>Tolak permintaan ini ?</p>
                                                                            <div class="smb-form-input">
                                                                                <div class="sfi-button" v-if="!loader">
                                                                                    <button @click="settleSakit(dt.id, 0);">Tolak</button>
                                                                                </div>
                                                                                <div class="sfi-button" v-else>
                                                                                    <button>Loading.....</button>
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="si-body-inner sso-note" v-else>
                                <p>Tidak ada data</p>
                            </div>
                        </div>
                        <div class="sbt-body-content" id="sbc-tugas" v-if="tugas">
                            <div class="si-body-inner" v-if="tugas.length > 0">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Nama</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(dt, index) in tugas" :key="dt">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <span>{{ dt.employe?.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Mulai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Selesai</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Status</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Keterangan</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(dt, index) in tugas" :key="dt">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.date_start }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.date_end }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.status_string }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.description }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-accordion" v-if="dt.status < 1">
                                                        <div class="srp-accordion-button" :id="'sab-tugas-action-' + index" @click="actionToggle('sab-tugas-action-' + index)">
                                                            <div class="sab-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="arrow"/>
                                                            </div>
                                                        </div>
                                                        <div class="srp-accordion-options">
                                                            <div class="sao-list">
                                                                <div class="">
                                                                    <span @click="showModal('modal-dinasacc-' + index)">Setujui</span>
                                                                </div>
                                                                <div class="modal srp-modal" :id="'modal-dinasacc-' + index">
                                                                    <div class="srp-modal-head">
                                                                        <div class="smh-close" @click="hideModal('modal-dinasacc-' + index)">
                                                                            <span>x</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="srp-modal-body">
                                                                        <div class="smb-form">
                                                                            <p>Setujui permintaan ini ?</p>
                                                                            <div class="smb-form-input">
                                                                                <div class="sfi-button" v-if="!loader">
                                                                                    <button @click="settleTugas(dt.id, 1);">Setujui</button>
                                                                                </div>
                                                                                <div class="sfi-button" v-else>
                                                                                    <button>Loading.....</button>
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="sao-list">
                                                                <div class="">
                                                                    <span @click="showModal('modal-dinasdeny-' + index)">Tolak</span>
                                                                </div>
                                                                <div class="modal srp-modal" :id="'modal-dinasdeny-' + index">
                                                                    <div class="srp-modal-head">
                                                                        <div class="smh-close" @click="hideModal('modal-dinasdeny-' + index)">
                                                                            <span>x</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="srp-modal-body">
                                                                        <div class="smb-form">
                                                                            <p>Tolak permintaan ini ?</p>
                                                                            <div class="smb-form-input">
                                                                                <div class="sfi-button" v-if="!loader">
                                                                                    <button @click="settleTugas(dt.id, 0);">Tolak</button>
                                                                                </div>
                                                                                <div class="sfi-button" v-else>
                                                                                    <button>Loading.....</button>
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="si-body-inner sso-note" v-else>
                                <p>Tidak ada data</p>
                            </div>
                        </div>
                        <div class="sbt-body-content" id="sbc-btabsen" v-if="btAbsen">
                            <div class="si-body-inner" v-if="btAbsen.length > 0">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>Nama</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(dt, index) in btAbsen" :key="dt">
                                                <div class="sbp-num">
                                                    <span>{{ index + 1 }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <span>{{ dt.employe?.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <div class="sts-head-part">
                                                <span>Shift</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Masuk/Keluar</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Waktu</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Status</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Keterangan</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(dt, index) in btAbsen" :key="dt">
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.schedule?.shift?.name }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.type?.alias }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.time }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.status?.string }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ dt.description }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <div class="srp-accordion" v-if="dt.status?.code < 1">
                                                        <div class="srp-accordion-button" :id="'sab-tugas-action-' + index" @click="actionToggle('sab-tugas-action-' + index)">
                                                            <div class="sab-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="arrow"/>
                                                            </div>
                                                        </div>
                                                        <div class="srp-accordion-options">
                                                            <div class="sao-list">
                                                                <div class="">
                                                                    <span @click="showModal('modal-dinasacc-' + index)">Setujui</span>
                                                                </div>
                                                                <div class="modal srp-modal" :id="'modal-dinasacc-' + index">
                                                                    <div class="srp-modal-head">
                                                                        <div class="smh-close" @click="hideModal('modal-dinasacc-' + index)">
                                                                            <span>x</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="srp-modal-body">
                                                                        <div class="smb-form">
                                                                            <p>Setujui permintaan ini ?</p>
                                                                            <div class="smb-form-input">
                                                                                <div class="sfi-button" v-if="!loader">
                                                                                    <button @click="settleBtAbsen(dt.id, 1);">Setujui</button>
                                                                                </div>
                                                                                <div class="sfi-button" v-else>
                                                                                    <button>Loading.....</button>
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="sao-list">
                                                                <div class="">
                                                                    <span @click="showModal('modal-dinasdeny-' + index)">Tolak</span>
                                                                </div>
                                                                <div class="modal srp-modal" :id="'modal-dinasdeny-' + index">
                                                                    <div class="srp-modal-head">
                                                                        <div class="smh-close" @click="hideModal('modal-dinasdeny-' + index)">
                                                                            <span>x</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="srp-modal-body">
                                                                        <div class="smb-form">
                                                                            <p>Tolak permintaan ini ?</p>
                                                                            <div class="smb-form-input">
                                                                                <div class="sfi-button" v-if="!loader">
                                                                                    <button @click="settleBtAbsen(dt.id, 0);">Tolak</button>
                                                                                </div>
                                                                                <div class="sfi-button" v-else>
                                                                                    <button>Loading.....</button>
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="si-body-inner sso-note" v-else>
                                <p>Tidak ada data</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import ApprovalSkeleton from '@/components/skeletons/sections/ApprovalSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useApprovalStore } from '@/stores/approval';
    import { showModal, hideModal, actionToggle, showTab } from '@/functions/general';
    import { settleDinas, settleSakit, settleTugas, settleBtAbsen } from '@/functions/approval';
    const dinas = computed(() => useApprovalStore().dinas);
    const sakit = computed(() => useApprovalStore().sakit);
    const tugas = computed(() => useApprovalStore().tugas);
    const btAbsen = computed(() => useApprovalStore().btAbsen);
    // const data = computed(() => useApprovalStore().data);
    const loader = computed(() => useApprovalStore().loader);
    useApprovalStore().setDinas();
    useApprovalStore().setSakit();
    useApprovalStore().setTugas();
    useApprovalStore().setBtAbsen();
    // useApprovalStore().setData();
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    @import url('../../assets/css/tab.css');
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        transition: 0.2s;
        /* border: 1px solid green; */
    }
    .si-head{
        padding: 0 0 2rem 0;
    }
    .data-baru-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        user-select: none;
        cursor: pointer;
    }
    .si-body-tab{
        /*  */
    }
    .sbt-head{
        width: 100%;
        display: flex;
        padding: 1rem 0 1rem 0;
        font-weight: 700;
        overflow: auto;
        user-select: none;
    }
    .sbt-head-button{
        min-width: 200px;
        cursor: pointer;
        border: 1px solid var(--softgrey);
        padding: 0.5rem;
        text-align: center;
        margin: 0 0.5rem 0 0;
        transition: 0.2s;
    }
    .sbt-head-button:hover{
        color: var(--primary);
    }
    .shb-active{
        color: var(--primary);
    }
    .sbt-body-content{
        position: relative;
        display: none;
        -webkit-animation-name: animatetab;
		-webkit-animation-duration: 0.5s;
		animation-name: animatetab;
		animation-duration: 0.5s;
    }
    .sbc-active{
        display: block;
    }
    .si-body-inner{
        padding: 0 0 2.5rem 0;
        /* border: 1px solid gold; */
    }
    .sbi-table{
        width: 100%;
        display: flex;
        /* border: 1px solid green; */
        padding: 0 0 2.5rem 0;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
        padding: 0 0 5rem 0;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
        padding: 0 0 5rem 0;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 75px;
        width: 25%;
        text-align: center;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-0{
        background: var(--smoke);
    }
    .sts-row-part{
        min-width: 75px;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .srp-0{
        background: var(--smoke);
    }
    .srp-accordion{
        position: relative;
        width: 100%;
        height: 37px;
        user-select: none;
    }
    .srp-accordion-button{
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .sab-icon img{
        transition: 0.2s;
    }
    .srp-accordion-options{
        position: absolute;
        top: 100%;
        left: 5%;
        width: 90%;
        background: var(--white);
        z-index: 1;
        display: none;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .sao-active{
        display: block;
    }
    .sao-list{
        padding: 0.5rem;
    }
    .srp-button{
        text-align: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .srp-modal{
        padding: 100px 25% 1rem 25%;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        max-height: 90%;
        overflow: auto;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 75px 0 0 0;
        }
        .srp-modal-body{
            height: 100%;
            padding: 25px 0 0 0;
            text-align: center;
            max-height: unset;
        }
    }
    /*  */
</style>