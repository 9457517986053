import { useDinasStore } from "@/stores/dinas";
export async function newData(){
    const fd = new FormData();
    const starttime = document.getElementById('input-data-mulai');
    const endtime = document.getElementById('input-data-selesai');
    const description = document.getElementById('input-data-keterangan');
    const attachments = document.getElementById('flb-attachments');
    for(var i = 0; i < attachments.children.length; i++){
        if(attachments.children[i].children[0].value){
            const [files] = await attachments.children[i].children[0].files;
            fd.append(`attachments[${i}]`, files);
        }
    }
    fd.append('date_start', starttime.value);
    fd.append('date_end', endtime.value);
    fd.append('description', description.value);
    fd.append('byself', 1);
    useDinasStore().newData(fd);
}
export async function updateData(index, id){
    const fd = new FormData();
    const starttime = document.getElementById('input-data-mulai' + index);
    const endtime = document.getElementById('input-data-selesai' + index);
    const description = document.getElementById('input-data-keterangan' + index);
    const attachments = document.getElementById('flb-attachments' + index);
    for(var i = 0; i < attachments.children.length; i++){
        if(attachments.children[i].children[0].value){
            const [files] = await attachments.children[i].children[0].files;
            fd.append(`attachments[${i}]`, files);
        }
    }
    fd.append('id', id);
    fd.append('date_start', starttime.value);
    fd.append('date_end', endtime.value);
    fd.append('description', description.value);
    fd.append('byself', 1);
    useDinasStore().updateData(fd);
}
export function deleteData(id){
    useDinasStore().deleteData(id);
}
export function showTab(cl, id){
    const button = document.getElementsByClassName(cl.button);
    const content = document.getElementsByClassName(cl.content);
    for(var i = 0; i < button.length; i++){
        button[i].classList.remove('shp-active');
    }
    for(i = 0; i < content.length; i++){
        content[i].classList.remove('sbp-active');
    }
    document.getElementById(id.button).classList.add('shp-active');
    document.getElementById(id.content).classList.add('sbp-active');
}
export function inputLampiran(id){
    const inform = document.getElementById(id);
    const el = document.createElement('div');
    el.classList.add('element-relative');
    el.style.cssText = 'width: 100%;';
    el.innerHTML = '<input type="file"/><div class="sfi-input-remove" style="position: absolute; top: 0; right: 0; width: 50px; height: 100%; cursor: pointer; display: flex; justify-content: right; align-items: center; padding: 0.5rem; transition: 0.2s;">x</div>';
    el.children[1].addEventListener('click', function(e){
        e.target.parentElement.remove();
    });
    inform.appendChild(el);
}
// export function inputLampiranUpdate(id){
//     const inform = document.getElementById(id);
//     const el = document.createElement('div');
//     el.classList.add('element-relative');
//     el.style.cssText = 'width: 100%;';
//     el.innerHTML = '<input type="file"/><div class="sfi-input-remove" style="position: absolute; top: 0; right: 0; width: 50px; height: 100%; cursor: pointer; display: flex; justify-content: right; align-items: center; padding: 0.5rem; transition: 0.2s;">x</div>';
//     el.children[1].addEventListener('click', function(e){
//         e.target.parentElement.remove();
//     });
//     inform.appendChild(el);
// }