import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import axiosFormData from "@/axiosFormData";
import { getCookie, showNotification, timeToYmdhms, hideModals, getDay } from "@/functions/general.js";
// import { showLemburButton } from "@/functions/presensi";

export const usePresensiStore = defineStore("presensi", {
    state: () => ({
        // ==================== states used on absen page and its relation
        rawPresence: null,
        presence: null,
        presenceShow: null,
        inputFilter: {from: null, to: null},
        loader: false,
        justNow: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on absen page and its relation
        async setRawPresence(){
            this.rawPresence = null;
            this.presence = null;
            this.presenceShow = null;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const date = new Date();
                const month = (date.getMonth() + 1);
                const year = date.getFullYear();
                const response = await axiosClient.get(`shift/presence/` + month + '/' + year + '?type=more');
                this.rawPresence = response.data.data;
                console.log(this.rawPresence);
                await this.setPresence();
                // console.log(this.presence);
            }catch(error){
                if(error.response?.status == 422){
                    showNotification('', error.response.data.message, 2000, 'var(--red)');
                }
                console.log(error);
            }
        },
        async setPresence(){
            var pdata = [];
            var presrow = null;
            for(var i = 0; i < this.rawPresence.length; i++){
                var note = null;
                if(this.rawPresence[i].time_in && this.rawPresence[i].time_out){
                    // ============================================
                    var datestart = this.rawPresence[i].date; // schedule start date
                    var dateend = this.rawPresence[i].date; // schedule end date
                    var dateci = this.rawPresence[i].date; // check in date
                    var dateco = this.rawPresence[i].date; // check out date
                    // var clockstart = 23;
                    // var clockend = 7;
                    var clockstart = parseInt(this.rawPresence[i].time_start.slice(0, 2));
                    var clockend = parseInt(this.rawPresence[i].time_end.slice(0, 2));
                    if(clockstart >= clockend){ // determine the end date
                        var rd = new Date(this.rawPresence[i].date);
                        var nd = new Date(rd.getTime() + 86400000); // 86400000 = 1 day
                        dateend = nd.toISOString().split('T')[0];
                        // console.log(dateend);
                        // console.log(nd.getFullYear() + '-' + (nd.getMonth() + 1) + '-' + nd.getDate());
                    }
                    // var clockci = 1;
                    // var clockco = 11;
                    var clockci = parseInt(this.rawPresence[i].time_start.slice(0, 2));
                    // var clockco = parseInt(this.rawPresence[i].time_end.slice(0, 2));
                    if(clockci <= (clockstart - 12)){ // determine the check in date
                        var cidate = new Date(this.rawPresence[i].date);
                        var ncidate = new Date(cidate.getTime() + 86400000); // 86400000 = 1 day
                        dateci = ncidate.toISOString().split('T')[0];
                        dateco = ncidate.toISOString().split('T')[0];
                    }
                    // console.log('timestart : ' + this.rawPresence[i].time_start + ', timeend : ' + this.rawPresence[i].time_end);
                    // console.log('schedule start clock : ' + clockstart + ', schedule end clock : ' + clockend);
                    // console.log(datestart);
                    // console.log(dateend);
                    // console.log(dateci);
                    // console.log(dateco);
                    // ============================================
                    const tsstr = datestart + ' ' + this.rawPresence[i].time_start; // schedule starts
                    const testr = dateend + ' ' + this.rawPresence[i].time_end; // schedule ends
                    const cistr = dateci + ' ' + this.rawPresence[i].time_in; // check in
                    const costr = dateco + ' ' + this.rawPresence[i].time_out; // check out
                    const starts = new Date(tsstr);
                    const ends = new Date(testr);
                    const checkin = new Date(cistr);
                    const checkout = new Date(costr);
                    const tstime = starts.getTime();
                    const tetime = ends.getTime();
                    const citime = checkin.getTime();
                    const cotime = checkout.getTime();
                    const minimum = tetime - tstime;
                    const elapsed = cotime - citime;
                    var differ = elapsed - minimum;
                    var difcon;
                    var difhour;
                    var difmin;
                    if(differ == 0){
                        note = {name : 'Tepat Waktu', message: 'Anda tepat waktu pada tanggal ini'};
                    }else if(differ > 0){
                        difcon = timeToYmdhms(differ);
                        difhour = difcon.hours + ' Jam ';
                        difmin = difcon.minutes + ' Menit';
                        note = {name : 'Lebih', message: 'Lebih ' + difhour + difmin};
                    }else{
                        difcon = timeToYmdhms(Math.abs(differ));
                        difhour = difcon.hours + ' Jam ';
                        difmin = difcon.minutes + ' Menit';
                        note = {name : 'Kurang', message: 'Kurang ' + difhour + difmin};
                    }
                }
                var lembur;
                const morehour = new String(this.rawPresence[i].more_hour_diff);
                if(parseInt(morehour.split(':')[0]) > 0 && !this.rawPresence[i].has_overtime_process){
                    // const lestart = new String(this.rawPresence[i].time_end);
                    // const lsval = parseInt(lestart.split(':')[0]);
                    // var extend1;
                    // var extend2;
                    // if((lsval + 1) <= 24){
                    //     extend1 = lsval + 1;
                    // }
                    // if((lsval + 2) <= 24){
                    //     extend2 = lsval + 2;
                    // }
                    lembur = true;
                }else{
                    lembur = false;
                }
                presrow = {
                    id: this.rawPresence[i].id,
                    date: this.rawPresence[i].date,
                    shift: this.rawPresence[i].shift,
                    check_in: this.rawPresence[i].time_in,
                    check_out: this.rawPresence[i].time_out,
                    note: note,
                    more_hour: this.rawPresence[i].more_hour_diff,
                    lembur: lembur,
                    day: getDay(new Date(this.rawPresence[i].date).getDay()),
                    holiday: this.rawPresence[i].is_holiday
                }
                if(!this.rawPresence[i].is_holiday && !this.rawPresence[i].is_leave){
                    pdata.push(presrow);
                }
            }
            this.presence = pdata;
            this.presenceShow = pdata;
        },
        inputDataFilter(e){
            if(e.srcElement.getAttribute('filter') == 'from'){
                this.inputFilter.from = e.srcElement.value;
            }else{
                this.inputFilter.to = e.srcElement.value;
            }
        },
        async filterData(){
            // console.log(this.inputFilter);
            if(this.inputFilter.from && this.inputFilter.to){
                const ndate = new Date();
                const fdate = new Date(this.inputFilter.from);
                const tdate = new Date(this.inputFilter.to);
                if(tdate.getTime() >= fdate.getTime()){
                    var scfiltered = [];
                    // =====
                    if((ndate.getMonth() == fdate.getMonth() && ndate.getMonth() == tdate.getMonth()) && (ndate.getFullYear() == fdate.getFullYear() && ndate.getFullYear() == tdate.getFullYear())){
                        for(var i = 0; i < this.presence.length; i++){
                            const date = new Date(this.presence[i].date);
                            if(date.getTime() >= fdate.getTime() && date.getTime() <= tdate.getTime()){
                                scfiltered.push(this.presence[i]);
                            }
                        }
                        this.presenceShow = scfiltered;
                        console.log('current month');
                    }else{
                        showNotification('', 'Filter hanya untuk bulan ini', 2000, 'var(--red)');
                        // for(var year = fdate.getFullYear(); year < tdate.getFullYear(); year++){
                        //     const response = await axiosClient.get(`shift/presence/` + month + '/' + year + '?type=more');
                        // }
                    }
                    console.log('now : ' + ndate.getMonth() + ', from : ' + fdate.getMonth() + ', to : ' + tdate.getMonth());
                    // =====
                }else{
                    showNotification('', 'Tanggal Hingga harus setelah atau sama dengan Tanggal Dari', 5000, 'var(--red)');
                }
            }else{
                showNotification('', 'Tanggal Dari dan Hingga harus diisi', 5000, 'var(--red)');
            }
        },
        resetData(){
            this.presenceShow = this.presence;
            this.inputFilter = {from: null, to: null};
        },
        async submitLembur(params){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosFormData.post(`overtime/submission`, params);
                if(response.data.success){
                    showNotification('', 'Permintaan Lembur dikirim', 5000, null);
                    hideModals();
                    this.setRawPresence();
                }
                // console.log(response);
                this.loader = false;
            }catch(error){
                if(error.response?.status == 422){
                    showNotification('', error.response.data.message, 2000, 'var(--red)');
                }
                console.log(error);
                this.loader = false;
            }
        }
        // ,
        // async initLembur(){
        //     var lbshow = 3600000; // 1 hour
        //     var lbdelay = null;
        //     const date = new Date();
        //     const nextdate = new Date(date.getTime() + 86400000); // + 1 day
        //     const today = date.getFullYear() + '-' + await leftPadNum(date.getMonth() + 1, 2, '0') + '-' + await leftPadNum(date.getDate(), 2, '0');
        //     const nextday = nextdate.getFullYear() + '-' + await leftPadNum(nextdate.getMonth() + 1, 2, '0') + '-' + await leftPadNum(nextdate.getDate(), 2, '0');
        //     for(var i = 0; i < this.rawPresence.length; i++){
        //         if(this.rawPresence[i].date == today){
        //             this.rawPresence[i].today = true;
        //             const ldstart = new Date(today + ' ' + this.rawPresence[i].time_start);
        //             const ldend = new Date(today + ' ' + this.rawPresence[i].time_end);
        //             if((ldend.getTime() - ldstart.getTime()) >= 0){
        //                 lbdelay = (ldend.getTime() + lbshow) - date.getTime();
        //                 console.log(ldend.getTime() - ldstart.getTime());
        //             }else{
        //                 lbdelay = (nextday.getTime() + lbshow) - date.getTime();
        //             }
        //         }else{
        //             this.rawPresence[i].today = false;
        //         }
        //     }
        //     if(lbdelay > 1){
        //         setTimeout(function(){
        //             showLemburButton();
        //         }, 1000);
        //     }else{
        //         showLemburButton();
        //     }
        //     console.log(lbdelay);
        //     console.log(this.presence);
        //     console.log(today);
        //     console.log(nextday);
        // }
    }
});
