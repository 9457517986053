import { defineStore } from "pinia";
import { setCookie, getCookie, showNotification, hideModals, generateRandomString } from "@/functions/general";
import { sessionExpiration } from "@/data/settings.js";
import router from "@/router";
import axiosClient from "../axios";
import axiosFormData from "@/axiosFormData";
import { useJadwalStore } from "./jadwal";

export const useAccountStore = defineStore("account", {
    state: () => ({
        // ==================== states used on account page and its relation
        account: null,
        provinces: null,
        provincesShow: null,
        searchProvince: {select: {title: 'Pilih Provinsi', value: ''}, input: {value: ''}},
        citiesByProvince: null,
        cbpShow: null,
        searchCity: {select: {title: '', value: ''}, input: {value: ''}},
        selectedCity: null,
        religions: null,
        religionsShow: null,
        searchReligion: {select: {title: '', value: ''}, input: {value: ''}},
        loader: false,
        leaders: [],
        loginPassword: {
            visibility: false,
            contains: false
        },
        resetPassword: {
            newPassword: {
                visibility: false,
                contains: false
            },
            newConfirmPassword: {
                visibility: false,
                contains: false
            }
        }
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on account page and its relation
        async login(params){
            this.loader = true;
            try{
                const response = await axiosClient.post(`auth/login`, params);
                if(response.data.token){
                    setCookie('session', response.data.token, sessionExpiration);
                    await this.getProfile();
                    router.push('/profil');
                }
                this.loader = false;
                console.log(response);
            }catch(error){
                if(error.response?.status == 403){
                    showNotification('', 'Email atau Password salah', 2000, null);
                }else{
                    showNotification('', error.response?.data?.message, 2000, 'red');
                }
                this.loader = false;
                console.log(error);
            }
        },
        async loginCheck(){
            if(!getCookie('deviceid')){
                setCookie('deviceid', generateRandomString(64), 365);
            }else{
                setCookie('deviceid', getCookie('deviceid'), 365);
            }
            if(getCookie('session')){
                this.getProfile();
                // console.log(getCookie('deviceid'));
            }
            // if(getCookie('deviceid')){
            //     console.log('Device ID : ' + getCookie('deviceid'));
            // }
        },
        async getLeaders(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`shift/child-access`);
                this.leaders = response.data.ids;
                console.log(response);
            }catch(error){
                if(error.response?.status == 401){
                    setCookie('session', '', 0);
                    router.push('/profil');
                    showNotification('', 'Sesi telah kadaluarsa, silakan login kembali !', 2000, null);
                }
                console.log(error);
            }
        },
        async getProfile(){
            if(!this.account){
                window.sessionStorage.setItem('authToken', getCookie('session'));
                try{
                    const response = await axiosClient.get(`employe/profile`);
                    this.account = response.data.data;
                    this.selectedCity = response.data.data.city;
                    await this.setProvinces();
                    for(var i = 0; i < this.provinces.length; i++){
                        if(this.account.province){
                            if(this.provinces[i].name.toLowerCase() == this.account.province.toLowerCase()){
                                await this.setCitiesByProvince(this.provinces[i]);
                                this.selectedCity = this.account.city;
                            }
                        }
                    }
                    this.setReligions();
                    if(this.account.attachment_lists.length){
                        if(this.account.attachment_values.length){
                            for(i = 0; i < this.account.attachment_lists.length; i++){
                                for(var j = 0; j < this.account.attachment_values.length; j++){
                                    if(this.account.attachment_lists[i].id == this.account.attachment_values[j].attachment_id){
                                        // console.log('hello');
                                        this.account.attachment_lists[i].submitted_document = this.account.attachment_values[j];
                                    }
                                }
                            }
                        }
                    }
                    console.log(this.account);
                }catch(error){
                    if(error.response?.status == 401){
                        setCookie('session', '', 0);
                        router.push('/profil');
                        showNotification('', 'Sesi telah kadaluarsa, silakan login kembali !', 2000, null);
                    }
                    console.log(error);
                }
            }
        },
        async refreshProfile(){
            this.account = null;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`employe/profile`);
                this.account = response.data.data;
                this.selectedCity = response.data.data.city;
                await this.setProvinces();
                for(var i = 0; i < this.provinces.length; i++){
                    if(this.account.province){
                        if(this.provinces[i].name.toLowerCase() == this.account.province.toLowerCase()){
                            await this.setCitiesByProvince(this.provinces[i]);
                            this.selectedCity = this.account.city;
                        }
                    }
                }
                this.setReligions();
                if(this.account.attachment_lists.length){
                    if(this.account.attachment_values.length){
                        for(i = 0; i < this.account.attachment_lists.length; i++){
                            for(var j = 0; j < this.account.attachment_values.length; j++){
                                if(this.account.attachment_lists[i].id == this.account.attachment_values[j].attachment_id){
                                    // console.log('hello');
                                    this.account.attachment_lists[i].submitted_document = this.account.attachment_values[j];
                                }
                            }
                        }
                    }
                }
                console.log(this.account);
            }catch(error){
                if(error.response?.status == 401){
                    setCookie('session', '', 0);
                    router.push('/profil');
                    showNotification('', 'Sesi telah kadaluarsa, silakan login kembali !', 2000, null);
                }
                console.log(error);
            }
            // window.sessionStorage.setItem('authToken', getCookie('session'));
            // try{
            //     const response = await axiosClient.get(`employe/profile`);
            //     this.account = response.data.data;
            //     this.selectedCity = response.data.data.city;
            //     await this.setProvinces();
            //     for(var i = 0; i < this.provinces.length; i++){
            //         if(this.account.province){
            //             if(this.provinces[i].name.toLowerCase() == this.account.province.toLowerCase()){
            //                 await this.setCitiesByProvince(this.provinces[i]);
            //                 this.selectedCity = this.account.city;
            //             }
            //         }
            //     }
            //     this.setReligions();
            //     // console.log(response);
            // }catch(error){
            //     console.log(error);
            // }
        },
        setReligions(){
            const religions = [
                {
                    id: 1,
                    name: 'Islam',
                    title: 'Islam',
                    value: 'Islam'
                },
                {
                    id: 2,
                    name: 'Katolik',
                    title: 'Katolik',
                    value: 'Katolik'
                },
                {
                    id: 3,
                    name: 'Protestan',
                    title: 'Protestan',
                    value: 'Protestan'
                },
                {
                    id: 4,
                    name: 'Budha',
                    title: 'Budha',
                    value: 'Budha'
                },
                {
                    id: 5,
                    name: 'Hindu',
                    title: 'Hindu',
                    value: 'Hindu'
                },
                {
                    id: 6,
                    name: 'Konghucu',
                    title: 'Konghucu',
                    value: 'Konghucu'
                }
            ];
            this.religions = religions;
            this.religionsShow = religions;
        },
        async logout(){
            this.loader = true;
            useJadwalStore().employees = null;
            // console.log(useJadwalStore().employees);
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.post(`auth/logout`);
                if(response.data.success){
                    setCookie('session', getCookie('session'), 0);
                    window.sessionStorage.setItem('authToken', null);
                    this.account = null;
                    router.push('/login');
                    showNotification('', 'Logout berhasil', 2000, null);
                }
                this.loader = false;
            }catch(error){
                this.loader = false;
                console.log(error);
            }
        },
        async setProvinces(){
            try{
                const response = await axiosClient.get(`region/province`);
                this.provinces = response.data;
                this.provincesShow = response.data;
                console.log(this.provinces);
            }catch(error){
                console.log(error);
            }
        },
        async setCitiesByProvince(province){
            this.selectedCity = null;
            // this.searchProvince.select.title = province.name;
            // this.searchProvince.input.value = province.name;
            try{
                const response = await axiosClient.get(`region/city/` + province.id);
                this.citiesByProvince = response.data;
                this.cbpShow = response.data;
            }catch(error){
                console.log(error);
            }
        },
        async updateProfile(params){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosFormData.post(`employe/profile/update`, params);
                if(response.status == 200){
                    showNotification('', 'Update Profil berhasil', 2000, null);
                    this.refreshProfile();
                    hideModals();
                }
                this.loader = false;
            }catch(error){
                if(error.response){
                    showNotification('', 'Ada yang salah, [kode : ' + error.response.status + ']', 2000, 'var(--red)');
                }
                this.loader = false;
                console.log(error);
            }
        },
        async inputProvinceSearch(e){
            this.searchProvince.input.value = await e.srcElement.value;
            if(this.searchProvince.input.value){
                var result = [];
                var inval = new RegExp(this.searchProvince.input.value, 'gi');
                for(var i = 0; i < this.provinces.length; i++){
                    if(this.provinces[i].name.match(inval)){
                        result.push(this.provinces[i]);
                    }
                }
                this.provincesShow = result;
            }else{
                this.provincesShow = this.provinces;
            }
        },
        async inputCitySearch(e){
            this.searchCity.input.value = await e.srcElement.value;
            if(this.searchCity.input.value){
                var result = [];
                var inval = new RegExp(this.searchCity.input.value, 'gi');
                for(var i = 0; i < this.citiesByProvince.length; i++){
                    if(this.citiesByProvince[i].name.match(inval)){
                        result.push(this.citiesByProvince[i]);
                    }
                }
                this.cbpShow = result;
            }else{
                this.cbpShow = this.citiesByProvince;
            }
        },
        async inputReligionSearch(e){
            this.searchReligion.input.value = await e.srcElement.value;
            if(this.searchReligion.input.value){
                var result = [];
                var inval = new RegExp(this.searchReligion.input.value, 'gi');
                for(var i = 0; i < this.religions.length; i++){
                    if(this.religions[i].name.match(inval)){
                        result.push(this.religions[i]);
                    }
                }
                this.religionsShow = result;
            }else{
                this.religionsShow = this.religions;
            }
        },
        async updatePassword(params){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.post(`account/password/change`, params);
                if(response.status == 200 && response.data.success){
                    showNotification('', 'Update Password berhasil', 2000, null);
                }
                this.loader = false;
                return {status: true};
                // console.log(response);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response.data.message, 2000, 'var(--red)');
                }
                this.loader = false;
                return {status: false};
                // console.log(error);
            }
        },
        async submitDocument(params){
            this.loader = true;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosFormData.post(`employe/attach`, params);
                if(response.data.success){
                    showNotification('', 'Upload Dokumen berhasil', 5000, null);
                    hideModals();
                    this.refreshProfile();
                    // this.setLembur();
                    // usePresensiStore().setRawPresence();
                }
                this.loader = false;
                // console.log(response);
            }catch(error){
                if(error.response?.status == 422){
                    showNotification('', error.response.data.message, 2000, 'var(--red)');
                }
                console.log(error);
                this.loader = false;
            }
        },
        inputEditName(id){
            const name = document.getElementById(id);
            this.account.name = name.value;
        },
        inputEditEmail(id){
            const email = document.getElementById(id);
            this.account.user.email = email.value;
        },
        inputEditBirthPlace(id){
            const birthplace = document.getElementById(id);
            this.account.birthplace = birthplace.value;
        },
        inputEditAddress(id){
            const address = document.getElementById(id);
            this.account.address = address.value;
        },
        inputEditPhone(id){
            const phone = document.getElementById(id);
            this.account.phone = phone.value;
        }
    }
});