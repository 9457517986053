// import QrcodeDecoder from "qrcode-decoder";
// import QCodeDecoder from "qcode-decoder";
import QrScanner from 'qr-scanner';
import { useAbsenStore } from '@/stores/absen';
import router from '@/router';
import { usePresensiStore } from '@/stores/presensi';

export async function scanQRCode(selfie){
	if(!useAbsenStore().selfieResult){
		return router.push('/absen');
	}
	let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
	var qc = null;
	// Get the video element
	const videoElement = document.getElementById("video-element");
	// const scvideo = document.getElementById('scanner-video');
	// const cmbutton = document.getElementById('start-camera');

	// Request permission to use the user's camera
	// navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream){
		// Set the video element's source to the camera stream
		videoElement.srcObject = stream;
		videoElement.load();
		// lineScanner('on');
		const qrScanner = new QrScanner(
			videoElement,
			result => getQC(result.data),
			{
				preferredCamera: 'environment',
				highlightScanRegion: true,
				highlightCodeOutline: true
				/* your options or returnDetailedScanResult: true if you're not specifying any other options */ 
			},
		);
		qrScanner.setInversionMode("both");
		qrScanner.start();
		QrScanner.listCameras(true).then(function(cameras){
			const camselected = (cameras.length - 1);
			qrScanner.setCamera(cameras[camselected].id);
			// console.log(cameras);
		});
		// console.log(qrScanner);
		async function getQC(qrcode){
			if(!qc){
				qc = await qrcode;
				sendQrcode(qc);
			}
		}
		async function sendQrcode(qrcode){
			stream.getTracks().forEach(function(track) {
				track.stop();
			});
			// scvideo.classList.add('scelm-hidden');
			// cmbutton.classList.remove('scelm-hidden');
			// console.log(JSON.parse(qrcode));
			qrScanner.stop();
			qrScanner.destroy();
			// const qc = qrcode;
			// console.log(selfie);
			const absenResult = await useAbsenStore().absenQR(qrcode, selfie);
			if(absenResult == 1){
				const date = new Date();
				useAbsenStore().selfieResult = null;
				usePresensiStore().justNow = date.toISOString().split('T')[0];
				router.push('/presensi');
			}
		}
	// }).catch(function(error){
	// 	console.log("Error accessing camera:", error);
	// });
	// Check if the browser supports WebRTC
	if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia){
		console.log("WebRTC is not supported");
		return;
	}
}
export async function scanFace(){
	let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
	var selfie = null;
	let camera_button = document.querySelector("#start-camera");
	let video = document.querySelector("#video-selfie");
	let click_button = document.querySelector("#capture-photo");
	let canvas = document.querySelector("#canvas-photo");
	let repeat = document.querySelector("#repeat-capture");
	let okay = document.querySelector("#okay-capture");
	// let scvideo = document.querySelector("#scanner-video");

	navigator.mediaDevices.enumerateDevices().then(function(devices){
		var cameras = [];
		for(var i = 0; i < devices.length; i++){
			if(devices[i].kind == 'videoinput'){
				cameras.push(devices[i]);
				// console.log(devices[i].label.match(/front\[(\d+)\]\[(\d+)\]/));
			}
			// console.log(devices[i]);
		}
		useAbsenStore().cameras = cameras;
		// console.log(useAbsenStore().cameras);
	});

	camera_button.addEventListener('click', async function() {
		camera_button.parentElement.classList.add('scelm-hidden');
		video.parentElement.classList.remove('scelm-hidden');
		// let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
		video.srcObject = stream;
		video.play();
	});

	click_button.addEventListener('click', function() {
		video.pause();
		video.parentElement.classList.add('scelm-hidden');
		canvas.parentElement.classList.remove('scelm-hidden');
		canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
		let image_data_url = canvas.toDataURL('image/jpeg');
		selfie = image_data_url;

		// data url of the image
		// console.log(image_data_url);
	});
	repeat.addEventListener('click', function() {
		video.play();
		video.parentElement.classList.remove('scelm-hidden');
		canvas.parentElement.classList.add('scelm-hidden');
	});
	okay.addEventListener('click', async function() {
		// const stream = await navigator.mediaDevices.getUserMedia({video: true});
		stream.getTracks().forEach(function(track) {
			track.stop();
		});
		canvas.parentElement.classList.add('scelm-hidden');
		// scvideo.classList.remove('scelm-hidden');
		// scanQRCode(selfie);
		// scvideo.scrollIntoView();
		useAbsenStore().selfieResult = selfie;
		// console.log(selfie);
		router.push('/absen/scan-qr');
	});
}
export function lineScanner(x){
	const line = document.getElementById('svl-absen');
	var pheight = line.parentElement.scrollHeight;
	var position = 0;
	var direction = 'down';
	function linePosition(){
		line.style.top = (position + 1) + 'px';
		if(position >= pheight){
			direction = 'up';
		}else{
			if(position == 0){
				direction = 'down';
			}
		}
		if(direction == 'down'){
			position = position + 1;
		}else{
			position = position - 1;
		}
	}
	switch(x){
		case 'on':
			line.classList.add('svl-active');
			setInterval(linePosition, 1);
		break;
		case 'off':
			line.classList.remove('svl-active');
			// for (var i = 0; i < window.setInterval.length; i++){
			// 	window.clearInterval(i);
			// }
			// clearInterval(liner);
		break;
	}
	// console.log(window.setInterval());
}






