<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <h1>Reset Password</h1>
            </div>
            <div class="si-body">
                <div class="login-form">
                    <div class="login-form-part">
                        <div class="lfp-label">
                            <span>Email</span>
                        </div>
                        <div class="lfp-input">
                            <input type="email" name="username" id="input-login-email" autocomplete="off" placeholder="masukkan email"/>
                        </div>
                    </div>
                    <!-- <div class="login-form-part">
                        <div class="lfp-label">
                            <span>Password</span>
                        </div>
                        <div class="lfp-input">
                            <input :type="loginPassword.visibility ? 'text' : 'password'" name="password" id="input-login-password" @input="loginPasswordInput('input-login-password');" autocomplete="off" placeholder="masukkan password"/>
                            <div class="lfp-input-shpass">
                                <template v-if="loginPassword.contains">
                                    <span v-if="!loginPassword.visibility" @click="shLoginPassword">Show</span>
                                    <span v-else @click="shLoginPassword">Hide</span>
                                </template>
                            </div>
                        </div>
                    </div> -->
                    <div class="login-form-part" v-if="!loader">
                        <button @click="login">Submit</button>
                    </div>
                    <div class="login-form-part" v-else>
                        <button>Loading.....</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useAccountStore } from '@/stores/account';
    // import { onMounted } from 'vue';
    // const loginPassword = computed(() => useAccountStore().loginPassword);
    const loader = computed(() => useAccountStore().loader);
    // onMounted(() => {
    //     setTimeout(function(){
    //         loginPasswordInput('input-login-password');
    //         console.log('mounted');
    //     }, 2000);
    // });
    // function loginPasswordInput(id){
    //     if(document.getElementById(id)){
    //         const input = document.getElementById(id);
    //         if(input.value){
    //             useAccountStore().loginPassword.contains = true;
    //         }else{
    //             useAccountStore().loginPassword.contains = false;
    //         }
    //         console.log(input.value);
    //     }
    // }
    // function shLoginPassword(){
    //     if(useAccountStore().loginPassword.visibility){
    //         useAccountStore().loginPassword.visibility = false;
    //     }else{
    //         useAccountStore().loginPassword.visibility = true;
    //     }
    // }
    function login(){
        const params = {
            username: document.getElementById('input-login-email').value, 
            password: document.getElementById('input-login-password').value
        };
        useAccountStore().login(params);
    }
</script>

<style scoped>
    section{
        padding: 2.5rem 25% 2.5rem 25%;
    }
    .section-inner{
        width: 100%;
    }
    .si-head h1{
        font-size: 2rem;
        margin: 0 0 1rem 0;
    }
    .si-body{
        display: flex;
        justify-content: center;
    }
    .login-form{
        width: 100%;
        min-height: 370px;
        border: 1px solid var(--softgrey);
        border-radius: 10px;
        padding: 1rem;
    }
    .login-form-part{
        width: 100%;
        padding: 0 0 1rem 0;
    }
    .lfp-label{
        padding: 1rem 0 1rem 0;
    }
    .lfp-input{
        width: 100%;
        height: 50px;
        position: relative;
    }
    .lfp-input input{
        width: 100%;
        height: 100%;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        outline: none;
        padding: 1% 50px 1% 1rem;
        font-size: 1.1rem;
        color: var(--grey);
    }
    .lfp-input-shpass{
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        user-select: none;
    }
    .login-form-part button{
        border: none;
        border-radius: 5px;
        background: var(--primary);
        color: var(--white);
        font-size: 1rem;
        padding: 1rem 2rem 1rem 2rem;
        min-width: 200px;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
    }
    .login-form-part button:hover{
        background: var(--primary-hover);
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
    }
    /*  */
</style>

