import { defineStore } from "pinia";
// import { jadwal } from "@/data/dummies.js";
// import { useAccountStore } from "./account";
import { getCookie, showNotification, hideModals, get12MonthsData } from "@/functions/general";
import axiosClient from "../axios.js";
import axiosFormData from "@/axiosFormData";
// import { showLemburButton } from "@/functions/jadwal";

export const useKpiStore = defineStore("kpi", {
    state: () => ({
        // ==================== states used on kpi page and its relation
        allData: null,
        dataFilter: null,
        lastFilter: null,
        kpiOptions: null,
        kpiOptionsFiltered: null,
        kpiReports: null,
        loader: false,
        filterLoader: false,
        // =====
        rawSchedule: null,
        jadwal: null,
        jadwalShow: null,
        jadwalYesterday: null,
        jadwalNearest: null,
        inputFilter: {from: null, to: null},
        employees: null,
        allFriends: null,
        friends: null,
        transferList: null,
        transferRequests: null,
        // loader: false
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on kpi page and its relation
        async setData(){
            this.allData = null;
            try{
                var filter = {
                    // date_from: get12MonthsData(),
                    // date_to: get12MonthsData(),
                    status: [{number: 0,name: 'TODO'}, {number: 1,name: 'ONGOING'}, {number: 2,name: 'DONE'}, {number: 3,name: 'APPROVED'}],
                    selected: {
                        date_from: null,
                        date_to: null,
                        status: null
                    }
                }
                this.dataFilter = filter;
                this.lastFilter = filter;
                const response = await axiosClient.get(`kpi/mine`);
                this.allData = {data: response.data.data, meta: response.data.meta, filter: filter};
                this.setKpiOptions();
                // console.log(this.allData);
            }catch(error){
                console.log(error);
            }
        },
        async resetData(){
            if(this.dataFilter.selected.date_from != null || this.dataFilter.selected.date_to != null || this.dataFilter.selected.status != null){
                this.setData();
            }
        },
        async filterData(){
            if(this.dataFilter.selected.date_from || this.dataFilter.selected.date_to || this.dataFilter.selected.status != null){
                console.log(this.dataFilter);
                this.filterLoader = true;
                this.allData.data = null;
                try{
                    const response = await axiosClient.get(`kpi/mine?date_start=${this.dataFilter?.selected?.date_from ? this.dataFilter?.selected?.date_from : ''}&date_end=${this.dataFilter?.selected?.date_to ? this.dataFilter?.selected?.date_to : ''}&status=${this.dataFilter?.selected?.status?.name ? this.dataFilter?.selected?.status?.name : ''}`);
                    this.allData.data = response.data.data;
                    this.allData.meta = response.data.meta;
                    this.lastFilter = this.dataFilter;
                    this.filterLoader = false;
                    console.log(response);
                }catch(error){
                    this.filterLoader = false;
                    console.log(error);
                }
            }
        },
        async dataByPage(page){
            this.allData.data = null;
            if(page == '&laquo; Previous'){
                page = this.allData.meta.current_page - 1;
            }
            if(page == 'Next &raquo;'){
                page = this.allData.meta.current_page + 1;
            }
            console.log(page);
            try{
                const response = await axiosClient.get(`kpi/mine?page=${page}&date_start=${this.lastFilter?.selected?.date_from ? this.lastFilter?.selected?.date_from : ''}&date_end=${this.lastFilter?.selected?.date_to ? this.lastFilter?.selected?.date_to : ''}&status=${this.lastFilter?.selected?.status?.name ? this.lastFilter?.selected?.status?.name : ''}`);
                this.allData.data = response.data.data;
                this.allData.meta = response.data.meta;
                // console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async setKpiOptions(){
            try{
                const response = await axiosClient.get(`kpi/options`);
                this.kpiOptions = response.data.data;
                this.kpiOptionsFiltered = response.data.data;
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async submitKpi(params, clear){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.loader = true;
            try{
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };
                const response = await axiosFormData.post(`kpi/submit`, params, config);
                if(response.data.success){
                    for(var i = 0; i < clear.length; i++){
                        document.getElementById(clear[i].input).value = clear[i].value;
                    }
                    showNotification('', 'Request berhasil', 2000, 'var(--primary)');
                    hideModals();
                    this.setData();
                }
                this.loader = null;
                console.log(response);
            }catch(error){
                console.log(navigator);
                if(error.response?.status == 422){
                    showNotification('', error.response?.data?.message, 2000, 'var(--red)');
                }else{
                    showNotification('', error.response?.data?.message, 2000, 'var(--red)');
                }
                this.loader = null;
                // console.clear();
                console.log(error);
            }
        },
        kpiFilterSelectDateFrom(date){
            this.dataFilter.selected.date_from = date;
            // this.allData.filter.selected.date_from = date;
            console.log(this.dataFilter);
        },
        kpiFilterSelectDateTo(date){
            this.dataFilter.selected.date_to = date;
            // this.allData.filter.selected.date_to = date;
            console.log(this.dataFilter);
        },
        kpiFilterSelectStatus(status){
            this.dataFilter.selected.status = status;
            // this.allData.filter.selected.status = status;
            console.log(this.dataFilter);
        },
        async getKpiReport(year, month){
            try{
                var data = null;
                var filter = null;
                const response = await axiosClient.get(`kpi/report?year=${year}&month=${month}`);
                Object.keys(response.data.data).forEach(key => {
                    data = response.data.data[key];
                });
                const currentYear = new Date().getFullYear();
                console.log(new Date().getMonth());
                filter = {
                    years: [currentYear - 2, currentYear - 1, currentYear, currentYear + 1, currentYear + 2],
                    months: get12MonthsData(),
                    selected: {
                        year: null,
                        month: null
                    }
                }
                this.kpiReports = {
                    data: data, 
                    filter: filter
                }
                console.log(response);
                console.log(this.kpiReports);
            }catch(error){
                console.log(error);
            }
        },
        selectYear(year){
            this.kpiReports.filter.selected.year = year;
            console.log(this.kpiReports);
        },
        selectMonth(month){
            this.kpiReports.filter.selected.month = month;
            console.log(this.kpiReports);
        },
        resetFilter(){
            this.kpiReports.data = null;
            this.kpiReports.filter.selected.year = null;
            this.kpiReports.filter.selected.month = null;
        },
        async filterKpiReport(){
            this.loader = true;
            if(this.kpiReports.filter.selected.year){
                if(this.kpiReports.filter.selected.month){
                    try{
                        this.kpiReports.data = null;
                        var data = null;
                        const response = await axiosClient.get(`kpi/report?year=${this.kpiReports.filter.selected.year}&month=${this.kpiReports.filter.selected.month.index + 1}`);
                        Object.keys(response.data.data).forEach(key => {
                            data = response.data.data[key];
                        });
                        this.kpiReports.data = data;
                        this.loader = false;
                        console.log(response);
                        console.log(this.kpiReports);
                    }catch(error){
                        this.loader = false;
                        console.log(error);
                    }
                }else{
                    showNotification('', 'Pilih Bulan dahulu', 2000, 'var(--red)');
                    this.loader = false;
                }
            }else{
                showNotification('', 'Pilih Tahun dahulu', 2000, 'var(--red)');
                this.loader = false;
            }
        },
        // =====
        // async setJadwal(){
        //     await useAccountStore().getProfile();
        //     this.getThisMonthSchedule();
        //     await this.getActiveEmployees();
        //     await this.getFriends();
        //     this.getTransferList();
        //     this.getTransferRequest();
        // },
        // async getThisMonthSchedule(){
        //     window.sessionStorage.setItem('authToken', getCookie('session'));
        //     try{
        //         const date = new Date();
        //         const month = (date.getMonth() + 1);
        //         const year = date.getFullYear();
        //         const response = await axiosClient.get(`shift/schedule/` + month + '/' + year);
        //         this.rawSchedule = await response.data.data;
        //         this.setSchedule();
        //         this.initLembur();
        //         // this.jadwal = await response.data.data;
        //         // this.jadwalShow = await response.data.data;
        //         console.log(this.rawSchedule);
        //     }catch(error){
        //         console.log(error);
        //     }
        // },
        // setSchedule(){
        //     const date = new Date();
        //     const yttime = new Date(date.getTime() - 86400000);
        //     const yesterday = new Date(yttime).toISOString().split('T')[0];
        //     // console.log(yesterday);
        //     var scdata = [];
        //     var nearestsc = [];
        //     for(var i = 0; i < this.rawSchedule.length; i++){
        //         this.rawSchedule[i].day = getDay(new Date(this.rawSchedule[i].date).getDay());
        //         if(!this.rawSchedule[i].is_holiday && !this.rawSchedule[i].is_leave){
        //             scdata.push(this.rawSchedule[i]);
        //             if(this.rawSchedule[i].date == date.toISOString().split('T')[0] || this.rawSchedule[i].date == yesterday){
        //                 nearestsc.push(this.rawSchedule[i]);
        //             }
        //             console.log(this.rawSchedule[i]);
        //         }
        //     }
        //     this.jadwal = scdata;
        //     this.jadwalShow = scdata;
        //     this.jadwalNearest = nearestsc;
        //     // console.log(this.jadwalShow);
        // },
        // async getActiveEmployees(){
        //     window.sessionStorage.setItem('authToken', getCookie('session'));
        //     try{
        //         const response = await axiosClient.get(`employe/active`);
        //         this.employees = response.data.data;
        //     }catch(error){
        //         console.log(error);
        //     }
        // },
        // async getFriends(){
        //     try{
        //         var frdata = [];
        //         for(var i = 0; i < this.employees.length; i++){
        //             if(this.employees[i].id != useAccountStore().account?.id){
        //                 frdata.push(this.employees[i]);
        //             }
        //             // console.log(this.employees[i]);
        //         }
        //         this.allFriends = frdata;
        //         this.friends = frdata;
        //         // console.log(this.friends);
        //     }catch(error){
        //         console.log(error);
        //     }
        // },
        // async transferSchedule(params){
        //     this.loader = true;
        //     try{
        //         const response = await axiosClient.post(`shift/transfer`, params);
        //         if(response.status == 201){
        //             this.getThisMonthSchedule();
        //             this.getTransferList();
        //             showNotification('', 'Permintaan transfer jadwal dikirim', 2000, null);
        //             hideModals();
        //         }
        //         this.loader = false;
        //     }catch(error){
        //         if(error.response){
        //             showNotification('', 'Error : ' + error.response.status + ', Message : ' + error.response.data.message, 2000, 'var(--red)');
        //         }
        //         this.loader = false;
        //         console.log(error);
        //     }
        // },
        // async cancelTransferSchedule(id){
        //     this.loader = true;
        //     try{
        //         const response = await axiosClient.delete(`shift/transfer/pull/` + id);
        //         if(response.status == 200){
        //             this.getThisMonthSchedule();
        //             this.getTransferList();
        //             showNotification('', response.data.message, 2000, null);
        //             hideModals();
        //         }
        //         this.loader = false;
        //     }catch(error){
        //         if(error.response){
        //             showNotification('', 'Error : ' + error.response.status + ', Message : ' + error.response.data.message, 2000, 'var(--red)');
        //         }
        //         this.loader = false;
        //         console.log(error);
        //     }
        // },
        // async getTransferList(){
        //     window.sessionStorage.setItem('authToken', getCookie('session'));
        //     try{
        //         const response = await axiosClient.get(`shift/transfer`);
        //         this.transferList = response.data.data;
        //         console.log(response);
        //     }catch(error){
        //         console.log(error);
        //     }
        // },
        // async getTransferRequest(){
        //     window.sessionStorage.setItem('authToken', getCookie('session'));
        //     try{
        //         const response = await axiosClient.get(`shift/transfer/request`);
        //         this.transferRequests = response.data.data;
        //         console.log(response);
        //     }catch(error){
        //         console.log(error);
        //     }
        // },
        // async transferResponse(params){
        //     this.loader = true;
        //     window.sessionStorage.setItem('authToken', getCookie('session'));
        //     try{
        //         const response = await axiosClient.post(`shift/transfer/response`, params);
        //         if(response.data.success){
        //             await this.getTransferRequest();
        //             showNotification('', 'Konfirmasi berhasil', 2000, null);
        //         }
        //         this.loader = false;
        //     }catch(error){
        //         this.loader = false;
        //         console.log(error);
        //     }
        // },
        // inputJadwalFilter(e){
        //     if(e.srcElement.getAttribute('filter') == 'from'){
        //         this.inputFilter.from = e.srcElement.value;
        //     }else{
        //         this.inputFilter.to = e.srcElement.value;
        //     }
        // },
        // filterJadwal(){
        //     if(this.inputFilter.from && this.inputFilter.to){
        //         const fdate = new Date(this.inputFilter.from);
        //         const tdate = new Date(this.inputFilter.to);
        //         if(tdate.getTime() >= fdate.getTime()){
        //             var scfiltered = [];
        //             for(var i = 0; i < this.jadwal.length; i++){
        //                 const date = new Date(this.jadwal[i].date);
        //                 if(date.getTime() >= fdate.getTime() && date.getTime() <= tdate.getTime()){
        //                     scfiltered.push(this.jadwal[i]);
        //                 }
        //             }
        //             this.jadwalShow = scfiltered;
        //         }else{
        //             showNotification('', 'Tanggal Hingga harus setelah atau sama dengan Tanggal Dari', 5000, 'var(--red)');
        //         }
        //     }else{
        //         showNotification('', 'Tanggal Dari dan Hingga harus diisi', 5000, 'var(--red)');
        //     }
        // },
        // resetJadwal(){
        //     this.jadwalShow = this.jadwal;
        //     this.inputFilter = {from: null, to: null};
        // },
        // async initLembur(){
        //     var lbshow = 3600000; // 1 hour
        //     var lbdelay = null;
        //     const date = new Date();
        //     const nextdate = new Date(date.getTime() + 86400000);
        //     const today = date.getFullYear() + '-' + await leftPadNum(date.getMonth() + 1, 2, '0') + '-' + await leftPadNum(date.getDate(), 2, '0');
        //     const nextday = nextdate.getFullYear() + '-' + await leftPadNum(nextdate.getMonth() + 1, 2, '0') + '-' + await leftPadNum(nextdate.getDate(), 2, '0');
        //     for(var i = 0; i < this.jadwal.length; i++){
        //         if(this.jadwal[i].date == today){
        //             this.jadwal[i].today = true;
        //             const ldstart = new Date(today + ' ' + this.jadwal[i].time_start);
        //             const ldend = new Date(today + ' ' + this.jadwal[i].time_end);
        //             if((ldend.getTime() - ldstart.getTime()) >= 0){
        //                 lbdelay = (ldend.getTime() + lbshow) - date.getTime();
        //                 // console.log(ldend.getTime() - ldstart.getTime());
        //             }else{
        //                 lbdelay = (nextday.getTime() + lbshow) - date.getTime();
        //             }
        //         }else{
        //             this.jadwal[i].today = false;
        //         }
        //     }
        //     for(i = 0; i < this.jadwalShow.length; i++){
        //         if(this.jadwalShow[i].date == today){
        //             this.jadwalShow[i].today = true;
        //         }else{
        //             this.jadwalShow[i].today = false;
        //         }
        //     }
        //     if(lbdelay > 1){
        //         setTimeout(function(){
        //             showLemburButton();
        //         }, lbdelay);
        //     }else{
        //         showLemburButton();
        //     }
        //     // console.log(lbdelay);
        //     // console.log(this.jadwal);
        //     // console.log(today);
        //     // console.log(nextday);
        // },
        // searchFriend(value){
        //     if(value != ''){
        //         var result = [];
        //         var inval = new RegExp(value, 'gi');
        //         for(var i = 0; i < this.kpiOptions.length; i++){
        //             if(this.kpiOptions[i].name.match(inval)){
        //                 result.push(this.kpiOptions[i]);
        //             }
        //         }
        //         this.kpiOptionsFiltered = result;
        //     }else{
        //         this.kpiOptionsFiltered = this.kpiOptions;
        //     }
        // }
    }
});