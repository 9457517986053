import { useAccountStore } from "@/stores/account";
import { showNotification, hideModals } from "./general";
export function updateProfile(){
    const fd = new FormData();
    const name = document.getElementById('input-pum-name');
    const image = document.getElementById('input-pum-image');
    const gender = document.getElementById('input-pum-gender');
    const email = document.getElementById('input-pum-email');
    const birthplace = document.getElementById('input-pum-birthplace');
    const birthdate = document.getElementById('input-pum-birthdate');
    const religion = document.getElementById('input-pum-religion');
    const address = document.getElementById('input-pum-address');
    const province = document.getElementById('input-pum-province');
    const city = document.getElementById('input-pum-city');
    const phone = document.getElementById('input-pum-phone');
    if(image.value){
        const [file] = image.files;
        fd.append('avatar', file);
    }
    fd.append('name', name.value);
    fd.append('gender', gender.value);
    fd.append('email', email.value);
    fd.append('birthplace', birthplace.value);
    fd.append('birthday', birthdate.value);
    fd.append('religion', religion.value);
    fd.append('address', address.value);
    fd.append('province', province.value);
    fd.append('city', city.value);
    fd.append('phone', phone.value);
    useAccountStore().updateProfile(fd);
}
export async function updatePassword(){
    const oldpass = document.getElementById('input-oldpass-updatepass');
    const newpass = document.getElementById('input-newpass-updatepass');
    const newpassconf = document.getElementById('input-newpassconf-updatepass');
    if(oldpass.value && newpass.value && newpassconf.value){
        const params = {
            current_password: oldpass.value, 
            password: newpass.value, 
            password_confirmation: newpassconf.value
        }
        console.log(params);
        var sendupdate = await useAccountStore().updatePassword(params);
        if(sendupdate.status){
            oldpass.value = null;
            newpass.value = null;
            newpassconf.value = null;
            hideModals();
        }
    }else{
        showNotification('', 'Semua input wajib diisi', 2000, 'var(--red)');
    }
}
export async function submitDocument(index, expired){
    const fd = new FormData();
    const id = document.getElementById('input-docid-' + index);
    const number = document.getElementById('input-docnum-' + index);
    const attachments = document.getElementById('flb-attachments-' + index);
    for(var i = 0; i < attachments.children.length; i++){
        if(attachments.children[i].children[0].value){
            const [files] = await attachments.children[i].children[0].files;
            fd.append(`attach[${i}][file]`, files);
            fd.append(`attach[${i}][id]`, id.value);
            fd.append(`attach[${i}][number]`, number.value);
            if(expired){
                const datestart = document.getElementById('input-docstart-' + index);
                const dateend = document.getElementById('input-docend-' + index);
                fd.append(`attach[${i}][date_start]`, datestart.value);
                fd.append(`attach[${i}][date_end]`, dateend.value);
            }
        }
    }
    // fd.append('schedule_id', id.value);
    // fd.append('time_end', endtime.value);
    // fd.append('description', description.value);
    useAccountStore().submitDocument(fd);
    // console.log(id.value);
}
export function inputLampiran(index){
    const inform = document.getElementById('flb-attachments-' + index);
    const el = document.createElement('div');
    el.classList.add('element-relative');
    el.style.cssText = 'width: 100%;';
    el.innerHTML = '<input type="file"/><div class="sfi-input-remove" style="position: absolute; top: 0; right: 0; width: 50px; height: 100%; cursor: pointer; display: flex; justify-content: right; align-items: center; padding: 0.5rem; transition: 0.2s;">x</div>';
    el.children[1].addEventListener('click', function(e){
        e.target.parentElement.remove();
    });
    inform.appendChild(el);
}
export function validateFileType(id, type){
    var selectedFile = document.getElementById(id).children[1].children[0].files[0];
    var allowedTypes;
    if(type == 'image'){
        allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
    }else if(type == 'pdf'){
        allowedTypes = ['application/pdf'];
    }

    if (!allowedTypes.includes(selectedFile.type)) {
        if(type == 'image'){
            showNotification('', 'Mohon upload file png, jpeg atau webp', 2000, null);
        }else if(type == 'pdf'){
            showNotification('', 'Mohon upload file pdf', 2000, null);
        }
       document.getElementById(id).children[1].children[0].value = '';
    }
}
export function transformImage(id){
    if(document.getElementById(id)){
        const target = document.getElementById(id);
        if(target.classList.contains('attachment-transformed')){
            target.classList.remove('attachment-transformed');
        }else{
            target.classList.add('attachment-transformed');
        }
    }
}

